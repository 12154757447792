import { useEffect, useState } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { Tooltip, ValidatedInput } from '../../components';
import { RequireAlert } from '../inventory-management/InventoryManagementCollectDeposit';
import { AlertSVG } from '../../components/svgs';
import { ValidatedState } from '../../components/custom-input/ValidatedInput';
import { AccountRoles, AppRoles, Validators } from '../../types/enums';
import { PermissionService } from '../../services/permission.service';
import {
  INVENTORY_CONSTANTS,
  INVENTORY_MANAGEMENT_CONSTANTS,
  LABEL_CONSTANTS,
  NOTIFICATION_CONSTANTS,
  PAYMENT_CONSTANTS,
  TOOLTIP_MESSAGE_CONSTANTS,
} from '../../constants/common';
import './transactionCart.scss';

type ShippingInsuranceProps = {
  validate: boolean;
  setValid: (valid: boolean) => void;
  shippingSummary: ShippingSummary | undefined;
  setShippingSummary: (shippingSummary: ShippingSummary) => void;
  rentDryVaporShipperTank: string;
};

export interface ShippingSummary {
  shippingInsurance: string;
  insuranceAmount: ValidatedState;
  insurance: number;
  termsAndConditions: string;
  handlingProcessingFee: number;
  tankRentalFee: number;
  shippingCost: number;
}

const ShippingInsurance = ({
  validate,
  setValid,
  shippingSummary,
  setShippingSummary,
  rentDryVaporShipperTank,
}: ShippingInsuranceProps) => {
  const TANK_RENTAL_FEE = 50;
  const HANDLING_PROCESSING_FEE = 10;
  const initialFormState = { value: '', valid: false };

  const accountRoles = useAppSelector(state => state.auth.accountContext.roles) as AccountRoles[];
  const appRoles = useAppSelector(state => state.auth.appContext.roles) as AppRoles[];
  const enabled = useAppSelector(state => state.user.enabled);
  const verified = useAppSelector(state => state.user.verified);

  const [isInsuranceSelected, setIsInsuranceSelected] = useState<string>(shippingSummary?.shippingInsurance || '');
  const [insuranceAmount, setInsuranceAmount] = useState<ValidatedState>(shippingSummary?.insuranceAmount ?? initialFormState);
  const [insuranceFee, setInsuranceFee] = useState<number>(shippingSummary?.insurance ?? 0);

  const permissionService = new PermissionService(accountRoles, appRoles, enabled, verified);

  useEffect(() => {
    updateInsuranceFee(insuranceAmount);
  }, [insuranceAmount]);

  useEffect(() => {
    validateForm();
    updateShippingSummary();
  }, [isInsuranceSelected, insuranceAmount, insuranceFee, rentDryVaporShipperTank]);

  const updateInsuranceFee = (amount: ValidatedState) => {
    const numericAmount = amount?.value ? parseFloat(amount.value) : 0;
    if (numericAmount && !isNaN(numericAmount) && numericAmount > 0) {
      setInsuranceFee(numericAmount * 0.05);
    } else {
      setInsuranceFee(0);
    }
  };

  const validateForm = () => {
    setValid(
      isInsuranceSelected === LABEL_CONSTANTS.NO ||
        (isInsuranceSelected === LABEL_CONSTANTS.YES && (insuranceAmount?.valid ?? false) && (insuranceFee ?? 0) > 0),
    );
  };

  const updateShippingSummary = () => {
    setShippingSummary({
      shippingInsurance: isInsuranceSelected,
      insuranceAmount: insuranceAmount,
      insurance: insuranceFee,
      termsAndConditions: shippingSummary?.termsAndConditions || '',
      handlingProcessingFee: HANDLING_PROCESSING_FEE,
      tankRentalFee: rentDryVaporShipperTank === LABEL_CONSTANTS.YES ? TANK_RENTAL_FEE : 0,
      shippingCost: 0, // shipping cost should be updated from the ship station API
    });
  };

  useEffect(() => {
    if (isInsuranceSelected === LABEL_CONSTANTS.NO) {
      setInsuranceAmount(initialFormState);
      setInsuranceFee(0);
    }
  }, [isInsuranceSelected]);

  return (
    <div className="shipping-insurance">
      <h2>{LABEL_CONSTANTS.SHIPPING_INSURANCE}</h2>
      <div className="form-row">
        <label className="radio-row-label">
          <div>
            *{INVENTORY_CONSTANTS.ADD_INSURANCE_PROMPT}:
            <RequireAlert isActive={validate && isInsuranceSelected == undefined} />
          </div>
        </label>
        <div className="radio-row">
          &ensp;
          <input
            type="radio"
            name="insurance-yes"
            checked={isInsuranceSelected === LABEL_CONSTANTS.YES}
            onChange={() => setIsInsuranceSelected(LABEL_CONSTANTS.YES)}
          />
          <label>{LABEL_CONSTANTS.YES}</label>
          <input
            type="radio"
            name="insurance-no"
            checked={isInsuranceSelected === LABEL_CONSTANTS.NO}
            onChange={() => setIsInsuranceSelected(LABEL_CONSTANTS.NO)}
          />
          <label>{LABEL_CONSTANTS.NO}</label>
        </div>
      </div>

      {isInsuranceSelected === LABEL_CONSTANTS.NO && (
        <div className="validated-input-message-error">
          <AlertSVG /> <p>{NOTIFICATION_CONSTANTS.NO_INSURANCE_MESSAGE}</p>
        </div>
      )}

      {isInsuranceSelected === LABEL_CONSTANTS.YES && (
        <div>
          <div className="insurance-amount-prompt">
            <label>{INVENTORY_MANAGEMENT_CONSTANTS.INSURANCE_AMOUNT_PROMPT}</label>
          </div>
          <div className="form-row">
            <ValidatedInput
              useId="Amount"
              label={`*${PAYMENT_CONSTANTS.AMOUNT} ($):`}
              type="number"
              validate={validate}
              setValidatedState={setInsuranceAmount}
              validators={[Validators.REQUIRED]}
              validatedStateForAutoFill={insuranceAmount}
              min={50}
              max={permissionService.userHasRSGAdminPermission() ? undefined : 9999}
              allowFraction
              preventNegative
            />
          </div>
          <div className="form-row">
            <label>{`${PAYMENT_CONSTANTS.INSURANCE_FEE}:`}</label>
            <div className="input-container">
              <div className="calc-insurance-amount">
                ${insuranceFee.toFixed(2)}
                <Tooltip>{TOOLTIP_MESSAGE_CONSTANTS.INSURANCE_FEE_TOOLTIP}</Tooltip>
              </div>
            </div>
          </div>
          <br />
        </div>
      )}
      <br />
    </div>
  );
};

export default ShippingInsurance;
