import { useState } from 'react';
import { Modal } from '../../../components';
import { CancelSVG } from '../../../components/svgs';
import { BUTTON_CONSTANTS, INVENTORY_MANAGEMENT_CONSTANTS, LABEL_CONSTANTS } from '../../../constants/common';
import './insuranceTermsModal.scss';

type TermsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onAccept: () => void;
};

const InsuranceTermsModal: React.FC<TermsModalProps> = ({ isOpen, onClose, onAccept }) => {
  const [insuranceTermsAccepted, setInsuranceTermsAccepted] = useState<string>(LABEL_CONSTANTS.DECLINED);

  const handleClose = () => {
    setInsuranceTermsAccepted(LABEL_CONSTANTS.DECLINED);
    onClose();
  };

  const handleConfirm = () => {
    setInsuranceTermsAccepted(LABEL_CONSTANTS.DECLINED);
    onAccept();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} ignoreBackdrop>
      <div className="insurance-terms-conditions-modal">
        <div className="terms-header">
          <h2>{LABEL_CONSTANTS.RSG_INSURANCE_TC}</h2>
          <button className="button icon" type="button" onClick={() => handleClose()}>
            <CancelSVG />
          </button>
        </div>

        <div className="terms-body">
          <h3>ANIMAL SEMEN TRANSIT COVERAGE INSURANCE</h3>
          <h4>INSURING AGREEMENT</h4>
          <p>
            It is agreed by the insured that each and every package of animal semen shipped by RSG Corporation, valued as
            illustrated on the certified shipping registry, will be insured by Growers Mutual Insurance Company. It is hereby
            understood and agreed that as to all shipments made on or after the effective date, this policy shall cover property
            as described only when in transit.
          </p>
          <p>
            Coverage is effective as of the date and time illustrated on the shipping receipt. Coverage ends when delivery is
            completed or 1o days after the effective date, whichever applies first. Coverage applies anywhere in the lower 48
            Continental United States.
          </p>
          <p>
            Insurance covers material damage to the insured animal semen regardless of the cause of the damage, unless the
            respective insurable peril is excluded or otherwise limited or modified in the policy.
          </p>

          <h4>EXCLUSIONS</h4>
          <ol className="exclusions-list">
            <li>Willful conduct of the insured, the policyholder or any person acting on the initiative of any of them.</li>
            <li>
              Insolvency or breach of financial obligations by the owners, charterers or operators of a vessel or an aircraft if
              the insured knew about such insolvency or breach of financial obligations in time when the insured item was loaded
              onboard a vessel or aircraft, or he should have known that such insolvency or breach of financial obligations could
              have influence on the usual course of transport. However, this exclusion will not apply in such cases when the
              insurance is concluded on behalf of the beneficiary who is buying the insured item or who agreed with buying the
              insured item in good will with a binding contract.
            </li>
            <li>
              Use of any weapon of war employing atomic or nuclear fission or fusion or similar reaction or radioactive force or
              substance.
            </li>
            <li>
              War, civil war, revolution, mutiny, insurrection or the following civil commotion, hostile acts by or against a
              belligerent power.
            </li>
            <li>
              Capture, seizure, confiscation, detention or garnishment of vessel or aircraft and the consequences thereof or
              attempts thereat.
            </li>
            <li>Abandoned mines, torpedoes, bombs or other derelict weapons of war.</li>
            <li>Acts by strikers, laid off workmen, persons participating in labor disturbances, riots or civil commotions.</li>
            <li>Strikes, lock-outs, employees disturbances, mutinies or civil commotions.</li>
            <li>Acts of terrorists or other persons acting from political ideological or religious motives.</li>
          </ol>

          <h4>CONDITIONS</h4>
          <ol className="conditions-list">
            <li>
              MISREPRESENTATION AND FRAUD. This entire policy shall be void if, whether before or after a loss, this insured has
              concealed or misrepresented any material fact or circumstance concerning this insurance or the subject thereof, or
              the interest of the insured therein, or in case of any fraud, attempted fraud, or false swearing by the insured
              relating thereto.
            </li>
            <li>
              CHANGES. Notice to any agent or knowledge possessed by any agent or by any other person shall not affect a waiver or
              a change in any part of this policy or stop the company from asserting any right under the terms of this policy, nor
              shall the terms of this policy be waived or changed, except by endorsement issued to form a part of this policy.
            </li>
            <li>
              COLLECTION FROM OTHERS. No loss shall be paid or made good hereunder if the insured has collected the same from
              others.
            </li>
            <li>
              NOTICE OF LOSS. The insured shall as soon as practicable report in writing to the company or its agent every loss
              which may become a claim under this policy and shall also file with the company or its agent within 15 days from the
              date of discovery of loss a detailed sworn proof of loss. Failure by the insured to report the said loss and to file
              such sworn proof of loss as hereinbefore provided shall invalidate any claim under this policy for such loss.
            </li>
            <li>
              NO BENEFIT TO BAILEE. This insurance shall in no way inure directly or indirectly to the benefit of any carrier or
              other bailee.
            </li>
            <li>ABANDONMENT. There can be no abandonment to the company of any property.</li>
            <li>
              {' '}
              VALUATION. The company shall not be liable beyond the actual cash value of the property at the time any loss occurs.
              The loss shall be ascertained or estimated according to such actual cash value with proper deduction for
              depreciation, however caused, and shall in no event exceed what it would then cost to repair or replace the same
              with material of like kind and quality.
            </li>
            <li>
              OTHER INSURANCE. The company shall not be liable for loss if, at the time of loss, there is any other insurance
              which would be attached if this insurance had not been effected, except that this insurance shall apply only as
              excess and in no event as contributing insurance, and then only after all other insurance has been exhausted.
            </li>
            <li>
              SETTLEMENT OF CLAIMS. All adjusted claims shall be paid or made good to the insured within fifteen business days
              after presentation and acceptance of satisfactory proof of interest and loss at the office of the company.
            </li>
            <li>
              SUIT. No suit, action or proceeding for the recovery of any claim under this policy shall be sustainable in any
              court of law or equity unless the same be commenced within 12 months next after discovery by the insured of the
              occurrence which gives rise to the claim, provided, however, that if by the laws of the state within which this
              policy is issued such limitation is invalid, then any such claims shall be void unless such action, suit or
              proceeding be commenced within the shortest limit of time permitted by the laws of such state.
            </li>
            <li>LOSS CLAUSE. Any loss hereunder shall not reduce the amount of this policy.</li>
            <li>
              SUBROGATION. In the event of any payment under this policy the company shall be subrogated to all the insured's
              rights of recovery therefor against any person or organization and the insured shall execute and deliver instruments
              and papers and do whatever else is necessary to secure such rights. The insured shall do nothing after loss to
              prejudice such rights.
            </li>
            <li>
              CANCELLATION. This policy may be canceled by the insured by surrender thereof to the company or any of its
              authorized agents or by mailing to the company written notice stating when thereafter the cancelation shall be
              effective. This policy may be canceled by the company by mailing to the insured at the address shown in this policy,
              written notice stating when not less than ten days thereafter such cancelation shall be effective. The mailing of
              notice as aforesaid shall be sufficient proof of notice. The time of surrender or the effective date and hour of
              cancelation stated in the notice shall become the end of the policy period. Delivery of such written notice either
              by the insured or by the company shall be equivalent to mailing. If the insured cancels, earned premium shall be
              computed in accordance with the customary short rate table and procedure. If the company cancels, earned premium
              shall be computed pro rata. Premium adjustment may be made either at the time cancellation is effected or as soon as
              practicable after cancelation becomes effective, but payment or tender of unearned premium is not a condition of
              cancelation.
            </li>
            <li>
              CONFORMITY TO STATUTE. Terms of this policy which are in conflict with the statutes of the state wherein this policy
              is issued are hereby amended to conform to such statutes.
            </li>
          </ol>
          <div className="terms-accept-checkbox">
            <input
              type="checkbox"
              id="termsAccepted"
              checked={insuranceTermsAccepted === LABEL_CONSTANTS.ACCEPTED}
              onChange={() =>
                setInsuranceTermsAccepted(prev =>
                  prev === LABEL_CONSTANTS.DECLINED ? LABEL_CONSTANTS.ACCEPTED : LABEL_CONSTANTS.DECLINED,
                )
              }
            />
            <label>{INVENTORY_MANAGEMENT_CONSTANTS.INSURANCE_TERMS_ACCEPT}</label>
          </div>
        </div>

        <div className="terms-footer flex-right">
          <button
            className="button accept-btn small"
            onClick={() => handleConfirm()}
            disabled={insuranceTermsAccepted != LABEL_CONSTANTS.ACCEPTED}>
            {BUTTON_CONSTANTS.CONFIRM}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default InsuranceTermsModal;
