export const APP_PATHS = {
  APP_: '/app/',

  ANIMAL_MANAGEMENT: 'animal-management',
  ANIMAL_MANAGEMENT_: 'animal-management/',
  SPECIES_BREED_MANAGEMENT: 'species-management',

  INVENTORY_MANAGEMENT: 'inventory-management',
  INVENTORY_MANAGEMENT_: 'inventory-management/',

  TRANSACTION_MANAGEMENT: 'transaction-management',
  TRANSACTION_MANAGEMENT_: 'transaction-management/',
  TRANSACTION_MANAGEMENT_WITHDRAW: 'transaction-management/withdraw/',
  TRANSACTION_MANAGEMENT_ADJUST: 'transaction-management/adjust/',

  CUSTOMER_INFO: 'customer-info',
  CUSTOMER_INFO_: 'customer-info/',

  LOCATION_MANAGEMENT: 'location-management',
  ADD_ACCOUNT: 'add-account',

  REPORTS: 'reports',
  REPORTS_: 'reports/',

  APPLICATION_TEAM: 'application-team',
  APPLICATION_TEAM_: 'application-team/',

  USER_PROFILE: 'profile',
  USER_PROFILE_: 'profile/',

  ADMIN_SETTINGS: 'admin-settings',

  TRANSACTION_CART: 'transaction-cart',
  TRANSACTION_CART_: 'transaction-cart/',
  TRANSACTION_CART_WITHDRAW: 'transaction-cart/withdraw/',
};

export const ACCOUNT_PATHS = {
  DASHBOARD: 'dashboard',

  INVENTORY: 'inventory',
  INVENTORY_: 'inventory/',

  TRANSACTION_HISTORY: 'transaction-history',
  TRANSACTION_HISTORY_: 'transaction-history/',

  TEAM_MANAGEMENT: 'team-management',
  TEAM_MANAGEMENT_: 'team-management/',

  SETTINGS: 'settings',
  SETTINGS_: 'settings/',
  ACCOUNT_SETTINGS: 'account-settings',
};

export const SETTINGS_PATHS = {
  PASSWORD: 'password',
};

export const INVENTORY_PATHS = {
  ITEM: ':animalId',
  ITEM_: ':animalId/',
  SELL: 'sell-transfer',
  WITHDRAW: 'withdraw',
  USE: 'use',
  DISCARD: 'discard',
};

export const ANIMAL_MANAGEMENT_PATHS = {
  ITEM: ':animalId',
  ITEM_: ':animalId/',
  EDIT: 'edit',
  SPECIMEN: ':specimenId',
};

export const SPECIES_MANAGEMENT_PATHS = {};

export const BREED_MANAGEMENT_PATHS = {};

export const ANIMAL_MANAGEMENT_ANIMAL_PATHS = {
  ANIMAL: ':id',
  ANIMAL_: ':id/',
  EDIT: 'edit',
};

export const INVENTORY_MANAGEMENT_PATHS = {
  COLLECT_DEPOSIT: 'collect-deposit',
  MOVE: 'move',
  CHECK_IN: 'check-in',
  CHECK_OUT: 'check-out',
  DISCARD: 'discard',
  ON_SITE_SELL: 'on-site-sell',
  CUSTOM_DATA_POINTS: 'custom-data-points',
  CANISTER_CONTENTS: 'canister-contents',
  RECONCILE: 'reconcile',
  RECONCILE_: 'reconcile/',
  RECONCILE_ITEM: 'reconcile/:specimenId',
  RSG_CLINIC_USE: 'rsg-clinic-use',
  THRESHOLD: 'threshold',
};

export const REPORT_PATHS = {
  INSURED_CUSTOMERS: 'insured-customers',
};

export const TEAM_MANAGEMENT_PATHS = {
  EDIT: ':id',
};

export const APPLICATION_TEAM_PATHS = {
  EDIT: ':id',
};

export const CUSTOMER_INFO_PATHS = {
  VIEW: ':accountId',
};

export const TRANSACTION_MANAGEMENT_PATHS = {
  VIEW: ':transactionId',
  CREATE_CLIENT_TRANSACTION: 'create-client-transaction',
  WITHDRAW: 'withdraw/:transactionId',
  ADJUST: 'adjust/:transactionId',
};

export const USER_PROFILE_PATHS = {
  UPDATE: 'edit-user',
};

export const TRANSACTION_HISTORY_PATHS = {
  VIEW: ':transactionId',
};

export const TRANSACTION_CART_PATHS = {
  WITHDRAW: 'withdraw/:accountId',
};

export const ROUTE_PATHS = {
  INVITATION_TOKEN: '/invitation-token',
  VERIFICATION_TOKEN: '/verification-token',
  CREATE_PASSWORD: '/create-password',
  SIGN_UP: '/signup',
  SIGN_IN: '/sign-in',
  FORGOT_PASSWORD: '/forgot-password',
  FORGOT_PASSWORD_CODE: '/forgot-password-code',
  RESET_TOKEN: '/reset-token',
  RESET_PASSWORD: '/reset-password',
  TERMS_OF_USE: '',
  PRIVACY_POLICY: '',
  APP: '/app',
  APP_: APP_PATHS.APP_ + '*',
  APP_DASHBOARD: APP_PATHS.APP_ + ACCOUNT_PATHS.DASHBOARD,
  APP_ANIMAL_MANAGEMENT: APP_PATHS.APP_ + APP_PATHS.ANIMAL_MANAGEMENT,
  APP_ANIMAL_MANAGEMENT_: APP_PATHS.APP_ + APP_PATHS.ANIMAL_MANAGEMENT_,
  APP_SPECIES_BREED_MANAGEMENT: APP_PATHS.APP_ + APP_PATHS.SPECIES_BREED_MANAGEMENT,
  APP_INVENTORY: APP_PATHS.APP_ + ACCOUNT_PATHS.INVENTORY,
  APP_INVENTORY_MANAGEMENT: APP_PATHS.APP_ + APP_PATHS.INVENTORY_MANAGEMENT,
  APP_INVENTORY_MANAGEMENT_COLLECT_DEPOSIT:
    APP_PATHS.APP_ + APP_PATHS.INVENTORY_MANAGEMENT_ + INVENTORY_MANAGEMENT_PATHS.COLLECT_DEPOSIT,
  APP_INVENTORY_MANAGEMENT_MOVE: APP_PATHS.APP_ + APP_PATHS.INVENTORY_MANAGEMENT_ + INVENTORY_MANAGEMENT_PATHS.MOVE,
  APP_INVENTORY_MANAGEMENT_CHECK_IN: APP_PATHS.APP_ + APP_PATHS.INVENTORY_MANAGEMENT_ + INVENTORY_MANAGEMENT_PATHS.CHECK_IN,
  APP_INVENTORY_MANAGEMENT_CHECK_OUT: APP_PATHS.APP_ + APP_PATHS.INVENTORY_MANAGEMENT_ + INVENTORY_MANAGEMENT_PATHS.CHECK_OUT,
  APP_INVENTORY_MANAGEMENT_DISCARD: APP_PATHS.APP_ + APP_PATHS.INVENTORY_MANAGEMENT_ + INVENTORY_MANAGEMENT_PATHS.DISCARD,
  APP_INVENTORY_MANAGEMENT_CANISTER_CONTENTS:
    APP_PATHS.APP_ + APP_PATHS.INVENTORY_MANAGEMENT_ + INVENTORY_MANAGEMENT_PATHS.CANISTER_CONTENTS,
  APP_INVENTORY_MANAGEMENT_RECONCILE: APP_PATHS.APP_ + APP_PATHS.INVENTORY_MANAGEMENT_ + INVENTORY_MANAGEMENT_PATHS.RECONCILE,
  APP_INVENTORY_MANAGEMENT_RECONCILE_: APP_PATHS.APP_ + APP_PATHS.INVENTORY_MANAGEMENT_ + INVENTORY_MANAGEMENT_PATHS.RECONCILE_,
  APP_TRANSACTION_MANAGEMENT: APP_PATHS.APP_ + APP_PATHS.TRANSACTION_MANAGEMENT,
  APP_TRANSACTION_MANAGEMENT_WITHDRAW: APP_PATHS.APP_ + APP_PATHS.TRANSACTION_MANAGEMENT_WITHDRAW,
  APP_TRANSACTION_MANAGEMENT_ADJUST: APP_PATHS.APP_ + APP_PATHS.TRANSACTION_MANAGEMENT_ADJUST,
  APP_TRANSACTION_MANAGEMENT_VIEW: APP_PATHS.APP_ + APP_PATHS.TRANSACTION_MANAGEMENT_,
  APP_TRANSACTION_HISTORY: APP_PATHS.APP_ + ACCOUNT_PATHS.TRANSACTION_HISTORY,
  APP_TRANSACTION_HISTORY_VIEW: APP_PATHS.APP_ + ACCOUNT_PATHS.TRANSACTION_HISTORY_,
  APP_CUSTOMER_INFO: APP_PATHS.APP_ + APP_PATHS.CUSTOMER_INFO,
  APP_CUSTOMER_INFO_VIEW: APP_PATHS.APP_ + APP_PATHS.CUSTOMER_INFO_,
  APP_ADD_ACCOUNT: APP_PATHS.APP_ + APP_PATHS.ADD_ACCOUNT,
  APP_SETTINGS: APP_PATHS.APP_ + ACCOUNT_PATHS.SETTINGS,
  APP_ACCOUNT_SETTINGS: APP_PATHS.APP_ + ACCOUNT_PATHS.ACCOUNT_SETTINGS,
  APP_SETTINGS_PASSWORD: APP_PATHS.APP_ + ACCOUNT_PATHS.SETTINGS_ + SETTINGS_PATHS.PASSWORD,
  APP_USER_PROFILE: APP_PATHS.APP_ + APP_PATHS.USER_PROFILE,
  APP_USER_PROFILE_UPDATE: APP_PATHS.APP_ + APP_PATHS.USER_PROFILE_ + USER_PROFILE_PATHS.UPDATE,
  APP_TEAM_MANAGEMENT: APP_PATHS.APP_ + ACCOUNT_PATHS.TEAM_MANAGEMENT,
  APP_TEAM_MANAGEMENT_EDIT: APP_PATHS.APP_ + ACCOUNT_PATHS.TEAM_MANAGEMENT_,
  APP_APPLICATION_TEAM: APP_PATHS.APP_ + APP_PATHS.APPLICATION_TEAM,
  APP_APPLICATION_TEAM_EDIT: APP_PATHS.APP_ + APP_PATHS.APPLICATION_TEAM_,
  APP_ADMIN_SETTINGS: APP_PATHS.APP_ + APP_PATHS.ADMIN_SETTINGS,
  APP_TRANSACTION_CART: APP_PATHS.APP_ + APP_PATHS.TRANSACTION_CART,
  APP_TRANSACTION_CART_WITHDRAW: APP_PATHS.APP_ + APP_PATHS.TRANSACTION_CART_WITHDRAW,
};
