import { useState } from 'react';
import { ValidatedInput } from '../../../components';
import { EditSVG } from '../../../components/svgs';
import { ValidatedState } from '../../../components/custom-input/ValidatedInput';
import TransactionLabelField from '../../../components/transaction/TransactionLabelField';
import { ValidateAddress } from '../TransactionCartWithdraw';
import { ShippingSummary } from '../ShippingInsurance';
import PaymentInformation from '../shipping-payment-info/PaymentInformation';
import { CartTransactionResponse } from '../../../types/interfaces/transactionCart.interfaces';
import { Validators } from '../../../types/enums';
import {
  ADDRESS_CONSTANTS,
  ANIMAL_CONSTANTS,
  BUTTON_CONSTANTS,
  INVENTORY_MANAGEMENT_CONSTANTS,
  LABEL_CONSTANTS,
  PAYMENT_CONSTANTS,
  TABLE_HEADER_CONSTANTS,
} from '../../../constants/common';
import './shippingPreview.scss';

type CustomerInfoForm = {
  customerName: ValidatedState;
  customerEmail: ValidatedState;
  customerPhoneNumber: ValidatedState;
};

type ShippingPreviewProps = {
  withdrawCartItem: CartTransactionResponse | undefined;
  shippingAddress: ValidateAddress | undefined;
  shippingSummary: ShippingSummary | undefined;
  setIsPreview: (isPreview: boolean) => void;
  customerInfo: CustomerInfoForm;
  setCustomerInfo: (customerInfo: CustomerInfoForm) => void;
};

const ShippingPreview = ({
  withdrawCartItem,
  shippingAddress,
  shippingSummary,
  setIsPreview,
  customerInfo,
  setCustomerInfo,
}: ShippingPreviewProps) => {
  const [isBuyerInfoEditMode, setIsEditBuyerInfoMode] = useState<boolean>(false);
  const [customerName, setCustomerName] = useState<ValidatedState>(customerInfo.customerName);
  const [customerEmail, setCustomerEmail] = useState<ValidatedState>(customerInfo.customerEmail);
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState<ValidatedState>(customerInfo.customerPhoneNumber);
  const [isPaymentPage, setIsPaymentPage] = useState<boolean>(false);

  const totalDiscardQuantity = withdrawCartItem?.specimenCartItems?.reduce(
    (accumulator: number, specimenCartItem) => accumulator + specimenCartItem?.quantity,
    0,
  );

  const totalShippingAmount = shippingSummary
    ? shippingSummary.insurance +
      shippingSummary.handlingProcessingFee +
      shippingSummary.tankRentalFee +
      shippingSummary.shippingCost
    : 0;

  const handleCancelClick = () => {
    setIsEditBuyerInfoMode(false);
    setCustomerName(customerInfo.customerName);
    setCustomerEmail(customerInfo.customerEmail);
    setCustomerPhoneNumber(customerInfo.customerPhoneNumber);
  };

  const updateCustomerInfo = () => {
    setCustomerInfo({ customerName, customerEmail, customerPhoneNumber });
    setIsEditBuyerInfoMode(false);
  };

  const handleProceedToPayment = async () => {
    setIsPaymentPage(true);
  };

  return (
    <div>
      <div className="step-counter">
        <div className={!isPaymentPage ? 'step-bubble active' : 'step-bubble'}>1</div>
        <div className="step-connector"></div>
        <div className={isPaymentPage ? 'step-bubble active' : 'step-bubble'}>2</div>
      </div>
      {!isPaymentPage ? (
        <div className="withdraw-shipping-preview">
          <div className="shipping-preview-header">
            <h2 className="review-purchase-title">{LABEL_CONSTANTS.REVIEW_PURCHASE_INFO}</h2>
            <label>{INVENTORY_MANAGEMENT_CONSTANTS.REVIEW_PURCHASE_INFO_SUBHEADER}</label>
          </div>
          <div className="animal-info">
            <h3 className="info-header">
              {ANIMAL_CONSTANTS.ANIMAL_INFO}
              <div className="button icon" onClick={() => setIsPreview(false)}>
                <EditSVG />
              </div>
            </h3>
            <div className="desk-select-specimen">
              <div className="specimen-table">
                <table>
                  <thead>
                    <tr>
                      <th>{TABLE_HEADER_CONSTANTS.ANIMAL}</th>
                      <th>{TABLE_HEADER_CONSTANTS.INVENTORY_TYPE}</th>
                      <th>{TABLE_HEADER_CONSTANTS.LOT_DATE_NO}</th>
                      <th>{TABLE_HEADER_CONSTANTS.AVAILABLE_QTY}</th>
                      <th>{TABLE_HEADER_CONSTANTS.QTY_TO_WITHDRAW}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {withdrawCartItem?.specimenCartItems?.map((specimenCartItem, index: number) => {
                      return (
                        <tr key={'specimen-row:' + specimenCartItem.specimenId + '-index:' + index}>
                          <td>
                            {specimenCartItem.animal?.code} - {specimenCartItem.animal?.name}
                          </td>
                          <td>{specimenCartItem.specimen.specimenType?.name}</td>
                          <td>{`${new Date(specimenCartItem.specimen.freezeDate).toLocaleDateString()} - ${
                            specimenCartItem.specimen.specimenId
                          }`}</td>
                          <td>{specimenCartItem.availableQuantity}</td>
                          <td>{specimenCartItem.quantity}</td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td colSpan={4}>{LABEL_CONSTANTS.TOTAL_QUANTITY}:</td>
                      <td>
                        <input type="text" disabled value={totalDiscardQuantity} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {/** Mobile View */}
            <div className="xs-select-specimens">
              {withdrawCartItem?.specimenCartItems.map((specimenCartItem, index: number) => {
                return (
                  <div className={`card max-width`} key={'specimen-row:' + specimenCartItem.specimenId + '-index:' + index}>
                    <div className="selected-specimens-row">
                      <div className="select-specimens">
                        <div className="card-content-section">
                          <div className="left column">
                            <label>{`${TABLE_HEADER_CONSTANTS.ANIMAL}: `}</label>
                            <label>{`${specimenCartItem.animal?.code} - ${specimenCartItem.animal?.name}`}</label>
                          </div>
                          <div className="left column">
                            <label>{`${TABLE_HEADER_CONSTANTS.INVENTORY_TYPE}: `}</label>
                            <label>{`${specimenCartItem.specimen.specimenType?.name}`}</label>
                          </div>
                          <div className="right column">
                            <label>{`${TABLE_HEADER_CONSTANTS.LOT_DATE_NO}: `}</label>
                            <label>{`${new Date(specimenCartItem.specimen.freezeDate).toLocaleDateString()} - ${
                              specimenCartItem.specimen.specimenId
                            }`}</label>
                          </div>
                        </div>
                        <div className="card-content-section">
                          <div className="left column">
                            <label>{`${TABLE_HEADER_CONSTANTS.AVAILABLE_QTY}: `}</label>
                            <label>{`${specimenCartItem.availableQuantity}`}</label>
                          </div>
                          <div className="right column ">
                            <label>{`${TABLE_HEADER_CONSTANTS.QTY_TO_WITHDRAW}: `}</label>
                            <label>{specimenCartItem.quantity}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              <div className="total-quantity">
                <label>{LABEL_CONSTANTS.TOTAL_QUANTITY}: &nbsp; </label>
                {totalDiscardQuantity}
              </div>
            </div>
          </div>
          <div className="buyer-info">
            <h3 className="info-header">
              {LABEL_CONSTANTS.BUYER_INFO}
              <div className="button icon" onClick={() => setIsEditBuyerInfoMode(true)}>
                <EditSVG />
              </div>
            </h3>
            <TransactionLabelField
              label={`${LABEL_CONSTANTS.SELLER_NAME}:`}
              value={
                isBuyerInfoEditMode ? (
                  <ValidatedInput
                    useId="seller-name"
                    label={''}
                    type="text"
                    setValidatedState={event => {
                      setCustomerName({
                        value: event.value.replace(/\s+/g, ' '),
                        valid: event.valid && event.value.trim().length > 0,
                      });
                    }}
                    validators={[Validators.REQUIRED]}
                    validatedStateForAutoFill={customerName}
                  />
                ) : (
                  <label>{customerName.value || '--'}</label>
                )
              }
            />
            <TransactionLabelField
              label={`${LABEL_CONSTANTS.SELLER_EMAIL}:`}
              value={
                isBuyerInfoEditMode ? (
                  <ValidatedInput
                    useId="seller-email"
                    label={''}
                    type="email"
                    setValidatedState={event => {
                      setCustomerEmail({
                        value: event.value.replace(/\s+/g, ' '),
                        valid: event.valid && event.value.trim().length > 0,
                      });
                    }}
                    validators={[Validators.REQUIRED, Validators.EMAIL]}
                    validatedStateForAutoFill={customerEmail}
                  />
                ) : (
                  <label>{customerEmail?.value || '--'}</label>
                )
              }
            />
            <TransactionLabelField
              label={`${LABEL_CONSTANTS.SELLER_PHONE_NUMBER}:`}
              value={
                isBuyerInfoEditMode ? (
                  <ValidatedInput
                    useId="seller-phone"
                    label={''}
                    type="phone"
                    setValidatedState={setCustomerPhoneNumber}
                    validators={[Validators.REQUIRED, Validators.PHONE_LENGTH]}
                    validatedStateForAutoFill={customerPhoneNumber}
                  />
                ) : (
                  <label>{customerPhoneNumber?.value || '--'}</label>
                )
              }
            />
          </div>
          <div className="shipping-info">
            <h3 className="info-header">
              {LABEL_CONSTANTS.SHIPPING_INFO}
              <div className="button icon" onClick={() => setIsPreview(false)}>
                <EditSVG />
              </div>
            </h3>
            <TransactionLabelField
              label={`${LABEL_CONSTANTS.WITHDRAW_METHOD}:`}
              value={<label>{LABEL_CONSTANTS.SHIP_TO_ADDRESS || '--'}</label>}
            />
            <TransactionLabelField
              label={`${ADDRESS_CONSTANTS.ADDRESS_LINE_1}:`}
              value={<label>{shippingAddress?.streetAddress.value || '--'}</label>}
            />
            <TransactionLabelField
              label={`${ADDRESS_CONSTANTS.ADDRESS_LINE_2}:`}
              value={<label>{shippingAddress?.streetAddress2.value || '--'}</label>}
            />
            <TransactionLabelField
              label={`${ADDRESS_CONSTANTS.CITY}:`}
              value={<label>{shippingAddress?.city.value || '--'}</label>}
            />
            <TransactionLabelField
              label={`${ADDRESS_CONSTANTS.STATE}:`}
              value={<label>{shippingAddress?.state.value || '--'}</label>}
            />
            <TransactionLabelField
              label={`${ADDRESS_CONSTANTS.ZIP_CODE}:`}
              value={<label>{shippingAddress?.postalCode.value || '--'}</label>}
            />
            <TransactionLabelField
              label={`${ADDRESS_CONSTANTS.COUNTRY}:`}
              value={<label>{shippingAddress?.country.value || '--'}</label>}
            />
            <TransactionLabelField
              label={`${LABEL_CONSTANTS.SHIPPING_INSURANCE}:`}
              value={<label>{shippingSummary?.shippingInsurance || '--'}</label>}
            />
            <TransactionLabelField
              label={`${PAYMENT_CONSTANTS.INSURANCE_AMOUNT}:`}
              value={<label>{`$${parseFloat(shippingSummary?.insuranceAmount?.value || '0').toFixed(2)}`}</label>}
            />
            <TransactionLabelField
              label={`${LABEL_CONSTANTS.TERMS_AND_CONDITIONS}:`}
              value={<label>{shippingSummary?.termsAndConditions || '--'}</label>}
            />
          </div>
          <div className="order-summary">
            <h3>{LABEL_CONSTANTS.ORDER_SUMMARY}</h3>
            <TransactionLabelField
              label={`${PAYMENT_CONSTANTS.SHIPPING_COST}:`}
              value={<label>{`$${shippingSummary?.shippingCost.toFixed(2)}`}</label>}
            />
            <TransactionLabelField
              label={`${PAYMENT_CONSTANTS.HANDLING_PROCESSING}:`}
              value={<label>{`$${shippingSummary?.handlingProcessingFee.toFixed(2)}`}</label>}
            />
            <TransactionLabelField
              label={`${PAYMENT_CONSTANTS.TANK_RENTAL_FEE}:`}
              value={<label>{`$${shippingSummary?.tankRentalFee.toFixed(2)}`}</label>}
            />
            <TransactionLabelField
              label={`${PAYMENT_CONSTANTS.INSURANCE}:`}
              value={<label>{`$${shippingSummary?.insurance.toFixed(2)}`}</label>}
            />
            <TransactionLabelField
              label={`${PAYMENT_CONSTANTS.TOTAL_AMOUNT}:`}
              value={
                <label>
                  <strong>{`$${totalShippingAmount.toFixed(2)}`}</strong>
                </label>
              }
            />
          </div>
          <div className="flex-right shipping-preview-buttons">
            {isBuyerInfoEditMode ? (
              <div className="buttons">
                <button type="button" className={'button green small'} onClick={() => handleCancelClick()}>
                  {BUTTON_CONSTANTS.CANCEL}
                </button>
                <button
                  type="button"
                  className={'button green small'}
                  onClick={() => updateCustomerInfo()}
                  disabled={!(customerName.valid && customerEmail.valid && customerPhoneNumber.valid)}>
                  {BUTTON_CONSTANTS.SAVE}
                </button>
              </div>
            ) : (
              <div className="buttons">
                <button type="button" className={'button green small'} onClick={() => setIsPreview(false)}>
                  {BUTTON_CONSTANTS.BACK}
                </button>
                <button type="button" className={'button green large'} onClick={() => handleProceedToPayment()}>
                  {BUTTON_CONSTANTS.PROCEED_TO_PAYMENT}
                </button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <PaymentInformation setIsPaymentPage={setIsPaymentPage} />
      )}
    </div>
  );
};

export default ShippingPreview;
