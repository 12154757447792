import { FC, useState, useEffect, useCallback } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import { SortedTable } from '../../components';
import ActionMenu from '../../components/action-buttons/ActionButtons';
import { CartItem } from '../../types/interfaces/transactionCart.interfaces';
import { deleteTransactionCartItem, getTransactionCartItems } from '../../api/transactionCartApi';
import { showToast } from '../../services/toast.service';
import { PermissionService } from '../../services/permission.service';
import { fetchTransactionCartItems } from '../../services/transactionCart.service';
import { toastMessages } from '../../constants/errorMessages';
import { ROUTE_PATHS } from '../../constants/routePaths';
import {
  BUTTON_CONSTANTS,
  LABEL_CONSTANTS,
  PAGE_HEADER_CONSTANTS,
  TABLE_HEADER_CONSTANTS,
  TRANSACTION_CONSTANTS,
} from '../../constants/common';
import './transactionCart.scss';

type TransactionCartProps = {
  permissionService: PermissionService;
};

const TransactionCart: FC<TransactionCartProps> = ({ permissionService }): JSX.Element => {
  const navigate = useNavigate();

  const { user } = useAppSelector(state => state);
  const accountId = useAppSelector(state => state.activeAccount.accountId);

  const [transactionCartItems, setTransactionCartItems] = useState<CartItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleGetTransactionCartItems = useCallback(
    async (type: string) => {
      if (user?.userId) {
        try {
          setIsLoading(true);
          const params = { filter: `transactionType eq ${type}` };
          const { data: cartItems } = permissionService.userHasRSGAdminPermission()
            ? await getTransactionCartItems({ userId: user.userId, ...params })
            : await getTransactionCartItems({ userId: user.userId, accountId, ...params });
          setTransactionCartItems(cartItems);
        } catch (error) {
          showToast.error(toastMessages.SOMETHING_WENT_WRONG);
        } finally {
          setIsLoading(false);
        }
      }
    },
    [user, accountId],
  );

  useEffect(() => {
    handleGetTransactionCartItems('Withdraw');
  }, [handleGetTransactionCartItems]);

  const removeTransactionCartItem = async (userId: number, accountId: number, transactionType: string) => {
    try {
      setIsLoading(true);
      setTransactionCartItems([]);
      await deleteTransactionCartItem(userId, accountId, transactionType);
      handleTransactionCartReduxState();
      await handleGetTransactionCartItems('Withdraw');
    } catch (error) {
      showToast.error(toastMessages.REMOVE_TRANSACTION_CART_ITEM_FAIL);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTransactionCartReduxState = () => {
    if (permissionService.userHasRSGAdminPermission()) {
      fetchTransactionCartItems(user.userId);
    } else {
      fetchTransactionCartItems(user.userId, accountId);
    }
  };

  const handleNextStep = (ownerAccountId: number) => {
    ownerAccountId && navigate(ROUTE_PATHS.APP_TRANSACTION_CART_WITHDRAW + ownerAccountId);
  };

  const getTransactions = (headers: string[], type: string) => {
    return (
      <div className="card">
        <h2>
          {type} {TRANSACTION_CONSTANTS.TRANSACTIONS}
        </h2>
        <div className="transaction-table">
          <SortedTable
            headers={headers?.map((header: string) => {
              return { displayName: header };
            })}
            data={transactionCartItems?.map(cartItem => {
              return [
                { content: cartItem.account.name },
                { content: cartItem.totalQuantity },

                {
                  content: (
                    <ActionMenu
                      actionButtons={[
                        { name: `${BUTTON_CONSTANTS.NEXT_STEP}`, action: () => handleNextStep(cartItem.accountId) },
                        {
                          name: `${BUTTON_CONSTANTS.DELETE}`,
                          action: () => user.userId && removeTransactionCartItem(user.userId, cartItem.accountId, type),
                        },
                      ]}
                    />
                  ),
                },
              ];
            })}
            height={256}
            loading={isLoading}
            noResultsMessage={LABEL_CONSTANTS.NO_TRANSACTIONS_FOUND}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="transaction-cart-page">
      <div className="transaction-cart">
        <div className="header">
          <h2 className="transaction-cart-header">{PAGE_HEADER_CONSTANTS.TRANSACTION_CART}</h2>
        </div>
        <hr className="header-hr" />
        <div className="inventory-transactions">
          {getTransactions(
            [TABLE_HEADER_CONSTANTS.ACCOUNT_OWNER, TABLE_HEADER_CONSTANTS.TOTAL_QTY, TABLE_HEADER_CONSTANTS.ACTION],
            'Withdraw',
          )}
        </div>
      </div>
    </div>
  );
};

export default TransactionCart;
