import { Route, Routes } from 'react-router-dom';
import InsuredCustomersReport from '../pages/reports/insured-customers/InsuredCustomersReport';
import { REPORT_PATHS } from '../constants/routePaths';

const ReportsRoutes = () => {
  return (
    <div>
      <Routes>
        <Route path={REPORT_PATHS.INSURED_CUSTOMERS} element={<InsuredCustomersReport />} />
      </Routes>
    </div>
  );
};
export default ReportsRoutes;
