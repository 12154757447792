import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import ShippingInsurance, { ShippingSummary } from './ShippingInsurance';
import ShippingPreview from './shipping-preview/ShippingPreview';
import { BackButton, DateInput, Loader } from '../../components';
import { ValidatedState } from '../../components/custom-input/ValidatedInput';
import ValidatedPickupInfo, { PickupInfo } from '../../components/custom-input/ValidatedPickupInfo';
import TrashCanSvg from '../../components/svgs/TrashCan.svg';
import ValidatedAddress from '../../components/custom-input/ValidatedAddress';
import InsuranceTermsModal from './insurance-terms-conditions/InsuranceTermsModal';
import { RequireAlert } from '../inventory-management/InventoryManagementCollectDeposit';
import {
  deleteSpecimenFromTransactionCart,
  deleteTransactionCartItem,
  getCartCompletionTransaction,
} from '../../api/transactionCartApi';
import { showToast } from '../../services/toast.service';
import { PermissionService } from '../../services/permission.service';
import { fetchTransactionCartItems } from '../../services/transactionCart.service';
import { SpecimenSelection } from '../../types/interfaces';
import { TransactionTypeEnum } from '../../types/enums';
import { CartTransactionResponse, SpecimenCartItem } from '../../types/interfaces/transactionCart.interfaces';
import { withdrawInventory } from '../../api/inventoryApi';
import { getAccountById } from '../../api/accountApi';
import { formatPositiveNumber } from '../../utils/commonUtils';
import { getPickupNotes, getShippingNotes } from '../../utils/addressHelpers';
import { toastMessages } from '../../constants/errorMessages';
import { ROUTE_PATHS } from '../../constants/routePaths';
import {
  BUTTON_CONSTANTS,
  INVENTORY_CONSTANTS,
  INVENTORY_MANAGEMENT_CONSTANTS,
  LABEL_CONSTANTS,
  PAGE_HEADER_CONSTANTS,
  TABLE_HEADER_CONSTANTS,
} from '../../constants/common';
import './transactionCart.scss';

export interface ValidateAddress {
  addressId?: ValidatedState;
  streetAddress: ValidatedState;
  streetAddress2: ValidatedState;
  city: ValidatedState;
  state: ValidatedState;
  postalCode: ValidatedState;
  country: ValidatedState;
  phone?: ValidatedState;
}

type CustomerInfoForm = {
  customerName: ValidatedState;
  customerEmail: ValidatedState;
  customerPhoneNumber: ValidatedState;
};

type TransactionCartWithdrawProps = {
  permissionService: PermissionService;
};

const defaultShippingSummary: ShippingSummary = {
  shippingInsurance: '',
  insuranceAmount: { value: '', valid: false },
  insurance: 0,
  termsAndConditions: '',
  handlingProcessingFee: 0,
  tankRentalFee: 0,
  shippingCost: 0,
};

const TransactionCartWithdraw = ({ permissionService }: TransactionCartWithdrawProps) => {
  const navigate = useNavigate();
  const { accountId } = useParams();
  const initialFormState = { value: '', valid: false };

  const { user } = useAppSelector(state => state);
  const paymentStatus = useAppSelector(state => state.payment.addedPaymentMethod);
  const activeAccount = useAppSelector(state => state.activeAccount);

  const [withdrawCartItem, setWithdrawCartItem] = useState<CartTransactionResponse>();
  const [withdrawMethodIsShip, setWithdrawMethodIsShip] = useState<boolean>();
  const [notes, setNotes] = useState<string>('');
  const [address, setAddress] = useState<ValidateAddress>();
  const [addressValid, setAddressValid] = useState<boolean>();
  const [pickupInfo, setPickupInfo] = useState<PickupInfo>();
  const [pickupInfoValid, setPickupInfoValid] = useState<boolean>();
  const [validate, setValidate] = useState<boolean>(false);
  const [rentDryVaporShipperTank, setRentDryVaporShipperTank] = useState<string>('');
  const [withdrawingInventory, setWithdrawingInventory] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [shippingInsuranceValid, setShippingInsuranceValid] = useState<boolean>(false);
  const [breedDate, setBreedDate] = useState<Date>();
  const [isPreview, setIsPreview] = useState<boolean>(false);
  const [shippingSummary, setShippingSummary] = useState<ShippingSummary>(defaultShippingSummary);
  const [customerInfo, setCustomerInfo] = useState<CustomerInfoForm>({
    customerName: initialFormState,
    customerEmail: initialFormState,
    customerPhoneNumber: initialFormState,
  });
  const [insuranceTermsModalOpen, setInsuranceTermsModalOpen] = useState(false);

  const getCartWithdrawTransaction = useCallback(async () => {
    setLoading(true);
    if (user?.userId && accountId) {
      try {
        const { data: cartCompletionTransaction } = await getCartCompletionTransaction(
          user?.userId,
          Number(accountId),
          TransactionTypeEnum.Withdraw,
        );
        setWithdrawCartItem(cartCompletionTransaction);
      } catch (error: any) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      }
    } else {
      setWithdrawCartItem(undefined);
    }
    setLoading(false);
  }, [user, accountId]);

  useEffect(() => {
    getCartWithdrawTransaction();
  }, [getCartWithdrawTransaction]);

  useEffect(() => {
    const getCustomerAccountInfo = async () => {
      if (accountId) {
        try {
          const { data: accountResponse } = await getAccountById(Number(accountId));
          setCustomerInfo({
            customerName: { value: accountResponse?.contactFirstName + ' ' + accountResponse?.contactLastName, valid: true },
            customerEmail: { value: accountResponse?.contactEmail, valid: true },
            customerPhoneNumber: { value: accountResponse?.contactPhone, valid: true },
          });
        } catch (error) {
          showToast.error(toastMessages.SOMETHING_WENT_WRONG);
        }
      }
    };

    getCustomerAccountInfo();
  }, [accountId]);

  const totalDiscardQuantity = withdrawCartItem?.specimenCartItems?.reduce(
    (accumulator: number, specimenCartItem) => accumulator + specimenCartItem?.quantity,
    0,
  );

  const isQuantityValid = (showToasts?: boolean) => {
    if (
      withdrawCartItem?.specimenCartItems.length === 0 ||
      withdrawCartItem?.specimenCartItems.some(specimenCartItem => specimenCartItem.quantity === 0)
    ) {
      showToasts && showToast.error(toastMessages.INVALID_ZERO_QUANTITY);
      return false;
    } else if (
      withdrawCartItem?.specimenCartItems.some(
        specimenCartItem => specimenCartItem.quantity > specimenCartItem?.availableQuantity,
      )
    ) {
      showToasts && showToast.error(toastMessages.CANT_WITHDRAW_MORE_THAN_AVAIL_QTY);
      return false;
    } else {
      return true;
    }
  };

  const checkPaymentInfoValid = () => {
    if (permissionService.userHasRSGAdminPermission()) {
      return true;
    } else {
      return paymentStatus != undefined && paymentStatus && activeAccount?.billingAmount?.outstandingBalanceCents === 0;
    }
  };

  const isAdditionalInfoValid = () => {
    return (
      checkPaymentInfoValid() &&
      ((withdrawMethodIsShip && shippingInsuranceValid && rentDryVaporShipperTank.length > 0) || !withdrawMethodIsShip) &&
      breedDate != undefined &&
      breedDate &&
      ((withdrawMethodIsShip && addressValid) || (!withdrawMethodIsShip && pickupInfoValid))
    );
  };

  const onWithdrawQtyChange = (e: any, index: number) => {
    const count = formatPositiveNumber(e.target.value);
    if (withdrawCartItem) {
      let newSpecimens = { ...withdrawCartItem };
      newSpecimens.specimenCartItems[index].quantity = count;
      setWithdrawCartItem(newSpecimens);
    }
  };

  const removeTransactionCartItem = async (accountId: number) => {
    try {
      await deleteTransactionCartItem(user?.userId, accountId, TransactionTypeEnum.Withdraw);
      handleTransactionCart();
    } catch (error) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    }
  };

  const handleSpecimenDelete = async (e: any, index: number) => {
    const transactionCartItemId = withdrawCartItem?.specimenCartItems[index]?.transactionCartItemId;
    if (transactionCartItemId) {
      try {
        await deleteSpecimenFromTransactionCart(transactionCartItemId);
        getCartWithdrawTransaction();
        handleTransactionCart();
      } catch (error: any) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      }
    }
  };

  const handleTransactionCart = () => {
    if (permissionService.userHasRSGAdminPermission()) {
      fetchTransactionCartItems(user.userId);
    } else {
      fetchTransactionCartItems(user.userId, Number(accountId));
    }
  };

  const handleErrors = (specimenCartItem: SpecimenCartItem) => {
    return specimenCartItem.quantity > specimenCartItem?.availableQuantity
      ? 'error'
      : specimenCartItem.quantity === 0
      ? 'warning'
      : '';
  };

  const inputQuantityElement = (specimenCartItem: SpecimenCartItem, index: number) => {
    return <input type="text" placeholder="0" value={specimenCartItem.quantity} onChange={e => onWithdrawQtyChange(e, index)} />;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    if (!withdrawingInventory) return;

    setWithdrawingInventory(false);
    setValidate(true);

    if (isAdditionalInfoValid() && isQuantityValid(true)) {
      const specimensSelected = Array.from(new Set(withdrawCartItem?.specimenCartItems.map(specimen => specimen.animalId))).map(
        animalId => {
          return {
            specimens: withdrawCartItem?.specimenCartItems
              .filter(specimen => specimen.animalId === animalId)
              .map(specimen => {
                if (specimen) {
                  return { specimenId: specimen.specimenId, quantity: specimen.quantity };
                }
              }),
          };
        },
      );

      const withdrawRequestBody = {
        animalSelections: specimensSelected as { specimens: (SpecimenSelection | undefined)[] }[],
        breedDate: breedDate!,
        notes:
          notes +
          '\nBreed Date: ' +
          breedDate?.toLocaleDateString('en-US') +
          (withdrawMethodIsShip
            ? '\nRent a dry vapor shipper tank from RSG for shipping order: ' + rentDryVaporShipperTank
            : '') +
          (withdrawMethodIsShip ? getShippingNotes(address) : getPickupNotes(pickupInfo)),
      };

      try {
        await withdrawInventory(withdrawRequestBody, accountId!);
        showToast.success(toastMessages.WITHDRAW_SUBMITTED);

        await removeTransactionCartItem(Number(accountId));
        navigate(ROUTE_PATHS.APP_TRANSACTION_CART);
      } catch {
        showToast.error(toastMessages.WITHDRAW_SUBMIT_FAIL);
      } finally {
        setWithdrawingInventory(true);
      }
    } else {
      setWithdrawingInventory(true);
    }
  };

  const handleCheckout = async (e: React.FormEvent) => {
    setValidate(true);
    if (isAdditionalInfoValid() && isQuantityValid(true)) {
      if (shippingSummary?.shippingInsurance === LABEL_CONSTANTS.YES) {
        if (shippingSummary.termsAndConditions === LABEL_CONSTANTS.ACCEPTED) {
          setIsPreview(true);
        } else {
          setInsuranceTermsModalOpen(true);
        }
      } else {
        setShippingSummary({ ...shippingSummary, termsAndConditions: LABEL_CONSTANTS.DECLINED });
        setIsPreview(true);
      }
    }
  };

  const handleConfirm = async () => {
    setShippingSummary({ ...shippingSummary, termsAndConditions: LABEL_CONSTANTS.ACCEPTED });
    setInsuranceTermsModalOpen(false);
    setIsPreview(true);
  };

  return (
    <div>
      {!isPreview && <BackButton pagesBack={2} />}
      <div className="inventory-action card">
        <h1>{PAGE_HEADER_CONSTANTS.CREATE_WITHDRAW_TRANSACTION}</h1>
        {!isPreview ? (
          <div className="transaction-cart-withdraw">
            <div className="form-row">
              <label>{LABEL_CONSTANTS.ACCOUNT_OWNER_ID}:</label>
              <div className="input-container">
                <p>{withdrawCartItem?.account?.name ?? '--'}</p>
              </div>
            </div>
            <br />

            <h2>{LABEL_CONSTANTS.INVENTORY_ITEMS}</h2>
            <div className="desk-select-specimen">
              <div className="specimen-table">
                <table>
                  <thead>
                    <tr>
                      <th>{TABLE_HEADER_CONSTANTS.ANIMAL}</th>
                      <th>{TABLE_HEADER_CONSTANTS.INVENTORY_TYPE}</th>
                      <th>{TABLE_HEADER_CONSTANTS.LOT_DATE_NO}</th>
                      <th>{TABLE_HEADER_CONSTANTS.AVAILABLE_QTY}</th>
                      <th>{TABLE_HEADER_CONSTANTS.QTY_TO_WITHDRAW}</th>
                      <th>{TABLE_HEADER_CONSTANTS.ACTION}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading &&
                      withdrawCartItem?.specimenCartItems?.map((specimenCartItem, index: number) => {
                        return (
                          <tr
                            className={handleErrors(specimenCartItem)}
                            key={'specimen-row:' + specimenCartItem.specimenId + '-index:' + index}>
                            <td>
                              {specimenCartItem.animal?.code} - {specimenCartItem.animal?.name}
                            </td>
                            <td>{specimenCartItem.specimen.specimenType?.name}</td>
                            <td>{`${new Date(specimenCartItem.specimen.freezeDate).toLocaleDateString()} - ${
                              specimenCartItem.specimen.specimenId
                            }`}</td>
                            <td>{specimenCartItem.availableQuantity}</td>
                            <td>{inputQuantityElement(specimenCartItem, index)}</td>
                            <td>
                              <button className={'trashIcon button'} onClick={e => handleSpecimenDelete(e, index)}>
                                <TrashCanSvg />
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    {!loading && withdrawCartItem?.specimenCartItems.length === 0 && (
                      <tr>
                        <td colSpan={6}>
                          <>{LABEL_CONSTANTS.NO_INVENTORY_FOUND}</>
                        </td>
                      </tr>
                    )}
                    {loading && (
                      <tr>
                        <td colSpan={6}>
                          <>
                            <br />
                            <br />
                            <Loader loaderSize="small" />
                            <br />
                            <br />
                          </>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td colSpan={5}>{LABEL_CONSTANTS.TOTAL_QUANTITY}:</td>
                      <td>
                        <input type="text" disabled value={totalDiscardQuantity} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {/** Mobile View */}
            <div className="xs-select-specimens">
              {loading ? (
                <Loader addedSpace loaderSize="small" />
              ) : !loading && withdrawCartItem?.specimenCartItems.length === 0 ? (
                <div className="result-item">{`${LABEL_CONSTANTS.NO_RESULTS_FOUND}.`}</div>
              ) : (
                <div>
                  {withdrawCartItem &&
                    withdrawCartItem.specimenCartItems.map((specimenCartItem, index: number) => {
                      return (
                        <div
                          className={`card max-width ${handleErrors(specimenCartItem)}`}
                          key={'specimen-row:' + specimenCartItem.specimenId + '-index:' + index}>
                          <div className="selected-specimens-row">
                            <div className="select-specimens">
                              <div className="card-content-section">
                                <div className="left column">
                                  <label>{`${TABLE_HEADER_CONSTANTS.ANIMAL}: `}</label>
                                  <label>{`${specimenCartItem.animal?.code} - ${specimenCartItem.animal?.name}`}</label>
                                </div>
                                <div className="left column">
                                  <label>{`${TABLE_HEADER_CONSTANTS.INVENTORY_TYPE}: `}</label>
                                  <label>{`${specimenCartItem.specimen.specimenType?.name}`}</label>
                                </div>
                                <div className="right column">
                                  <label>{`${TABLE_HEADER_CONSTANTS.LOT_DATE_NO}: `}</label>
                                  <label>{`${new Date(specimenCartItem.specimen.freezeDate).toLocaleDateString()} - ${
                                    specimenCartItem.specimen.specimenId
                                  }`}</label>
                                </div>
                              </div>
                              <div className="card-content-section">
                                <div className="left column">
                                  <label>{`${TABLE_HEADER_CONSTANTS.AVAILABLE_QTY}: `}</label>
                                  <label>{`${specimenCartItem.availableQuantity}`}</label>
                                </div>
                                <div className="left column ">
                                  <label>{`${TABLE_HEADER_CONSTANTS.QTY_TO_WITHDRAW}: `}</label>
                                  <label>{inputQuantityElement(specimenCartItem, index)}</label>
                                </div>
                                <div className="right center column">
                                  <label>{`${TABLE_HEADER_CONSTANTS.ACTION}:`}</label>
                                  <button className={'trashIcon button'} onClick={e => handleSpecimenDelete(e, index)}>
                                    <TrashCanSvg />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  <div className="total-quantity">
                    <label>{LABEL_CONSTANTS.TOTAL_QUANTITY}: &nbsp; </label>
                    {totalDiscardQuantity}
                  </div>
                </div>
              )}
            </div>

            <br />

            <div className="form-row">
              <label>
                *{LABEL_CONSTANTS.BREED_DATE}:
                <RequireAlert isActive={validate && !breedDate} />
              </label>
              <DateInput value={breedDate} onChange={setBreedDate} isClearable minDate={new Date()} maxDate={null} />
            </div>

            <div className="form-row expanded-text-area">
              <label htmlFor="inventory-notes-textarea">{LABEL_CONSTANTS.NOTES}:</label>
              <textarea
                value={notes}
                placeholder="Notes"
                id="inventory-notes-textarea"
                onChange={e => setNotes(e.target.value)}
              />
            </div>
            <br />
            <div className="form-row">
              <label>{LABEL_CONSTANTS.WITHDRAW_METHOD}:</label>
              <div className="radio-row">
                &ensp;
                <input
                  type="radio"
                  id="shipping"
                  value="true"
                  checked={withdrawMethodIsShip}
                  onChange={() => setWithdrawMethodIsShip(true)}
                />
                <label htmlFor="shipping">{LABEL_CONSTANTS.SHIP_TO_ADDRESS}</label>
                <input
                  type="radio"
                  id="pickup"
                  value="false"
                  checked={withdrawMethodIsShip === false}
                  onChange={() => setWithdrawMethodIsShip(false)}
                />
                <label htmlFor="pickup">{LABEL_CONSTANTS.PICKUP}</label>
              </div>
            </div>

            {!withdrawingInventory && <Loader loaderSize={'small'} simple />}

            {withdrawMethodIsShip === true && (
              <>
                <div className="form-row rent-tank">
                  <label className="rent-tank-radio-row-label">
                    <div>
                      *{INVENTORY_CONSTANTS.HAVE_TANK}:
                      <RequireAlert isActive={validate && !rentDryVaporShipperTank} />
                    </div>
                  </label>
                  <div className=" rent-tank-radio-row radio-row">
                    &ensp;
                    <input
                      type="radio"
                      name="nitrogen-tank"
                      checked={rentDryVaporShipperTank === LABEL_CONSTANTS.YES}
                      onChange={() => setRentDryVaporShipperTank(LABEL_CONSTANTS.YES)}
                    />
                    <label>{LABEL_CONSTANTS.YES_NEED_TO_RENT_TANK}</label>
                    <input
                      type="radio"
                      name="nitrogen-tank"
                      checked={rentDryVaporShipperTank === LABEL_CONSTANTS.NO}
                      onChange={() => setRentDryVaporShipperTank(LABEL_CONSTANTS.NO)}
                    />
                    <label>{LABEL_CONSTANTS.NO_HAVE_MY_OWN_TANK}</label>
                  </div>
                </div>

                <h2>{LABEL_CONSTANTS.SHIPPING_INFO}</h2>
                <p>{INVENTORY_MANAGEMENT_CONSTANTS.WITHDRAW_SHIPPING_NOTE}</p>
                <br />
                <ValidatedAddress validate={validate} setValid={setAddressValid} setAddress={setAddress} address={address} />
                <br />
                <ShippingInsurance
                  validate={validate}
                  setValid={setShippingInsuranceValid}
                  setShippingSummary={setShippingSummary}
                  rentDryVaporShipperTank={rentDryVaporShipperTank}
                  shippingSummary={shippingSummary}
                />
                {shippingSummary.shippingInsurance === LABEL_CONSTANTS.YES &&
                  shippingSummary.termsAndConditions === LABEL_CONSTANTS.ACCEPTED && (
                    <div className="form-row">
                      <label>{LABEL_CONSTANTS.TERMS_AND_CONDITIONS}:</label>
                      <div className="input-container">
                        <p>{shippingSummary.termsAndConditions ?? '--'}</p>
                      </div>
                    </div>
                  )}
              </>
            )}

            {withdrawMethodIsShip === false && (
              <>
                <ValidatedPickupInfo validate={validate} setValid={setPickupInfoValid} setPickupInfo={setPickupInfo} />
                <br />
                <div className="flex-right withdraw-submit-section">
                  <p className="withdraw-inventory-guide withdraw-submit-guide">
                    {INVENTORY_MANAGEMENT_CONSTANTS.COMMON_INVENTORY_FOOTER}
                  </p>
                </div>
              </>
            )}

            <div className="flex-right margin-top">
              <button
                type="submit"
                className={`button green ${withdrawMethodIsShip ? 'large' : 'small'} ${
                  !isQuantityValid() || !isAdditionalInfoValid() ? 'disabled' : ''
                }`}
                onClick={withdrawMethodIsShip ? handleCheckout : handleSubmit}>
                {withdrawMethodIsShip ? BUTTON_CONSTANTS.PROCEED_TO_CHECKOUT : BUTTON_CONSTANTS.SUBMIT}
              </button>
            </div>
          </div>
        ) : (
          <ShippingPreview
            withdrawCartItem={withdrawCartItem}
            shippingAddress={address}
            shippingSummary={shippingSummary}
            customerInfo={customerInfo}
            setIsPreview={setIsPreview}
            setCustomerInfo={setCustomerInfo}
          />
        )}
        <InsuranceTermsModal
          isOpen={insuranceTermsModalOpen}
          onClose={() => setInsuranceTermsModalOpen(false)}
          onAccept={() => handleConfirm()}
        />
      </div>
    </div>
  );
};

export default TransactionCartWithdraw;
