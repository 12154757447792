import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import { AccountSearch, AnimalSearch, BackButton, Loader, Modal, SelectSpecimens, ValidatedInput } from '../../components';
import { SelectedSpecimenQuantity } from '../../components/specimen-tables/SelectSpecimens';
import FilterDropdown, { FilterOption } from '../../components/custom-input/FilterDropdown';
import { ValidatedState } from '../../components/custom-input/ValidatedInput';
import { AlertSVG } from '../../components/svgs';
import { Account, AccountUserInfo, Animal, SpecimenSelection } from '../../types/interfaces';
import { TransactionTypeEnum, Validators } from '../../types/enums';
import { discard, inventoryDeposit, sellToExistingUser, sellToNewUser, usesInventory } from '../../api/inventoryApi';
import { getUserByUsername } from '../../api/userApi';
import { addSelectedSpecimenToCart } from '../../api/transactionCartApi';
import { showToast } from '../../services/toast.service';
import { filterOutDeactivatedAccounts } from '../../services/user.service';
import { specifiedAccountUserHasInventoryPermission } from '../../services/permission.service';
import { fetchTransactionCartItems } from '../../services/transactionCart.service';
import { toastMessages } from '../../constants/errorMessages';
import { ROUTE_PATHS } from '../../constants/routePaths';
import {
  LABEL_CONSTANTS,
  BUTTON_CONSTANTS,
  PAGE_HEADER_CONSTANTS,
  TRANSACTION_CONSTANTS,
  ANIMAL_CONSTANTS,
  VALIDATION_ALERT_CONSTANTS,
} from '../../constants/common';
import './transactionManagement.scss';

const CreateClientTransaction = () => {
  const initialFormState = { value: '', valid: false };
  const navigate = useNavigate();
  const { user } = useAppSelector(state => state);
  const cartItems = useAppSelector(state => state.transactionCart.transactionCartItems);

  const [accountOwner, setAccountOwner] = useState<Account>();
  const [buyerAccount, setBuyerAccount] = useState<Account>();
  const [animal, setAnimal] = useState<Animal>();
  const [depositQuantity, setDepositQuantity] = useState<ValidatedState>(initialFormState);
  const [notes, setNotes] = useState<string>('');
  const [validate, setValidate] = useState<boolean>(false);
  const [modalErrorMessage, setModalErrorMessage] = useState<string>('');
  const [creatingTransaction, setCreatingTransaction] = useState<boolean>(true);
  const [transactionType, setTransactionType] = useState<FilterOption<TransactionTypeEnum>>({
    name: '',
    value: TransactionTypeEnum.Default,
  });
  const [selectedQuantities, setSelectedQuantities] = useState<{ valid: boolean; value: SelectedSpecimenQuantity[] }>({
    valid: false,
    value: [],
  });

  const [sellToNonExistingUser, setSellToNonExistingUser] = useState<boolean>(false);
  const [buyerEmail, setBuyerEmail] = useState<ValidatedState>(initialFormState);
  const [onAddInfoStep, setOnAddInfoStep] = useState<boolean>(false);
  const [buyerUserId, setBuyerUserId] = useState<number>();
  const [selectedBuyerAccount, setSelectedBuyerAccount] = useState<FilterOption<string>>({ name: '', value: '' });
  const [buyerFirstName, setBuyerFirstName] = useState<ValidatedState>(initialFormState);
  const [buyerLastName, setBuyerLastName] = useState<ValidatedState>(initialFormState);
  const [buyerPhoneNumber, setBuyerPhoneNumber] = useState<ValidatedState>(initialFormState);
  const [dropdownAccounts, setDropdownAccounts] = useState<FilterOption<string>[]>([]);
  const [specimenAddingToCart, setSpecimenAddingToCart] = useState<boolean>(true);

  const transactionTypeOptions: FilterOption<string>[] = [
    { name: 'Deposit', value: TransactionTypeEnum.Deposit },
    { name: 'Use', value: TransactionTypeEnum.Use },
    { name: 'Withdraw', value: TransactionTypeEnum.Withdraw },
    { name: 'Sell', value: TransactionTypeEnum.Sell },
    { name: 'Discard', value: TransactionTypeEnum.Discard },
  ];

  useEffect(() => {
    setAnimal(undefined);
  }, [accountOwner]);

  useEffect(() => {
    selectedQuantities.value.forEach(inventoryLot => {
      if (inventoryLot.selected && inventoryLot.quantity > inventoryLot.availableQuantity) {
        if (transactionType.value === TransactionTypeEnum.Sell) {
          showToast.error(toastMessages.CANT_SELL_MORE_THAN_AVAIL_QTY);
        } else if (transactionType.value === TransactionTypeEnum.Withdraw) {
          showToast.error(toastMessages.CANT_WITHDRAW_MORE_THAN_AVAIL_QTY);
        } else if (transactionType.value === TransactionTypeEnum.Use) {
          showToast.error(toastMessages.CANT_USE_MORE_THAN_AVAIL_QTY);
        } else if (transactionType.value === TransactionTypeEnum.Discard) {
          showToast.error(toastMessages.INVALID_DISCARD_QUANTITY);
        }
      }
    });
  }, [selectedQuantities]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!creatingTransaction) return;
    setValidate(true);

    if (formIsValid()) {
      setCreatingTransaction(false);

      switch (transactionType.value) {
        case TransactionTypeEnum.Deposit:
          createDepositTransaction();
          break;
        case TransactionTypeEnum.Use:
          createUseTransaction();
          break;
        case TransactionTypeEnum.Sell:
          createSellTransaction();
          break;
        case TransactionTypeEnum.Discard:
          createDiscardTransaction();
          break;
        default:
          showToast.error(toastMessages.SOMETHING_WENT_WRONG);
          break;
      }
    }
  };

  const formIsValid = (): boolean => {
    const isTransactionTypeValid = transactionType.value !== undefined;
    const isAccountOwnerValid = accountOwner !== undefined;
    const hasNotes = notes.length > 0;
    const isDepositValid = transactionType.value === TransactionTypeEnum.Deposit && Number(depositQuantity.value) > 0;
    const isAnimalAndQuantityValid =
      animal !== undefined && selectedQuantities.value.length > 0 && selectedQuantities.value.every(item => item.quantity > 0);

    const isInventoryValid = selectedQuantities.value.every(
      inventoryLot => !(inventoryLot.quantity > inventoryLot.availableQuantity),
    );

    return (
      isTransactionTypeValid &&
      isAccountOwnerValid &&
      hasNotes &&
      checkIfSellIsValid() &&
      (isDepositValid || isAnimalAndQuantityValid) &&
      isInventoryValid
    );
  };

  const checkIfSellIsValid = () => {
    const isSellTransaction = transactionType.value === TransactionTypeEnum.Sell;
    if (!isSellTransaction) return true;

    const isSellToExistingUser = !sellToNonExistingUser && buyerAccount != undefined;
    const isSellToNonExistingUser = sellToNonExistingUser && buyerEmail.valid;
    const isAddInfoStepValid =
      !onAddInfoStep ||
      (onAddInfoStep &&
        buyerFirstName.valid &&
        buyerLastName.valid &&
        (selectedBuyerAccount.value.length > 0 || buyerPhoneNumber.valid));

    return selectedQuantities.valid && (isSellToExistingUser || (isSellToNonExistingUser && isAddInfoStepValid));
  };

  const createDepositTransaction = async () => {
    try {
      await inventoryDeposit(Number(accountOwner?.accountId), +depositQuantity.value, notes ?? '');
      showToast.success(toastMessages.DEPOSIT_INITIATED);
      navigate(ROUTE_PATHS.APP_TRANSACTION_MANAGEMENT);
    } catch (error) {
      showToast.error(toastMessages.FAILED_DEPOSIT_INITIATED);
    } finally {
      setCreatingTransaction(true);
    }
  };

  const createUseTransaction = async () => {
    try {
      await usesInventory({
        accountId: Number(accountOwner?.accountId),
        notes: notes,
        specimenSelections: selectedQuantities.value.map((q: SelectedSpecimenQuantity) => {
          return {
            specimenId: q.specimenId,
            quantityUsed: q.quantity,
            selectedCanisterId: q.selectedCanisterId,
          } as SpecimenSelection;
        }),
      });
      showToast.success(toastMessages.USE_HAS_BEEN_SUBMITTED);
      navigate(ROUTE_PATHS.APP_TRANSACTION_MANAGEMENT);
    } catch (error: any) {
      if (error?.response?.status === 409) {
        const detailObject = JSON.parse(error.response.data.detail.substring(error.response.data.detail.indexOf('{')));
        const errMsg = detailObject.ErrMsg;
        setModalErrorMessage(errMsg);
      } else {
        showToast.error(toastMessages.FAILED_TO_SUBMIT_USE);
      }
    } finally {
      setCreatingTransaction(true);
    }
  };

  const createSellTransaction = async () => {
    if (sellToNonExistingUser) {
      setValidate(onAddInfoStep);

      if (!onAddInfoStep) {
        if (buyerEmail.valid) {
          searchByEmail();
        }
        setCreatingTransaction(true);
        return;
      }
      if (buyerEmail.valid && buyerFirstName.valid && buyerLastName.valid) {
        setCreatingTransaction(false);

        if (selectedBuyerAccount.value.length > 0 && isQuantityValid()) {
          sellTransactionForExistingUser(
            Number(accountOwner?.accountId),
            user.userId,
            +selectedBuyerAccount.value,
            selectedQuantities,
            notes,
          );
        } else if (buyerPhoneNumber.valid && isQuantityValid()) {
          try {
            await sellToNewUser({
              sellerAccountId: Number(accountOwner?.accountId),
              sellerUserId: user.userId,
              specimenSelections: selectedQuantities.value.map((q: SelectedSpecimenQuantity) => {
                return { specimenId: q.specimenId, quantity: q.quantity } as SpecimenSelection;
              }),
              notes: notes,
              firstName: buyerFirstName.value,
              lastName: buyerLastName.value,
              email: buyerEmail.value,
              phone: buyerPhoneNumber.value,
            });
            showToast.success(toastMessages.SALE_HAS_BEEN_SUBMITTED);
            navigate(ROUTE_PATHS.APP_TRANSACTION_MANAGEMENT);
          } catch (error) {
            showToast.error(toastMessages.FAILED_TO_SUBMIT_SALE);
          } finally {
            setCreatingTransaction(true);
          }
        } else {
          setCreatingTransaction(true);
        }
      }
    } else {
      sellTransactionForExistingUser(
        Number(accountOwner?.accountId),
        user.userId,
        Number(buyerAccount?.accountId),
        selectedQuantities,
        notes,
      );
    }
  };

  const sellTransactionForExistingUser = async (
    ownerAccountId: number,
    ownerUserId: number,
    buyerAccountId: number,
    selectedQuantities: { valid: boolean; value: SelectedSpecimenQuantity[] },
    notes: string,
  ) => {
    try {
      await sellToExistingUser({
        sellerAccountId: ownerAccountId,
        sellerUserId: ownerUserId,
        buyerAccountId: +buyerAccountId,
        specimenSelections: selectedQuantities.value.map((q: SelectedSpecimenQuantity) => {
          return { specimenId: q.specimenId, quantity: q.quantity } as SpecimenSelection;
        }),
        notes: notes,
      });
      showToast.success(toastMessages.SALE_HAS_BEEN_SUBMITTED);
      navigate(ROUTE_PATHS.APP_TRANSACTION_MANAGEMENT);
    } catch (error) {
      showToast.error(toastMessages.FAILED_TO_SUBMIT_SALE);
    } finally {
      setCreatingTransaction(true);
      resetAdditionalInfo();
    }
  };

  const createDiscardTransaction = async () => {
    try {
      await discard(accountOwner?.accountId!, selectedQuantities.value, notes);
      showToast.success(toastMessages.DISCARD_SUCCESSFULLY_REQUESTED);
      navigate(ROUTE_PATHS.APP_TRANSACTION_MANAGEMENT);
    } catch (error) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    } finally {
      setCreatingTransaction(true);
    }
  };

  const searchByEmail = async () => {
    try {
      const res = await getUserByUsername(buyerEmail.value, { include: 'Accounts.Account,Accounts.Roles' });
      let accounts = res.data.accounts;
      accounts = filterOutDeactivatedAccounts(accounts);

      const dropdownOptions = accounts
        .filter((account: AccountUserInfo) => {
          return specifiedAccountUserHasInventoryPermission(account);
        })
        .map((account: AccountUserInfo): FilterOption<string> => {
          return {
            name: account.account!.name,
            value: account.accountId.toString(),
            optionWarning:
              account.account?.outstandingBalanceCents != undefined &&
              account.account?.penaltyCents != undefined &&
              (account.account?.outstandingBalanceCents > 0 || account.account?.penaltyCents > 0)
                ? true
                : false,
          };
        });

      if (dropdownOptions.length > 0) {
        setSelectedBuyerAccount({ name: '', value: '' });
        setBuyerUserId(res.data.userId);
        setBuyerFirstName({ valid: true, value: res.data.firstName });
        setBuyerLastName({ valid: true, value: res.data.lastName });
        setBuyerPhoneNumber(initialFormState);
        setDropdownAccounts(dropdownOptions);
      } else {
        resetAdditionalInfo();
      }
    } catch (error) {
      resetAdditionalInfo();
    } finally {
      setOnAddInfoStep(true);
    }
  };

  const isQuantityValid = () => {
    if (selectedQuantities.value.every(inventoryLot => inventoryLot.quantity === 0)) {
      showToast.error(toastMessages.INVALID_ZERO_QUANTITY);
      return false;
    } else if (selectedQuantities.valid) {
      return true;
    } else {
      return false;
    }
  };

  const resetAdditionalInfo = () => {
    setSelectedBuyerAccount({ name: '', value: '' });
    setBuyerUserId(undefined);
    setBuyerFirstName(initialFormState);
    setBuyerLastName(initialFormState);
    setBuyerPhoneNumber(initialFormState);
    setDropdownAccounts([]);
  };

  const checkValidity = (): boolean => {
    return (
      transactionType.value !== undefined &&
      accountOwner !== undefined &&
      animal !== undefined &&
      selectedQuantities.value.length > 0 &&
      selectedQuantities.value.every(item => item.quantity > 0) &&
      selectedQuantities.value.every(inventoryLot => !(inventoryLot.quantity > inventoryLot.availableQuantity))
    );
  };

  const isNextStepValid = () => {
    if (
      checkValidity() ||
      (accountOwner != undefined && !animal && cartItems?.some(item => item.accountId === accountOwner?.accountId))
    ) {
      return true;
    } else {
      return false;
    }
  };

  const addSpecimenToCart = async () => {
    if (checkValidity()) {
      try {
        const selectedSpecimens = {
          userId: user.userId,
          accountId: accountOwner?.accountId!,
          animalId: animal?.animalId!,
          specimenCartItems: selectedQuantities.value.map(specimen => {
            return {
              specimenId: specimen.specimenId,
              transactionType: transactionType.value,
              quantity: specimen.quantity,
            };
          }),
        };

        await addSelectedSpecimenToCart(selectedSpecimens);
        setAnimal(undefined);
        fetchTransactionCartItems(user.userId);
        showToast.success(toastMessages.ADD_REQUEST_TO_CART_SUCCESS);
      } catch (error) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      } finally {
        setSpecimenAddingToCart(true);
      }
    } else {
      setSpecimenAddingToCart(true);
    }
  };

  const handleAddMoreSpecimen = () => {
    if (!specimenAddingToCart) return;
    setSpecimenAddingToCart(false);
    addSpecimenToCart();
  };

  const handleNextStep = async () => {
    if (!specimenAddingToCart) return;
    setSpecimenAddingToCart(false);

    if (checkValidity()) {
      try {
        await addSpecimenToCart();
        navigate(ROUTE_PATHS.APP_TRANSACTION_CART_WITHDRAW + accountOwner?.accountId);
      } catch (error) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      }
    } else {
      setSpecimenAddingToCart(true);
      navigate(ROUTE_PATHS.APP_TRANSACTION_CART_WITHDRAW + accountOwner?.accountId);
    }
  };

  return (
    <div className="create-client-transaction">
      <BackButton />
      <Modal isOpen={modalErrorMessage.length > 0} onClose={() => setModalErrorMessage('')}>
        <div className="sign-out-modal">
          <div className="body">
            <p className="modal-p-side-margin">{modalErrorMessage}</p>
          </div>
          <div className="footer">
            <button className="button green small" onClick={() => setModalErrorMessage('')}>
              {BUTTON_CONSTANTS.OK}
            </button>
          </div>
        </div>
      </Modal>

      <div className="inventory-action card">
        <h1>{PAGE_HEADER_CONSTANTS.CREATE_CLIENT_TRANSACTION}</h1>
        <h2>{TRANSACTION_CONSTANTS.TRANSACTION_INFORMATION}</h2>

        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <label>*{TRANSACTION_CONSTANTS.TRANSACTION_TYPE}:</label>
            <div className="input-container">
              <FilterDropdown options={transactionTypeOptions} value={transactionType} onChange={setTransactionType} />
            </div>
          </div>
          {(!creatingTransaction || !specimenAddingToCart) && <Loader loaderSize={'small'} simple />}
          <div className="form-row">
            <label>*{LABEL_CONSTANTS.ACCOUNT_OWNER_ID}:</label>
            <div className="input-container">
              <AccountSearch onChange={setAccountOwner} showIcon />
            </div>
          </div>

          {transactionType.value && transactionType.value !== TransactionTypeEnum.Deposit && (
            <>
              <div className="form-row">
                <label>*{ANIMAL_CONSTANTS.ANIMAL_NAME}:</label>
                <div className="input-container">
                  <AnimalSearch
                    onChange={setAnimal}
                    showIcon
                    accountId={accountOwner?.accountId}
                    disabled={accountOwner === undefined}
                    clearInputField={animal != undefined ? false : true}
                  />
                </div>
              </div>

              {accountOwner && animal !== undefined && (
                <>
                  <br />
                  <SelectSpecimens
                    animalId={animal?.animalId}
                    accountId={accountOwner?.accountId}
                    onChange={e => setSelectedQuantities(e)}
                  />
                  <br />
                </>
              )}
            </>
          )}

          {transactionType.value === TransactionTypeEnum.Deposit && (
            <div className="form-row">
              <ValidatedInput
                useId="quantity"
                label={`*${LABEL_CONSTANTS.QUANTITY}:`}
                type="number"
                setValidatedState={setDepositQuantity}
                validators={[Validators.REQUIRED]}
              />
            </div>
          )}

          {transactionType.value === TransactionTypeEnum.Sell && (
            <>
              <h2>{LABEL_CONSTANTS.BUYER_INFO}</h2>
              <div className="form-row">
                <label>*{LABEL_CONSTANTS.SELL_TO_NON_EXISTENT_ACCOUNT}:</label>
                <div className="input-container">
                  <div className="radio-row">
                    <input
                      type="radio"
                      id="sell-to-nonexisting"
                      name="sell-transaction"
                      onChange={() => setSellToNonExistingUser(true)}
                    />
                    <label htmlFor="sell-to-nonexisting">{LABEL_CONSTANTS.YES}</label>
                    <input
                      type="radio"
                      id="sell-to-existing"
                      name="sell-transaction"
                      defaultChecked={true}
                      onChange={() => setSellToNonExistingUser(false)}
                    />
                    <label htmlFor="sell-to-existing">{LABEL_CONSTANTS.NO}</label>
                  </div>
                </div>
              </div>

              {sellToNonExistingUser ? (
                <>
                  <div className="form-row">
                    <ValidatedInput
                      useId="buyer-email"
                      label={`*${LABEL_CONSTANTS.BUYER_EMAIL}:`}
                      type="email-search"
                      onButtonClick={() => {
                        if (buyerEmail.valid) searchByEmail();
                      }}
                      setValidatedState={e => {
                        setBuyerEmail(e);
                        setOnAddInfoStep(false);
                        resetAdditionalInfo();
                      }}
                      validate={validate}
                      validators={[Validators.REQUIRED, Validators.EMAIL]}
                      validatedStateForAutoFill={buyerEmail}
                    />
                  </div>

                  {onAddInfoStep &&
                    (dropdownAccounts.length > 0 ? (
                      <div>
                        <div className="validated-input-message-error">
                          <AlertSVG />
                          {VALIDATION_ALERT_CONSTANTS.ACCOUNT_FOUND}
                        </div>
                        <div className="form-row">
                          <label>{LABEL_CONSTANTS.BUYER_FIRST_NAME}:</label>
                          <div className="input-container">
                            <label>{buyerFirstName.value}</label>
                          </div>
                        </div>
                        <div className="form-row">
                          <label>{LABEL_CONSTANTS.BUYER_LAST_NAME}:</label>
                          <div className="input-container">
                            <label>{buyerLastName.value}</label>
                          </div>
                        </div>
                        <div className="form-row">
                          <label>*{LABEL_CONSTANTS.ACCOUNT_NAME}:</label>
                          <div className="input-container">
                            <FilterDropdown
                              options={dropdownAccounts}
                              placeholder="Choose Account"
                              value={selectedBuyerAccount}
                              onChange={e => setSelectedBuyerAccount(e)}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="form-row">
                          <ValidatedInput
                            validatedStateForAutoFill={buyerFirstName}
                            useId="buyer-first-name"
                            label={`*${LABEL_CONSTANTS.BUYER_FIRST_NAME}:`}
                            type="text"
                            setValidatedState={setBuyerFirstName}
                            validators={[Validators.REQUIRED]}
                          />
                        </div>
                        <div className="form-row">
                          <ValidatedInput
                            validatedStateForAutoFill={buyerLastName}
                            useId="buyer-last-name"
                            label={`*${LABEL_CONSTANTS.BUYER_LAST_NAME}:`}
                            type="text"
                            setValidatedState={setBuyerLastName}
                            validators={[Validators.REQUIRED]}
                          />
                        </div>
                        <div className="form-row">
                          <ValidatedInput
                            validatedStateForAutoFill={buyerPhoneNumber}
                            useId="buyer-phone-number"
                            label={`*${LABEL_CONSTANTS.BUYER_PHONE_NUMBER}:`}
                            type="phone"
                            setValidatedState={setBuyerPhoneNumber}
                            validators={[Validators.REQUIRED, Validators.PHONE_LENGTH]}
                          />
                        </div>
                      </div>
                    ))}
                </>
              ) : (
                <div className="form-row">
                  <label>*{LABEL_CONSTANTS.BUYER_ACCOUNT}:</label>
                  <div className="input-container">
                    <AccountSearch onChange={setBuyerAccount} showIcon account={buyerAccount} />
                  </div>
                </div>
              )}
            </>
          )}

          {transactionType.value !== TransactionTypeEnum.Withdraw && (
            <div className="form-row expanded-text-area">
              <label>*{LABEL_CONSTANTS.NOTES}:</label>
              <textarea value={notes} placeholder="Notes" onChange={e => setNotes(e.target.value)} />
            </div>
          )}

          <div className="flex-right">
            {transactionType.value == TransactionTypeEnum.Withdraw ? (
              <>
                <button
                  className={'medium green button'}
                  type="button"
                  disabled={!checkValidity()}
                  onClick={() => handleAddMoreSpecimen()}>
                  {BUTTON_CONSTANTS.SAVE_AND_ADD_MORE}
                </button>
                <button
                  className={'small green button'}
                  type="button"
                  disabled={!isNextStepValid()}
                  onClick={() => handleNextStep()}>
                  {BUTTON_CONSTANTS.NEXT_STEP}
                </button>
              </>
            ) : (
              <button className={'small green button'} type="submit" disabled={!formIsValid()}>
                {BUTTON_CONSTANTS.SUBMIT}
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateClientTransaction;
