export const REACT_APP_API_URL: string = process.env.REACT_APP_API_URL ?? 'http://localhost:5000/api';
export const REACT_APP_EXPERIMENTAL_MODE: boolean = process.env.REACT_APP_EXPERIMENTAL_MODE === 'true';

export const SIGN_IN_API_URL = '/Auth';

export const AUTH_API_ENDPOINTS = {
  ACCOUNT: '/Auth/account',
  REFRESH: '/Auth/refresh',
  RESET: '/Auth/reset',
  SIGN_IN: '/Auth',
  TRANSIENT: '/Auth/transient',
  USER: '/Auth/user',
};

export const ACCOUNT_API_ENDPOINTS = {
  ACCOUNT_USER: '/users/',
  ACCOUNTS: '/accounts/',
  INVITE: '/invite',
  LOCK_UNLOCK_ACCOUNT: '/toggleaccountlockstatus',
  SIGNUP: '/Accounts/signup',
};

export const ANIMAL_OWNERS_API_ENDPOINTS = {
  GET: '/animalowners',
  GET_: '/animalowners/',
};

export const ANIMALS_API_ENDPOINTS = {
  ANIMALS: '/animals/',
  ATTACHMENTS: '/attachments',
  CREATE: '/animals',
  EDIT: '/animals',
  GET_ACCOUNT_: '/animals/accounts/',
  GET_COUNT: '/animals/count',
  GET: '/animals',
  INVENTORY_OWNERSHIP: '/inventoryownership',
  ODATA: '/odata',
  SET_GLOBAL_THRESHOLD: '/animals/setglobalthreshold',
};

export const BREEDS_API_ENDPOINTS = {
  CREATE: '/Breeds',
  GET: '/Breeds',
  PUT: '/Breeds/',
};

export const BULK_TRANSACTIONS_ENDPOINTS = {
  BULK_ORDER_: '/bulkorder/',
  BULK_ORDER_ODATA: '/bulkorder/odata',
  BULK_ORDER: '/bulkorder',
};

export const CATALOG_API_ENDPOINTS = {
  ADD_QUANTITY: '/catalog',
  CATALOG_: '/catalog/',
  GET_CATALOG_SELL_DATA: '/catalogspecimensellreport',
  GET_QUANTITY: '/catalog/quantity',
};

export const CUSTOM_DATA_POINTS_API_ENDPOINTS = {
  CREATE: '/specimencustomdatakeys',
  DELETE: '/specimencustomdatakeys/',
  GET: '/specimencustomdatakeys',
  PUT: '/specimencustomdatakeys/',
};

export const CONFIGURATION_API_ENDPOINTS = {
  DEACTIVATE_PROFILE: '/configuration/profiles/',
};

export const INVENTORY_API_ENDPOINTS = {
  ADJUST: '/inventory/adjust',
  BULK_ADJUST: '/inventory/adjust/bulk',
  BULK_WITHDRAW: 'inventory/withdraw/bulk',
  CHECK_IN: '/inventory/checkin',
  CHECK_OUT: '/inventory/checkout',
  CHECK_ZERO_VARIANCE_: 'inventory/check-zero-variance/',
  COLLECT: '/inventory/collect',
  DEPOSIT: '/inventory/deposit/',
  DISCARD: '/inventory/discard',
  GET_ANIMALS_: '/inventory/animals/',
  GET_SPECIMENS_: '/inventory/specimens/',
  GET_TYPES_: '/inventory/types/',
  GET: '/inventory',
  MOVE: '/inventory/move',
  RECONCILE_ADJUST: '/inventory/reconcile-adjust',
  RECONCILE: '/inventory/reconcile',
  REDEEM: '/inventory/redeem',
  SELL_TO_NEW_USER: '/inventory/sell-invite',
  SELL_USE: '/inventory/sell-use',
  SELL: '/inventory/sell',
  SPECIMEN_LOCATION_REPORT: 'inventory/withdraw/movementform',
  USE: '/inventory/use',
  WITHDRAW: 'inventory/withdraw',
};

export const INVENTORY_TRANSACTIONS_ENDPOINTS = {
  GET_: '/inventorytransactions/',
  GET: '/inventorytransactions',
  INVENTORY_TRANSACTIONS_: '/inventorytransactions/',
  INVENTORY_TRANSACTIONS_EXPORT: '/inventorytransactions/export',
  INVENTORY_TRANSACTIONS_ODATA: '/inventorytransactions/odata',
  INVENTORY_TRANSACTIONS: '/inventorytransactions',
  LINKED_STORAGE_MOVEMENT: '/linked-storage-movement',
  LINKED_TRANSACTIONS: '/linked',
};

export const INVENTORY_TRANSACTION_LOCATIONS_ENDPOINTS = {
  TRANSACTION_LOCATIONS: '/inventorytransactionlocations',
};

export const PAYMENT_TRANSACTION_API_ENDPOINTS = {
  CASH_PAYMENTS: '/payments/direct',
  GET_OUTSTANDING_BALANCE: '/subscription/accounts/outstandingamount',
  GET_PAYMENT_INFO: '/paymentcontext/accounts/',
  GET_QB_LATE_FEE: '/subscription/latefee/',
  INSTANT_PAYMENT: '/instantpayment',
  MAKE_PAYMENT: '/subscription/makepayment',
  PAYMENT_DETAILS: '/subscription/accounts/paymentdetails',
  PAYMENT_TRANSACTION_: '/paymenttransaction/',
  PAYMENT_TRANSACTION_ODATA: '/paymenttransaction/odata',
  PAYMENT_TRANSACTION: '/paymenttransaction',
  PAYMENTS: '/payments',
  SUBSCRIPTION_ACCOUNTS_: '/subscription/accounts/',
  USER_SUBSCRIBED: '/subscription/accounts/paymentdetails/issubscribed',
};

export const QUICKBOOKS_OPEN_BALANCE_DISCREPANCIES = {
  GET: '/quickbooksopenbalancediscrepancy',
  GET_ODATA: '/quickbooksopenbalancediscrepancy/odata',
};

export const QUICKBOOKS_SYNC = {
  RESYNC: '/quickbookssync/resync',
  SYNC_HISTORY: '/quickbookssynchistory',
};

export const ROLE_API_ENDPOINTS = {
  ROLES: '/Roles/',
};

export const SPECIES_API_ENDPOINTS = {
  CREATE: '/Species',
  GET: '/Species',
  PUT: '/Species/',
};

export const SPECIMENS_API_ENDPOINTS = {
  ACCOUNTS_: '/accounts/',
  ACCOUNTS: '/accounts',
  ANIMALS: '/animals/',
  CANISTERS: '/canisters/',
  CREATE: '/Specimens',
  GET_ACCOUNT_INVENTORY: '/specimens/inventory/accounts/',
  GET_ACCOUNTS: '/Specimens/accounts/',
  GET_INVENTORY: '/specimens/inventory',
  GET_USE_PENDING: '/specimens/canisters/use-pending/',
  GET_VARIANCE_: '/specimens/variance/',
  GET_VARIANCE_SUMMARY: '/specimens/inventorysummary/',
  GET_VARIANCES: '/specimens/reconcile',
  GET: '/Specimens',
  REMOVED_COUNT: '/removed-count',
  SPECIMENS: '/specimens/',
  UPDATE: '/specimens/',
  USE_PENDING: '/use-pending/',
};

export const SPECIMENS_LOCATIONS_API_ENDPOINTS = {
  GET: '/SpecimenLocations',
};

export const SPECIMEN_TYPES_API_ENDPOINTS = {
  GET: '/specimentypes',
};

export const STORAGE_PLANS_API_ENDPOINTS = {
  STORAGE_PLANS: '/StoragePlans',
};

export const STORAGE_ITEM_ENDPOINTS = {
  CANISTER_COUNT: '/count',
  CANISTERS_: '/StorageCanisters/',
  CANISTERS: '/StorageCanisters',
  SITES_: '/StorageSites/',
  SITES: '/StorageSites',
  TANK_INVENTORY_COUNT: '/inventory/count',
  TANKS_: '/StorageTanks/',
  TANKS: '/StorageTanks',
};

export const TRANSACTION_CART_API_ENDPOINTS = {
  ADD_TO_CART: '/transactioncartitem',
  DELETE_SPECIMEN: '/transactioncartitem/',
  DELETE_TRANSACTION_CART_ITEM: '/transactioncartitem',
  GET_CART_TRANSACTION: '/transactioncartitem/completionpage',
  GET_TRANSACTION_CART_ITEMS: '/transactioncartitem/cartpage',
};

export const USER_API_ENDPOINTS = {
  ADMIN_PASSWORD_RESET: '/users/password/adminreset',
  INVITATION: '/invitation',
  ODATA: '/Users/odata',
  PASSWORD_RESET: '/Users/password/reset',
  PROFILE_IMAGE: '/profile/image',
  SET_NEW_PASSWORD: '/Users/password',
  USER: '/Users',
  VERIFICATION: '/verification',
};
