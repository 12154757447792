import { AccountRoles, PaymentMethods } from '../types/enums';
import { StandardParamsToODataParams } from '../types/interfaces';
import { getAccountById } from '../api/accountApi';
import {
  getPaymentDetailsByAccountId,
  getPaymentTransactions,
  getPaymentTransactionsOdata,
  getSubscriptionStatus,
} from '../api/paymentGatewayApi';
import { showToast } from './toast.service';
import { toastMessages } from '../constants/errorMessages';

export const doesUserHaveSettingAccess = async (accountId: number, userId: number) => {
  try {
    const { data: accountResponse } = await getAccountById(accountId, { include: 'Users.Roles' });
    const accountUser = accountResponse.users.find(accountUser => accountUser.userId === userId);

    return accountUser?.roles.some(role => role.name === AccountRoles.AccountOwner || role.name === AccountRoles.AccountAdmin);
  } catch (error: any) {
    throw error;
  }
};

export const doesAccountHasPendingPaymentTransaction = async (accountId?: number) => {
  try {
    if (accountId) {
      const params = { filter: `accountId eq ${accountId}`, sort: '^billedDate' };
      const { data: paymentHistoryResponse } = await getPaymentTransactionsOdata(StandardParamsToODataParams(params));
      return paymentHistoryResponse?.findIndex(paymentHistory => paymentHistory?.status === 'Pending') >= 0;
    } else {
      const params = { sort: '^billedDate' };
      const { data: paymentHistoryResponse } = await getPaymentTransactions(params);
      return paymentHistoryResponse?.findIndex(paymentHistory => paymentHistory?.status === 'Pending') >= 0;
    }
  } catch (error: any) {
    throw error;
  }
};

export const getPaymentDetails = async (accountId: number, isUserAdmin?: boolean) => {
  try {
    const { data: isUserSubscribed } = await getSubscriptionStatus(accountId);
    if (isUserSubscribed) {
      const { data: paymentDetailsResponse } = await getPaymentDetailsByAccountId(accountId, isUserAdmin);
      const { paymentMethod, maskedCardNumber, expiry, accountType, name, city, country, region, address, postal } =
        paymentDetailsResponse || {};
      let accountData =
        paymentMethod === PaymentMethods.CARD
          ? {
              cardNumber: { value: maskedCardNumber, valid: maskedCardNumber?.length > 0 ? true : false },
              expiry: { value: expiry, valid: expiry?.length > 0 ? true : false },
              accountNumber: { value: '', valid: false },
              accountType: { value: '', name: '' },
            }
          : {
              cardNumber: { value: '', valid: false },
              expiry: { value: '', valid: false },
              accountNumber: { value: maskedCardNumber, valid: maskedCardNumber?.length > 0 ? true : false },
              accountType: { value: accountType, name: accountType },
            };

      return {
        paymentType: { value: paymentMethod, valid: paymentMethod ? true : false },
        name: { value: name, valid: name?.length > 0 ? true : false },
        address: { value: address, valid: address?.length > 0 ? true : false },
        city: { value: city, valid: city?.length > 0 ? true : false },
        region: { value: region, valid: region?.length > 0 ? true : false },
        postalCode: { value: postal, valid: postal?.length > 0 ? true : false },
        country: { value: country, valid: country?.length > 0 ? true : false },
        routingNumber: { value: '', valid: false },
        cvv: { value: '', valid: false },
        ...accountData,
      };
    }
  } catch {
    showToast.error(toastMessages.SOMETHING_WENT_WRONG);
  }
};
