import { useEffect, useMemo, useState, useCallback, MutableRefObject, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CustomerInfoHeader from './CustomerInfoHeader';
import { AccountInfo, BackButton, Loader, Modal, SortedTable } from '../../components';
import ActionMenu from '../../components/action-buttons/ActionButtons';
import DeactivateAccountModal from '../../components/account-settings/DeactivateAccountModal';
import InventoryGraphics from '../../components/account-info/InventoryGraphics';
import LockSVG from '../../components/svgs/Lock.svg';
import LockUnlockAccountModal from '../../components/lock-unlock-account-modal/LockUnlockAccountModal';
import MarkAsPaidModal from '../../components/mark-as-paid-modal/MarkAsPaidModal';
import NotificationBanner from '../../components/notificationBanner/NotificationBanner';
import PagedTable from '../../components/sorted-table/PagedTable';
import PaymentHistory from '../../components/account-settings/manage-billing-info/PaymentHistory';
import QuickView from '../../components/quick-view/QuickView';
import UnlockSVG from '../../components/svgs/Unlock.svg';
import UserCard from '../../components/account-card/UserCard';
import SpecimenViewEdit from '../animal-management/SpecimenViewEdit';
import { doesAccountHasPendingPaymentTransaction } from '../../services/payment.service';
import { showToast } from '../../services/toast.service';
import { deactivateAccountById, getAccountById, lockOrUnlockAccount } from '../../api/accountApi';
import { getAnimalOwnershipsByAccountId } from '../../api/animalOwnersApi';
import { getInventoryCount } from '../../api/inventoryApi';
import { getOutstandingBalance, getQuickBooksLateFee, getSubscriptionStatus } from '../../api/paymentGatewayApi';
import { getSpecimensByAccount } from '../../api/specimensApi';
import { getUserProfileImageById } from '../../api/userApi';
import {
  Account,
  AccountUserInfo,
  AnimalOwner,
  BillingAmount,
  Specimen,
  SpecimenInventoryWithAccount,
  TeamManagementUser,
  UserRole,
} from '../../types/interfaces';
import { AccountRole, getRoleValue } from '../../types/enums';
import { toastMessages } from '../../constants/errorMessages';
import { ROUTE_PATHS } from '../../constants/routePaths';
import {
  ACCOUNT_CONSTANTS,
  ANIMAL_CONSTANTS,
  BUTTON_CONSTANTS,
  LABEL_CONSTANTS,
  PAYMENT_CONSTANTS,
  TABLE_HEADER_CONSTANTS,
} from '../../constants/common';
import './customerInfo.scss';

const CustomerInfoView = (): JSX.Element => {
  const { accountId } = useParams();
  const navigate = useNavigate();

  const [billingAmount, setBillingAmount] = useState<BillingAmount>();
  const [customerAccount, setCustomerAccount] = useState<Account>();
  const [inventoryInfo, setInventoryInfo] = useState<SpecimenInventoryWithAccount[]>([]);
  const [isOpenDeactivateAccountModal, setIsOpenDeactivateAccountModal] = useState<boolean>(false);
  const [isOpenLockUnlockAccountModal, setIsOpenLockUnlockAccountModal] = useState<boolean>(false);
  const [isOpenMarkAsPaidModal, setIsOpenMarkAsPaidModal] = useState<boolean>(false);
  const [isQuickViewOpen, setIsQuickViewOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingPage, setLoadingPage] = useState<boolean>(false);
  const [outstandingBalance, setOutstandingBalance] = useState<number>(0);
  const [pagingOffset, setPagingOffset] = useState<number>(0);
  const [penalty, setPenalty] = useState<number>(0);
  const [pendingACHPayment, setPendingACHPayment] = useState<boolean>(true);
  const [refreshPage, setRefreshPage] = useState<number>(0);
  const [refreshPaymentHistory, setRefreshPaymentHistory] = useState<number>(0);
  const [searchAnimal, setSearchAnimal] = useState<string>('');
  const [specimen, setSpecimen] = useState<Specimen>();
  const [specimenInfoModal, setSpecimenInfoModal] = useState<JSX.Element>();
  const [totalAnimalOwnerships, setTotalAnimalOwnerships] = useState<AnimalOwner[]>([]);
  const [totalInventory, setTotalInventory] = useState<number>(-1);
  const [userProfileImages, setUserProfileImages] = useState<{ [key: number]: Blob }>({});
  const [users, setUsers] = useState<AccountUserInfo[]>([]);

  const hasAllResults: MutableRefObject<boolean> = useRef<boolean>(false);
  const pagingSize = 6;

  const sortedUsers = useMemo(() => {
    const sortUsers = (userArray: TeamManagementUser[]) => {
      const index = userArray?.findIndex((u: TeamManagementUser) => u.roles.includes(AccountRole.AccountOwner));
      const accountOwner = userArray?.splice(index, 1);
      userArray?.unshift(...accountOwner);
      return userArray;
    };

    const flattenRoles = (roles: UserRole[]) => {
      return roles?.map(role => getRoleValue(role?.name)).join(', ');
    };

    return sortUsers(
      users
        .map(u => {
          return {
            firstName: u?.user?.firstName,
            fullName: u?.user?.firstName + ' ' + u?.user?.lastName,
            lastName: u?.user?.lastName,
            profileImageId: u?.user?.profileImageId,
            roles: flattenRoles(u?.roles),
            userId: u?.userId,
            username: u?.user?.username,
            verified: u?.user?.verified,
          };
        })
        .sort((a: TeamManagementUser, b: TeamManagementUser) => {
          return a?.firstName?.toUpperCase() > b?.firstName?.toUpperCase() ? 1 : -1;
        }),
    );
  }, [users]);

  const getInventoryInfo = useCallback(async () => {
    if (hasAllResults?.current) {
      return;
    }

    const params = {
      filter: accountId,
      include: 'Animal, SpecimenCustomDataValue',
      limit: pagingSize,
      offset: pagingOffset,
      searchString: searchAnimal?.length > 0 ? searchAnimal : undefined,
      sort: 'Animal.Name',
    };
    try {
      setLoading(true);
      const specimenResponse = await getSpecimensByAccount(+accountId!, params);
      const specimensData = specimenResponse?.data;
      const newInventoryData = pagingOffset > 0 ? [...inventoryInfo, ...specimensData] : specimensData;
      setInventoryInfo(newInventoryData);
      hasAllResults.current = specimensData?.length < pagingSize;
      setLoading(false);
    } catch {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    }
  }, [accountId, searchAnimal, pagingOffset]);

  useEffect(() => {
    getTeamMembers();
    fetchDueBillingAmount();
  }, [accountId, refreshPaymentHistory, refreshPage]);

  useEffect(() => {
    hasAllResults.current = false;
    setInventoryInfo([]);

    if (pagingOffset === 0) {
      getInventoryInfo();
    } else {
      setPagingOffset(0);
    }
  }, [accountId, searchAnimal]);

  useEffect(() => {
    getInventoryInfo();
  }, [pagingOffset]);

  useEffect(() => {
    const loadUserImages = async () => {
      const results = await Promise.all(
        sortedUsers?.map(user => getUserProfileImageById(user?.userId, user?.profileImageId).catch(() => new Blob())),
      );

      const userImages: { [key: number]: Blob } = {};
      sortedUsers?.forEach((user, index) => {
        userImages[user?.userId] = results[index];
      });

      setUserProfileImages(userImages);
    };

    loadUserImages();
  }, [sortedUsers]);

  useEffect(() => {
    if (accountId) {
      doesAccountHasPendingPaymentTransaction(Number(accountId))
        .then(response => {
          setPendingACHPayment(response);
        })
        .catch(error => {
          showToast.error(toastMessages.SOMETHING_WENT_WRONG);
        });
    }
  }, [accountId]);

  const getTeamMembers = async () => {
    try {
      const { data: accountResponse } = await getAccountById(+accountId!, { include: 'Users.Roles' });
      setCustomerAccount(accountResponse);
      setOutstandingBalance(accountResponse?.outstandingBalanceCents ?? 0);
      setPenalty(accountResponse?.penaltyCents ?? 0);
      setUsers(accountResponse?.users);
    } catch {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    }
  };

  const fetchDueBillingAmount = async () => {
    try {
      const { data: billingAmountResponse } = await getOutstandingBalance(+accountId!);
      const { data: isUserSubscribed } = await getSubscriptionStatus(+accountId!);
      if (isUserSubscribed) {
        const { data: qbLateFeeAmount } = await getQuickBooksLateFee(+accountId!);
        setBillingAmount({ ...billingAmountResponse, quickbooksLateFeeCents: qbLateFeeAmount });
      } else {
        setBillingAmount(billingAmountResponse);
      }
    } catch {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    }
  };

  const lotInfoSpecimen = (item: Specimen) => {
    setSpecimenInfoModal(<SpecimenViewEdit itemId={item.specimenId} />);
  };

  const handleQuickViewOpen = (item: Specimen) => {
    setSpecimen(item);
    setIsQuickViewOpen(true);
  };

  const canUserDeactivateAccount = () => {
    return totalInventory === 0 && totalAnimalOwnerships?.length === 0 && outstandingBalance <= 0 && !customerAccount?.locked;
  };

  const deactivateAccount = async () => {
    setIsOpenDeactivateAccountModal(false);
    if (accountId) {
      try {
        await deactivateAccountById(Number(accountId));
        navigate(ROUTE_PATHS.APP_CUSTOMER_INFO);
        showToast.success(toastMessages.DEACTIVATE_ACCOUNT_SUCCESS);
      } catch (error: any) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      }
    }
  };

  const handleLockStatus = async () => {
    setLoadingPage(true);
    setIsOpenLockUnlockAccountModal(false);
    if (accountId) {
      try {
        await lockOrUnlockAccount(+accountId!);
        setRefreshPage(r => r + 1);
        showToast.success(toastMessages.LOCK_STATUS_SUCCESS);
      } catch (error: any) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      }
    }
    setLoadingPage(false);
  };

  useEffect(() => {
    const handleDeactivateAccount = async () => {
      try {
        if (accountId) {
          Promise.all([getTotalInventory(), getTotalAnimalOwnershipsForAccount()]);
        }
      } catch (error) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      }
    };

    handleDeactivateAccount();
  }, [accountId]);

  const getTotalInventory = async () => {
    try {
      const { data: total } = await getInventoryCount({ accountId: +accountId! });
      setTotalInventory(total);
    } catch (error: any) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    }
  };

  const getTotalAnimalOwnershipsForAccount = async () => {
    try {
      const { data: animalOwnership } = await getAnimalOwnershipsByAccountId({ filter: 'accountId eq ' + accountId });
      setTotalAnimalOwnerships(animalOwnership);
    } catch (error: any) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    }
  };

  const loadMore = () => {
    setPagingOffset(pagingOffset + pagingSize);
  };

  return (
    <div className="customer-info">
      <CustomerInfoHeader customerAccount={customerAccount} />
      {loadingPage && <Loader pageLoader loaderSize="small" />}

      <div className="deactivate-section-content">
        <div className="section-content">
          <button
            className={canUserDeactivateAccount() ? 'deactivate-button button blue' : 'deactivate-button disabled button blue'}
            onClick={() => setIsOpenDeactivateAccountModal(true)}
            disabled={!canUserDeactivateAccount()}>
            {ACCOUNT_CONSTANTS.DEACTIVATE_ACCOUNT}
          </button>
          {customerAccount && (
            <button
              className="lock-unlock-button"
              type="button"
              title={`${customerAccount.locked ? LABEL_CONSTANTS.ACCOUNT_LOCKED : LABEL_CONSTANTS.ACCOUNT_UNLOCKED}`}
              onClick={() => setIsOpenLockUnlockAccountModal(true)}>
              {customerAccount.locked ? <LockSVG /> : <UnlockSVG />}
            </button>
          )}
        </div>

        {(outstandingBalance > 0 || penalty > 0 || pendingACHPayment) && (
          <div className="payment-section">
            <NotificationBanner
              children={
                <div className="account-due-payment">
                  {pendingACHPayment ? (
                    <p>{ACCOUNT_CONSTANTS.PENDING_ACH_SUBHEADER}</p>
                  ) : (
                    <p>{ACCOUNT_CONSTANTS.DUE_PAYMENT_SUBHEADER}</p>
                  )}
                </div>
              }
            />
            {!pendingACHPayment && (
              <div className="button-section">
                <button className={'paid-button button blue'} onClick={() => setIsOpenMarkAsPaidModal(true)}>
                  {ACCOUNT_CONSTANTS.MARK_PAID}
                </button>
              </div>
            )}
          </div>
        )}
      </div>

      <MarkAsPaidModal
        isOpen={isOpenMarkAsPaidModal}
        setIsOpen={setIsOpenMarkAsPaidModal}
        onConfirm={() => setRefreshPaymentHistory(r => r + 1)}
        customerAccount={customerAccount}
        billingAmount={billingAmount}
      />

      <DeactivateAccountModal
        isOpen={isOpenDeactivateAccountModal}
        onClose={() => setIsOpenDeactivateAccountModal(false)}
        onConfirm={() => deactivateAccount()}
      />

      <LockUnlockAccountModal
        isOpen={isOpenLockUnlockAccountModal}
        accountLocked={customerAccount?.locked ?? false}
        onClose={() => setIsOpenLockUnlockAccountModal(false)}
        onConfirm={() => handleLockStatus()}
      />

      <div className="dashboard">
        {accountId !== undefined && (
          <>
            <div className="card max-width">
              <AccountInfo accountId={+accountId} showAccountOwner />
            </div>

            <div className="card max-width">
              <InventoryGraphics accountId={+accountId} />
            </div>

            <div className="full team-members">
              <h2>{LABEL_CONSTANTS.TEAM_MEMBERS}</h2>
              <SortedTable
                headers={[{ displayName: TABLE_HEADER_CONSTANTS.NAME }, { displayName: TABLE_HEADER_CONSTANTS.ROLE }]}
                data={sortedUsers?.map(user => {
                  return [
                    { content: <UserCard user={user} profileImage={userProfileImages[user?.userId]} /> },
                    { content: <div className="user-role">{user?.roles}</div> },
                  ];
                })}
              />
            </div>
            <div className="full desk-inventory-table">
              <div className="account-inventory-search">
                <h2>{LABEL_CONSTANTS.INVENTORY}</h2>
                <div className="search-input-wrapper">
                  <input
                    type="text"
                    placeholder={LABEL_CONSTANTS.SEARCH_ANIMAL}
                    value={searchAnimal}
                    onChange={e => setSearchAnimal(e.target.value)}
                  />
                </div>
              </div>
              <PagedTable
                headers={[
                  { displayName: TABLE_HEADER_CONSTANTS.ANIMAL_NAME, sortValue: 'Animal.Name' },
                  { displayName: TABLE_HEADER_CONSTANTS.TYPE, sortValue: 'SpecimenType.name' },
                  { displayName: TABLE_HEADER_CONSTANTS.LOT_DATE_NO, sortValue: 'FreezeDate' },
                  { displayName: TABLE_HEADER_CONSTANTS.QUALITY_PERCENTAGE, sortValue: 'QualityPercentage' },
                  { displayName: TABLE_HEADER_CONSTANTS.QUANTITY_OWNED, sortValue: 'QuantityOwned' },
                  { displayName: TABLE_HEADER_CONSTANTS.ACTION_ITEM },
                ]}
                include="Animal,SpecimenCustomDataValue"
                filter={accountId}
                searchString={searchAnimal?.length > 1 ? searchAnimal : undefined}
                getData={params => {
                  return getSpecimensByAccount(+accountId, {
                    ...params,
                    searchString: searchAnimal?.length > 1 ? searchAnimal : undefined,
                  });
                }}
                sortBy="Animal.Name"
                buildRow={item => {
                  return [
                    item?.animal?.name,
                    item?.specimenType?.name,
                    `${new Date(item?.freezeDate).toLocaleDateString()} - ${item?.specimenId}`,
                    item?.qualityPercentage != null ? `${item?.qualityPercentage}%` : '',
                    `${item?.quantityOwned} Units`,
                    <ActionMenu
                      actionButtons={[
                        { name: BUTTON_CONSTANTS.VIEW_DETAILS, action: () => lotInfoSpecimen(item) },
                        { name: BUTTON_CONSTANTS.QUICK_VIEW, action: () => handleQuickViewOpen(item) },
                      ]}
                    />,
                  ];
                }}
                pageSize={6}
                height={300}
                noResultsMessage={LABEL_CONSTANTS.NO_INVENTORY}
              />
            </div>

            {/** Mobile View */}
            <div className="full xs-inventory-data">
              <div className="account-inventory-search">
                <h2>{LABEL_CONSTANTS.INVENTORY}</h2>
                <div className="search-input-wrapper">
                  <input
                    type="text"
                    placeholder={LABEL_CONSTANTS.SEARCH_ANIMAL}
                    value={searchAnimal}
                    onChange={e => setSearchAnimal(e.target.value)}
                  />
                </div>
              </div>
              {!loading && inventoryInfo?.length === 0 ? (
                <div className="result-item">{LABEL_CONSTANTS.NO_INVENTORY}</div>
              ) : (
                inventoryInfo &&
                inventoryInfo.map((inventory, index) => {
                  return (
                    <div className="card max-width" key={'inventory-' + inventory?.specimenId + index}>
                      <div className="inventory-item-card">
                        <div className="inventory-card-content">
                          <div className="inventory-info">
                            <div className="inventory-content">
                              <label>{`${TABLE_HEADER_CONSTANTS.ANIMAL_NAME} : `}</label>
                              <label>{` ${inventory?.animal?.name ?? '-'}`}</label>
                            </div>
                            <div className="inventory-content">
                              <label>{`${TABLE_HEADER_CONSTANTS.FREEZE_RECEIVE_DATE} : `}</label>
                              <label>{`${new Date(inventory?.freezeDate).toLocaleDateString()}`}</label>
                            </div>
                            <div className="inventory-content">
                              <label>{`${TABLE_HEADER_CONSTANTS.TYPE} : `}</label>
                              <label>{` ${inventory?.specimenType?.name ?? '-'}`}</label>
                            </div>
                          </div>
                          <div className="inventory-info">
                            <div className="inventory-content">
                              <label>{`${TABLE_HEADER_CONSTANTS.QUALITY_PERCENTAGE} : `}</label>
                              <label>{`${inventory?.qualityPercentage ?? '-'}`}</label>
                            </div>
                            <div className="inventory-content">
                              <label>{`${TABLE_HEADER_CONSTANTS.QUANTITY_OWNED} : `}</label>
                              <label>{`${inventory?.quantityOwned ?? '-'}`}</label>
                            </div>
                          </div>
                        </div>
                        <div className="inventory-action-button">
                          <ActionMenu
                            actionButtons={[
                              { name: BUTTON_CONSTANTS.VIEW_DETAILS, action: () => lotInfoSpecimen(inventory) },
                              { name: BUTTON_CONSTANTS.QUICK_VIEW, action: () => handleQuickViewOpen(inventory) },
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
              {loading && <Loader addedSpace loaderSize="small" />}
              {!hasAllResults?.current && inventoryInfo?.length > 0 && (
                <button className="button blue medium load-more-button" onClick={() => loadMore()}>
                  {BUTTON_CONSTANTS.LOAD_MORE}
                </button>
              )}
            </div>

            <div className="full animal-ownership">
              <h2>{ANIMAL_CONSTANTS.ANIMAL_OWNERSHIP}</h2>
              <PagedTable
                headers={[
                  TABLE_HEADER_CONSTANTS.ANIMAL_CODE,
                  TABLE_HEADER_CONSTANTS.ANIMAL_NAME,
                  TABLE_HEADER_CONSTANTS.OWNERSHIP_PERCENTAGE,
                ].map(header => {
                  return { displayName: header };
                })}
                filter={`accountId eq ${accountId}`}
                include="Animal"
                getData={params => {
                  return getAnimalOwnershipsByAccountId(params);
                }}
                buildRow={(item: AnimalOwner) => {
                  return [item?.animal?.code, item?.animal?.name, item?.ownershipPercentage];
                }}
                height={264}
                noResultsMessage={LABEL_CONSTANTS.NO_ANIMAL_OWNERSHIP}
              />
            </div>
            <div className="full customer-payment-history">
              <h2>{PAYMENT_CONSTANTS.PAYMENT_HISTORY}</h2>
              <PaymentHistory accountId={Number(accountId)} refresh={refreshPaymentHistory} />
            </div>
          </>
        )}
      </div>

      {specimen && (
        <QuickView
          specimen={specimen}
          isOpen={isQuickViewOpen}
          onClose={() => setIsQuickViewOpen(false)}
          setIsOpen={setIsQuickViewOpen}
        />
      )}

      <Modal isOpen={specimenInfoModal !== undefined} onClose={() => setSpecimenInfoModal(undefined)} width={700}>
        <div className="specimen-info-modal">
          <BackButton onClick={() => setSpecimenInfoModal(undefined)} />
          {specimenInfoModal}
        </div>
      </Modal>
    </div>
  );
};

export default CustomerInfoView;
