import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../redux/hooks';
import PaymentDetails from '../../../components/payment-method-modal/PaymentDetails';
import { initialPaymentState } from '../../../components/payment-method-modal/AddPaymentMethodModal';
import BillingAddress from '../../../components/payment-method-modal/BillingAddress';
import { AlertSVG } from '../../../components/svgs';
import { Loader } from '../../../components';
import { RequireAlert } from '../../inventory-management/InventoryManagementCollectDeposit';
import { AccountRoles, AppRoles, PaymentActions, PaymentMethods, ShippingPaymentMethods } from '../../../types/enums';
import { PaymentMethod } from '../../../types/interfaces/paymentMethod.interface';
import { getSubscriptionStatus } from '../../../api/paymentGatewayApi';
import { showToast } from '../../../services/toast.service';
import { getPaymentDetails } from '../../../services/payment.service';
import { PermissionService } from '../../../services/permission.service';
import { toastMessages } from '../../../constants/errorMessages';
import { BUTTON_CONSTANTS, LABEL_CONSTANTS, NOTIFICATION_CONSTANTS, PAYMENT_CONSTANTS } from '../../../constants/common';
import './paymentInformation.scss';

interface PaymentInformationProps {
  setIsPaymentPage: (isPreview: boolean) => void;
}

const PaymentInformation: FC<PaymentInformationProps> = ({ setIsPaymentPage }) => {
  const { accountId } = useParams();

  const accountRoles = useAppSelector(state => state.auth.accountContext.roles) as AccountRoles[];
  const appRoles = useAppSelector(state => state.auth.appContext.roles) as AppRoles[];
  const enabled = useAppSelector(state => state.user.enabled);
  const verified = useAppSelector(state => state.user.verified);

  const permissionService = new PermissionService(accountRoles, appRoles, enabled, verified);
  const initialFormState = { value: '', valid: false };

  const [paymentInfo, setPaymentInfo] = useState<PaymentMethod>({ ...initialPaymentState, paymentType: initialFormState });
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>({ ...initialPaymentState, paymentType: initialFormState });
  const [shippingPaymentMethod, setShippingPaymentMethod] = useState<string>('');
  const [validate, setValidate] = useState<boolean>(false);
  const [placingOrder, setPlacingOrder] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [notes, setNotes] = useState<string>('');

  useEffect(() => {
    const fetchPaymentDetails = async () => {
      setIsLoading(true);
      try {
        const { data: paymentStatus } = await getSubscriptionStatus(Number(accountId));
        const isUserAdmin = permissionService.userHasRSGAdminPermission();

        if (accountId && paymentStatus) {
          const paymentData = await getPaymentDetails(Number(accountId), isUserAdmin);
          paymentData && setPaymentInfo(paymentData);
        }
      } catch (error) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPaymentDetails();
  }, [accountId]);

  useEffect(() => {
    paymentInfo != undefined && setPaymentMethod(paymentInfo);
    if (paymentInfo?.paymentType?.value === PaymentMethods.CARD) {
      setShippingPaymentMethod(ShippingPaymentMethods.CARD_ON_FILE);
    } else {
      setShippingPaymentMethod(ShippingPaymentMethods.NEW_CARD);
      setPaymentMethod(initialPaymentState);
    }
  }, [paymentInfo]);

  useEffect(() => {
    if (shippingPaymentMethod === ShippingPaymentMethods.CARD_ON_FILE && paymentInfo) {
      setPaymentMethod(paymentInfo);
    } else if (shippingPaymentMethod === ShippingPaymentMethods.NEW_CARD) {
      setPaymentMethod(initialPaymentState);
    }
  }, [shippingPaymentMethod]);

  const checkValidity = () => {
    const { paymentType, name, address, city, region, postalCode, country, cardNumber, expiry, cvv } = paymentMethod || {};

    const isCardPayment = paymentMethod?.paymentType?.value === PaymentMethods.CARD;
    const isCardOnFile = shippingPaymentMethod === ShippingPaymentMethods.CARD_ON_FILE;
    const isMarkAsPaid = shippingPaymentMethod === ShippingPaymentMethods.MARK_AS_PAID;
    const isBillingAddressValid = address?.valid && city?.valid && region?.valid && postalCode?.valid && country?.valid;
    const isCardValid = cardNumber?.valid && expiry?.valid && (isCardOnFile || cvv?.valid);
    const hasNotes = notes.trim().length > 0;

    return (
      (paymentType?.valid && name?.valid && isBillingAddressValid && isCardPayment && isCardValid) || (isMarkAsPaid && hasNotes)
    );
  };

  const handleCancel = () => {
    setIsPaymentPage(false);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!placingOrder) return;
    setPlacingOrder(false);
    setValidate(true);

    if (accountId) {
      try {
      } catch (error: any) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
        setValidate(false);
      } finally {
        setPlacingOrder(true);
      }
    }
  };

  return (
    <div className="shipping-payment-information add-payment-method">
      <div className="payment-info-header">
        <h2>{PAYMENT_CONSTANTS.PAYMENT_INFORMATION}</h2>
      </div>
      <div className="payment-info-subheader">
        <label>{NOTIFICATION_CONSTANTS.SHIPPING_PAYMENT_INFO_SUBHEADER}</label>
      </div>
      {paymentInfo?.paymentType?.value === PaymentMethods.ACH && (
        <div className="payment-info-subheader ach-warning">
          <AlertSVG />
          <label>{NOTIFICATION_CONSTANTS.ACH_PAYMENT_NOT_ALLOWED}</label>
        </div>
      )}
      {isLoading ? (
        <Loader loaderSize="small" addedSpace />
      ) : (
        <form action="submit" onSubmit={(event: React.FormEvent) => handleSubmit(event)}>
          <div className="payment-method-selection">
            {paymentInfo?.paymentType?.value != PaymentMethods.ACH && <label>{LABEL_CONSTANTS.CHOOSE_PAYMENT_MODE}</label>}
            <>
              <div className="radio-row">
                {paymentInfo?.paymentType?.value === PaymentMethods.CARD && (
                  <>
                    <input
                      type="radio"
                      id="payment-method-card-on-file"
                      name="payment-method-card-on-file"
                      value={shippingPaymentMethod}
                      onChange={() => setShippingPaymentMethod(ShippingPaymentMethods.CARD_ON_FILE)}
                      checked={shippingPaymentMethod === ShippingPaymentMethods.CARD_ON_FILE}
                    />
                    <label htmlFor="payment-method-card-on-file">{LABEL_CONSTANTS.USE_CARD_ON_FILE}</label>
                  </>
                )}
                {paymentInfo?.paymentType?.value === PaymentMethods.ACH && !permissionService.userHasRSGAdminPermission() ? (
                  <></>
                ) : (
                  <>
                    <input
                      type="radio"
                      id="payment-method-new-card"
                      name="payment-method-new-card"
                      value={shippingPaymentMethod}
                      onChange={() => setShippingPaymentMethod(ShippingPaymentMethods.NEW_CARD)}
                      checked={shippingPaymentMethod === ShippingPaymentMethods.NEW_CARD}
                    />
                    <label htmlFor="payment-method-new-card">{LABEL_CONSTANTS.ENTER_NEW_CARD_DETAILS}</label>
                  </>
                )}
                {permissionService.userHasRSGAdminPermission() && (
                  <>
                    <input
                      type="radio"
                      id="payment-method-mark-as-paid"
                      name="payment-method-mark-as-paid"
                      value={shippingPaymentMethod}
                      onChange={() => setShippingPaymentMethod(ShippingPaymentMethods.MARK_AS_PAID)}
                      checked={shippingPaymentMethod === ShippingPaymentMethods.MARK_AS_PAID}
                    />
                    <label htmlFor="payment-method-mark-as-paid">{LABEL_CONSTANTS.MARK_AS_PAID}</label>
                  </>
                )}
              </div>
            </>
          </div>
          {shippingPaymentMethod === ShippingPaymentMethods.MARK_AS_PAID ? (
            <div className="mark-as-paid">
              <label className="mark-as-paid-question">{PAYMENT_CONSTANTS.CONFIRM_SKIP_PAYMENT_MESSAGE}</label>
              <div className="mark-as-paid-info">
                <p>By selecting this option:</p>
                <ul>
                  <li>{PAYMENT_CONSTANTS.SKIP_PAYMENT_NO_CARD_REQUIRED}</li>
                  <li>{PAYMENT_CONSTANTS.SKIP_PAYMENT_ASSUMED_COMPLETED}</li>
                  <li>{PAYMENT_CONSTANTS.SKIP_PAYMENT_NO_CHARGES_PROCESSED}</li>
                  <li>{PAYMENT_CONSTANTS.NO_INVOICE_PAYMENT_RECORD_IN_QB}</li>
                </ul>
              </div>
              <div className="form-row expanded-text-area">
                <label>
                  <div>
                    *{LABEL_CONSTANTS.NOTES}:
                    <RequireAlert isActive={validate && !notes} />
                  </div>
                </label>
                <textarea value={notes} placeholder="Notes" onChange={e => setNotes(e.target.value)} />
              </div>
            </div>
          ) : (
            <>
              <PaymentDetails
                paymentMethod={paymentMethod}
                validate={validate}
                buttonAction={PaymentActions.SHIPPING_PAYMENT}
                paymentMethodType={shippingPaymentMethod}
                setPaymentMethod={setPaymentMethod}
              />
              <BillingAddress
                paymentMethod={paymentMethod}
                validate={validate}
                buttonAction={PaymentActions.SHIPPING_PAYMENT}
                paymentMethodType={shippingPaymentMethod}
                setPaymentMethod={setPaymentMethod}
              />
              <div className="payment-transaction-note">
                {paymentMethod?.paymentType?.value === PaymentMethods.CARD && (
                  <div className="transaction-alert">
                    <AlertSVG />
                    <p>{NOTIFICATION_CONSTANTS.CC_PROCESSING_FEE_MSG}</p>
                  </div>
                )}
              </div>
            </>
          )}
          <div className="buttons">
            <button type="button" className="button red inverted medium" onClick={handleCancel}>
              {BUTTON_CONSTANTS.CANCEL}
            </button>
            <button type="submit" className={`button green medium ${checkValidity() ? '' : 'disabled'}`}>
              {BUTTON_CONSTANTS.PLACE_ORDER}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};
export default PaymentInformation;
