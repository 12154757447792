import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Modal from '../modal/Modal';
import Loader from '../loader/Loader';
import { Account, BillingAmount } from '../../types/interfaces';
import { centsToDollars } from '../../services/account.service';
import { showToast } from '../../services/toast.service';
import { clearOutstandingBalanceByCash } from '../../api/paymentGatewayApi';
import { toastMessages } from '../../constants/errorMessages';
import { ACCOUNT_CONSTANTS, BUTTON_CONSTANTS, LABEL_CONSTANTS } from '../../constants/common';
import './markAsPaidModal.scss';

export type MarkAsPaidModalProps = {
  isOpen: boolean;
  width?: number;
  setIsOpen: (e: boolean) => void;
  onConfirm: () => void;
  customerAccount?: Account;
  billingAmount?: BillingAmount;
};

const MarkAsPaidModal = ({ isOpen, width = 600, setIsOpen, customerAccount, billingAmount, onConfirm }: MarkAsPaidModalProps) => {
  const { accountId } = useParams();

  const [notes, setNotes] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const handleClose = () => {
    setNotes('');
    setIsOpen(false);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);

    if (accountId) {
      await clearOutstandingBalanceByCash(Number(accountId), notes)
        .then(() => {
          showToast.success(toastMessages.MARK_AS_PAID_SUCCESS);
          onConfirm();
        })
        .catch(() => {
          showToast.error(toastMessages.SOMETHING_WENT_WRONG);
        })
        .finally(() => {
          setIsOpen(false);
        });
    }

    setLoading(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} width={width} ignoreBackdrop>
      <div className="mark-as-paid-modal">
        <div className="header">
          <h4>{ACCOUNT_CONSTANTS.MARK_PAID}</h4>
        </div>
        <div className="body">
          <div className="body-content">
            <div className="row">
              <div className="l-column">{ACCOUNT_CONSTANTS.ACCOUNT_FARM_NAME}: </div>
              <div className="r-column">
                <b>{customerAccount?.name}</b>
              </div>
            </div>
            <div className="row">
              <div className="l-column">{ACCOUNT_CONSTANTS.DUE_AMOUNT} : </div>
              <div className="r-column">
                {billingAmount?.penaltyCents && billingAmount?.penaltyCents > 0 ? (
                  <>
                    <b>${centsToDollars(billingAmount?.totalFineAmountCents)}</b> (
                    <b>${centsToDollars(billingAmount?.outstandingBalanceCents)}</b> Outstanding Balance +{' '}
                    <b>${centsToDollars(billingAmount?.lateFeeCents)}</b> Late Fee +{' '}
                    <b>${centsToDollars(billingAmount?.financeChargeCents)}</b> Finance Charge.)
                  </>
                ) : (
                  <b>
                    ${centsToDollars((billingAmount?.totalFineAmountCents ?? 0) + (billingAmount?.quickbooksLateFeeCents ?? 0))}
                  </b>
                )}
              </div>
            </div>
            {loading && <Loader loaderSize={'medium'} />}
            <div className="row">
              <div className="l-column">{`*${LABEL_CONSTANTS.NOTES} :`}</div>
              <div className="r-column">
                <textarea
                  value={notes}
                  placeholder="Notes"
                  id="inventory-notes-textarea"
                  onChange={e => setNotes(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <button className="button blue inverted small" onClick={handleClose} disabled={loading}>
            {BUTTON_CONSTANTS.CANCEL}
          </button>
          <button className="button green small" onClick={handleSubmit} disabled={!notes?.trim() || loading}>
            {BUTTON_CONSTANTS.YES}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default MarkAsPaidModal;
