import { Dispatch, FC, SetStateAction } from 'react';
import FilterDropdown, { FilterOption } from '../custom-input/FilterDropdown';
import ValidatedInput, { ValidatedState } from '../custom-input/ValidatedInput';
import { PaymentMethod } from '../../types/interfaces/paymentMethod.interface';
import {
  Validators,
  PaymentMethods,
  PaymentActions,
  AccountTypesEnum,
  PayNowPaymentMethods,
  ShippingPaymentMethods,
} from '../../types/enums';
import { ACCOUNT_CONSTANTS, PAYMENT_CONSTANTS } from '../../constants/common';
import './addPaymentMethodModal.scss';

type PaymentDetailsProps = {
  validate: boolean;
  buttonAction: string;
  paymentMethodType: string;
  paymentMethod: PaymentMethod;
  setPaymentMethod: (paymentMethod: PaymentMethod) => void;
  confirmAccountNumber?: ValidatedState;
  setConfirmAccountNumber?: Dispatch<SetStateAction<ValidatedState>>;
};

const PaymentDetails: FC<PaymentDetailsProps> = ({
  paymentMethod,
  validate,
  buttonAction,
  paymentMethodType,
  setPaymentMethod,
  confirmAccountNumber,
  setConfirmAccountNumber,
}) => {
  const isExistingMethodSelected = () => {
    return (
      (buttonAction === PaymentActions.PAY_NOW_METHOD && paymentMethodType === PayNowPaymentMethods.EXISTING) ||
      (buttonAction === PaymentActions.SHIPPING_PAYMENT && paymentMethodType === ShippingPaymentMethods.CARD_ON_FILE)
    );
  };

  const formatExpiryDate = (expiryDate: ValidatedState) => {
    const expDate = expiryDate;

    expiryDate.value =
      expDate?.value?.replace(/\//g, '').substring(0, 2) +
      (expDate?.value?.length > 2 ? '/' : '') +
      expDate?.value?.replace(/\//g, '').substring(2, 4);

    return expiryDate;
  };

  return (
    <div className="payment-details">
      <h3>
        {paymentMethod?.paymentType?.value === PaymentMethods.CARD
          ? PAYMENT_CONSTANTS.CARD_DETAILS
          : PAYMENT_CONSTANTS.ACCOUNT_DETAILS}
      </h3>
      <div className="input">
        <ValidatedInput
          label={`${
            paymentMethod?.paymentType?.value === PaymentMethods.CARD
              ? `*${PAYMENT_CONSTANTS.NAME_ON_CARD}`
              : `*${PAYMENT_CONSTANTS.ACCOUNT_NAME}`
          }`}
          validatedStateForAutoFill={paymentMethod?.name}
          validate={validate}
          disabled={isExistingMethodSelected()}
          setValidatedState={event => {
            setPaymentMethod({
              ...paymentMethod,
              name: { value: event?.value?.replace(/\s+/g, ' '), valid: event?.valid && event?.value?.trim().length > 0 },
            });
          }}
          validators={[Validators.REQUIRED, Validators.NEEDS_UPPER_LOWER]}
        />
      </div>
      {paymentMethod?.paymentType?.value === PaymentMethods.CARD && (
        <>
          <div className="input">
            <ValidatedInput
              label={`*${PAYMENT_CONSTANTS.CARD_NUMBER}`}
              type="number"
              validatedStateForAutoFill={paymentMethod?.cardNumber}
              validate={validate}
              disabled={isExistingMethodSelected()}
              setValidatedState={event => {
                setPaymentMethod({ ...paymentMethod, cardNumber: { value: event?.value, valid: event?.valid } });
              }}
              validators={isExistingMethodSelected() ? [] : [Validators.REQUIRED, Validators.CARD_NUMBER]}
            />
          </div>
          <div className="input-row">
            <div className="input">
              <ValidatedInput
                label={`*${PAYMENT_CONSTANTS.EXPIRY_DATE}`}
                placeholder={PAYMENT_CONSTANTS.MM_YY}
                validatedStateForAutoFill={formatExpiryDate(paymentMethod?.expiry)}
                validate={validate}
                disabled={isExistingMethodSelected()}
                setValidatedState={event => {
                  setPaymentMethod({ ...paymentMethod, expiry: { value: event?.value, valid: event?.valid } });
                }}
                validators={[Validators.REQUIRED, Validators.EXPIRY_DATE_LENGTH]}
              />
            </div>

            {isExistingMethodSelected() ? (
              <></>
            ) : (
              <div className="input">
                <ValidatedInput
                  label={`*${PAYMENT_CONSTANTS.CVV}`}
                  placeholder={PAYMENT_CONSTANTS.CVV}
                  type="number"
                  validatedStateForAutoFill={paymentMethod?.cvv}
                  validate={validate}
                  setValidatedState={event => {
                    setPaymentMethod({ ...paymentMethod, cvv: { value: event?.value, valid: event?.valid } });
                  }}
                  validators={[Validators.REQUIRED, Validators.CVV_NUMBER]}
                />
              </div>
            )}
          </div>
        </>
      )}
      {paymentMethod?.paymentType?.value === PaymentMethods.ACH && buttonAction != PaymentActions.PAY_NOW_METHOD && (
        <>
          <div className="input-row">
            <div className="input">
              <label className="validated-input-label">*{ACCOUNT_CONSTANTS.ACCOUNT_TYPE}: </label>
              <FilterDropdown
                placeholder="Select"
                options={
                  Object.values(AccountTypesEnum).map(accountType => ({
                    name: accountType,
                    value: accountType,
                  })) as FilterOption<AccountTypesEnum>[]
                }
                value={{ name: paymentMethod?.accountType?.value, value: paymentMethod?.accountType?.value }}
                onChange={event => {
                  setPaymentMethod({ ...paymentMethod, accountType: { value: event?.value, name: event?.value } });
                }}
              />
            </div>
            <div className="input">
              <ValidatedInput
                label={`*${PAYMENT_CONSTANTS.ROUTING_NUMBER}`}
                type="number"
                validatedStateForAutoFill={paymentMethod?.routingNumber}
                setValidatedState={event => {
                  setPaymentMethod({ ...paymentMethod, routingNumber: { value: event?.value, valid: event?.valid } });
                }}
                validate={validate}
                validators={[Validators.REQUIRED, Validators.ROUTING_NUMBER]}
              />
            </div>
          </div>
          <div className="input-row">
            <div className="input">
              <ValidatedInput
                label={`*${PAYMENT_CONSTANTS.ACCOUNT_NUMBER}`}
                type="number"
                validatedStateForAutoFill={paymentMethod?.accountNumber}
                setValidatedState={event => {
                  setPaymentMethod({ ...paymentMethod, accountNumber: { value: event?.value, valid: event?.valid } });
                }}
                validate={validate}
                validators={[Validators.REQUIRED, Validators.ACCOUNT_NUMBER]}
                preventPaste={true}
              />
            </div>
            <div className="input">
              <ValidatedInput
                label={`*${PAYMENT_CONSTANTS.CONFIRM_ACCOUNT_NUMBER}`}
                type="number"
                validatedStateForAutoFill={confirmAccountNumber}
                setValidatedState={setConfirmAccountNumber ?? (() => {})}
                validate={validate}
                matchString={paymentMethod?.accountNumber?.value}
                validators={[Validators.REQUIRED]}
                preventPaste={true}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PaymentDetails;
