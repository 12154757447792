import { FC } from 'react';
import ValidatedInput from '../custom-input/ValidatedInput';
import { PaymentActions, PayNowPaymentMethods, ShippingPaymentMethods, Validators } from '../../types/enums';
import { PaymentMethod } from '../../types/interfaces/paymentMethod.interface';
import { ADDRESS_CONSTANTS, PAYMENT_CONSTANTS } from '../../constants/common';
import './addPaymentMethodModal.scss';

type BillingAddressProps = {
  paymentMethod: PaymentMethod;
  validate: boolean;
  buttonAction: string;
  paymentMethodType: string;
  setPaymentMethod: (paymentMethod: PaymentMethod) => void;
};

const BillingAddress: FC<BillingAddressProps> = ({
  paymentMethod,
  validate,
  buttonAction,
  paymentMethodType,
  setPaymentMethod,
}) => {
  const isExistingMethodSelected = () => {
    return (
      (buttonAction === PaymentActions.PAY_NOW_METHOD && paymentMethodType === PayNowPaymentMethods.EXISTING) ||
      (buttonAction === PaymentActions.SHIPPING_PAYMENT && paymentMethodType === ShippingPaymentMethods.CARD_ON_FILE)
    );
  };

  return (
    <div className="billing-address">
      <h3>{PAYMENT_CONSTANTS.BILLING_ADDRESS}</h3>
      <div className="input">
        <ValidatedInput
          label={ADDRESS_CONSTANTS.ADDRESS}
          validatedStateForAutoFill={paymentMethod?.address}
          setValidatedState={event => {
            setPaymentMethod({
              ...paymentMethod,
              address: { value: event?.value?.replace(/\s+/g, ' '), valid: event?.valid && event?.value?.trim().length > 0 },
            });
          }}
          validate={validate}
          disabled={isExistingMethodSelected()}
          validators={[Validators.REQUIRED]}
        />
      </div>
      <div className="input-row">
        <div className="input">
          <ValidatedInput
            label={ADDRESS_CONSTANTS.CITY}
            validatedStateForAutoFill={paymentMethod.city}
            validate={validate}
            setValidatedState={event => {
              setPaymentMethod({
                ...paymentMethod,
                city: { value: event?.value?.replace(/\s+/g, ' '), valid: event?.valid && event?.value?.trim().length > 0 },
              });
            }}
            disabled={isExistingMethodSelected()}
            validators={[Validators.REQUIRED]}
          />
        </div>
        <div className="input">
          <ValidatedInput
            label={ADDRESS_CONSTANTS.STATE_REGION}
            validatedStateForAutoFill={paymentMethod?.region}
            validate={validate}
            setValidatedState={event => {
              setPaymentMethod({
                ...paymentMethod,
                region: { value: event?.value?.replace(/\s+/g, ' '), valid: event?.valid && event?.value?.trim().length > 0 },
              });
            }}
            disabled={isExistingMethodSelected()}
            validators={[Validators.REQUIRED]}
          />
        </div>
        <div className="input">
          <ValidatedInput
            label={ADDRESS_CONSTANTS.ZIP_CODE}
            validatedStateForAutoFill={paymentMethod?.postalCode}
            validate={validate}
            setValidatedState={event => {
              setPaymentMethod({ ...paymentMethod, postalCode: { value: event?.value, valid: event?.valid } });
            }}
            disabled={isExistingMethodSelected()}
            validators={[Validators.REQUIRED, Validators.ZIP_CODE]}
          />
        </div>
        <div className="input">
          <ValidatedInput
            label={ADDRESS_CONSTANTS.COUNTRY}
            validatedStateForAutoFill={paymentMethod?.country}
            validate={validate}
            setValidatedState={event => {
              setPaymentMethod({
                ...paymentMethod,
                country: { value: event?.value?.replace(/\s+/g, ' '), valid: event?.valid && event?.value?.trim().length > 0 },
              });
            }}
            disabled={isExistingMethodSelected()}
            validators={[Validators.REQUIRED]}
          />
        </div>
      </div>
    </div>
  );
};

export default BillingAddress;
