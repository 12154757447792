import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { store } from '../../redux/store';
import { storePaymentStatus } from '../../redux/reducers/paymentGatewaySlice';
import { storeBillingAmount } from '../../redux/reducers/accountSlice';
import Modal from '../modal/Modal';
import { AlertSVG } from '../svgs';
import Loader from '../loader/Loader';
import { ValidatedState } from '../custom-input/ValidatedInput';
import PaymentDetails from './PaymentDetails';
import BillingAddress from './BillingAddress';
import { centsToDollars, setActiveAccount } from '../../services/account.service';
import { showToast } from '../../services/toast.service';
import {
  addPaymentDetails,
  clearOutstandingBalance,
  makeInstantPayment,
  updatePaymentDetails,
} from '../../api/paymentGatewayApi';
import { PaymentMethod } from '../../types/interfaces/paymentMethod.interface';
import { PaymentMethods, PaymentActions, PayNowPaymentMethods } from '../../types/enums';
import { ROUTE_PATHS } from '../../constants/routePaths';
import { toastMessages } from '../../constants/errorMessages';
import { BUTTON_CONSTANTS, LABEL_CONSTANTS, NOTIFICATION_CONSTANTS, PAYMENT_CONSTANTS } from '../../constants/common';
import './addPaymentMethodModal.scss';

export const initialPaymentState = {
  accountNumber: { value: '', valid: false },
  accountType: { value: '', name: '' },
  address: { value: '', valid: false },
  cardNumber: { value: '', valid: false },
  city: { value: '', valid: false },
  country: { value: '', valid: false },
  cvv: { value: '', valid: false },
  expiry: { value: '', valid: false },
  name: { value: '', valid: false },
  paymentType: { value: PaymentMethods.CARD, valid: true },
  postalCode: { value: '', valid: false },
  region: { value: '', valid: false },
  routingNumber: { value: '', valid: false },
};

type AddPaymentMethodModalProps = {
  accountId: number;
  buttonAction: string;
  isOpen: boolean;
  onclick: () => void;
  paymentDetails?: PaymentMethod;
  setIsOpen: (e: boolean) => void;
  setUpdatedPaymentInfo?: (e: boolean) => void;
  title: string;
};

const AddPaymentMethodModal: FC<AddPaymentMethodModalProps> = ({
  accountId,
  buttonAction,
  isOpen,
  onclick,
  paymentDetails,
  setIsOpen,
  setUpdatedPaymentInfo,
  title,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const activeAccount = useAppSelector(state => state.activeAccount);

  const [addingPaymentMethod, setAddingPaymentMethod] = useState<boolean>(true);
  const [clearingBalance, setClearingBalance] = useState<boolean>(true);
  const [confirmAccountNumber, setConfirmAccountNumber] = useState<ValidatedState>({ value: '', valid: false });
  const [makePaymentAsDefault, setMakePaymentAsDefault] = useState<boolean>(false);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(initialPaymentState);
  const [payNowPaymentMethod, setPayNowPaymentMethod] = useState<string>('');
  const [validate, setValidate] = useState<boolean>(false);

  useEffect(() => {
    paymentDetails != undefined && setPaymentMethod(paymentDetails);
    if (buttonAction === PaymentActions.PAY_NOW_METHOD) {
      if (paymentDetails?.paymentType?.value === PaymentMethods.ACH) {
        setPayNowPaymentMethod(PayNowPaymentMethods.NEW);
        setPaymentMethod(initialPaymentState);
      } else {
        setPayNowPaymentMethod(PayNowPaymentMethods.EXISTING);
      }
    }
  }, [paymentDetails]);

  const checkValidity = () => {
    const { address, city, region, postalCode, country, cardNumber, expiry, cvv, accountType, accountNumber, routingNumber } =
      paymentMethod || {};

    const isCardPayment = paymentMethod?.paymentType?.value === PaymentMethods.CARD;
    const isExistingPayNow =
      buttonAction === PaymentActions.PAY_NOW_METHOD && payNowPaymentMethod === PayNowPaymentMethods.EXISTING;

    const isBillingAddressValid = address?.valid && city?.valid && region?.valid && postalCode?.valid && country?.valid;
    const isCardValid = cardNumber?.valid && expiry?.valid && (isExistingPayNow || cvv?.valid);
    const isAccountValid =
      accountType?.value?.length > 0 &&
      accountNumber?.valid &&
      routingNumber?.valid &&
      accountNumber?.value === confirmAccountNumber?.value;

    return (
      paymentMethod?.paymentType?.valid &&
      paymentMethod?.name?.valid &&
      isBillingAddressValid &&
      (isCardPayment ? isCardValid : isAccountValid)
    );
  };

  const checkOutstandingBalance = () => {
    return activeAccount?.billingAmount?.outstandingBalanceCents > 0 || activeAccount?.billingAmount?.penaltyCents > 0;
  };

  const mapPaymentInfo = (paymentMethod: PaymentMethod) => {
    const { address, city, region, postalCode, country, cardNumber, expiry, cvv, accountType, accountNumber, routingNumber } =
      paymentMethod;

    let accountData =
      paymentMethod?.paymentType?.value === PaymentMethods.CARD
        ? { account: cardNumber?.value, expiry: expiry?.value, cvv2: cvv?.value }
        : { account: accountNumber?.value, bankRoutingNumber: routingNumber?.value, accountType: accountType?.value };

    return {
      paymentMethod: paymentMethod?.paymentType?.value,
      name: paymentMethod?.name?.value,
      address: address?.value,
      city: city?.value,
      region: region?.value,
      postal: postalCode?.value,
      country: country?.value,
      ...accountData,
    };
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!addingPaymentMethod) return;
    setAddingPaymentMethod(false);
    setValidate(true);

    const paymentInfo = mapPaymentInfo(paymentMethod);

    if (accountId) {
      try {
        if (buttonAction === PaymentActions.ADD_PAYMENT_METHOD) {
          await addPaymentDetails(accountId, paymentInfo);
          showToast.success(toastMessages.ADD_PAYMENT_METHOD_SUCCESS);
          setActiveAccount(accountId);
          navigate(ROUTE_PATHS.APP_DASHBOARD);
          setIsOpen(false);
        } else if (buttonAction === PaymentActions.UPDATE_PAYMENT_METHOD) {
          await updatePaymentDetails(accountId, paymentInfo);
          setUpdatedPaymentInfo && setUpdatedPaymentInfo(true);
          showToast.success(toastMessages.ADD_PAYMENT_UPDATED);
          setActiveAccount(accountId);
          setIsOpen(false);
        } else if (buttonAction === PaymentActions.PAY_NOW_METHOD) {
          if (payNowPaymentMethod === PayNowPaymentMethods.EXISTING) {
            await handleRetryPayment();
          } else if (payNowPaymentMethod === PayNowPaymentMethods.NEW) {
            if (makePaymentAsDefault) {
              await updatePaymentDetails(accountId, paymentInfo);
              setUpdatedPaymentInfo && setUpdatedPaymentInfo(true);
              showToast.success(toastMessages.ADD_PAYMENT_UPDATED);
              setActiveAccount(accountId);
              await handleRetryPayment();
            } else {
              await handleInstantPayment(paymentInfo);
            }
          }
        }

        dispatch(storePaymentStatus(true));
      } catch (error: any) {
        if (error?.response?.status === 701) {
          showToast.error(error.response?.data?.title);
        } else {
          showToast.error(toastMessages.SOMETHING_WENT_WRONG);
        }
        setIsOpen(true);
        setValidate(false);
      } finally {
        setAddingPaymentMethod(true);
      }
    }
  };

  const handleRetryPayment = async () => {
    if (!clearingBalance) return;
    setClearingBalance(false);

    try {
      const response = await clearOutstandingBalance(accountId);

      if (response?.data?.status === 'A') {
        showToast.success(toastMessages.PAYMENT_SUCCESS);
        setActiveAccount(accountId);
        store.dispatch(storeBillingAmount({ ...activeAccount.billingAmount, outstandingBalanceCents: 0 }));
        navigate(ROUTE_PATHS.APP_DASHBOARD);
        setIsOpen(false);
      } else {
        showToast.error(toastMessages.PAYMENT_FAIL);
      }
    } catch {
      showToast.error(toastMessages.PAYMENT_FAIL);
      setIsOpen(true);
      setValidate(false);
    } finally {
      setClearingBalance(true);
    }
  };

  const handleInstantPayment = async (paymentInfo?: any) => {
    if (!clearingBalance) return;
    setClearingBalance(false);

    try {
      const { data: paymentResponse } = await makeInstantPayment(accountId, paymentInfo);

      if (paymentResponse?.status === 'A') {
        showToast.success(toastMessages.PAYMENT_SUCCESS);
        setActiveAccount(accountId);
        store.dispatch(storeBillingAmount({ ...activeAccount.billingAmount, outstandingBalanceCents: 0 }));
        navigate(ROUTE_PATHS.APP_DASHBOARD);
        setIsOpen(false);
      } else {
        showToast.error(toastMessages.PAYMENT_FAIL);
      }
    } catch (error: any) {
      if (error?.response?.status === 701) {
        showToast.error(error.response?.data?.title);
      } else {
        showToast.error(toastMessages.PAYMENT_FAIL);
      }
      setIsOpen(true);
      setValidate(false);
    } finally {
      setClearingBalance(true);
    }
  };

  const isThePayNowExistingMethodACH = () => {
    return (
      buttonAction === PaymentActions.PAY_NOW_METHOD &&
      payNowPaymentMethod === PayNowPaymentMethods.NEW &&
      paymentDetails?.paymentType?.value === PaymentMethods.ACH
    );
  };

  useEffect(() => {
    if (buttonAction === PaymentActions.PAY_NOW_METHOD) {
      if (payNowPaymentMethod === PayNowPaymentMethods.EXISTING && paymentDetails) {
        setPaymentMethod(paymentDetails);
      } else if (payNowPaymentMethod === PayNowPaymentMethods.NEW) {
        setPaymentMethod(initialPaymentState);
      }
    }
  }, [payNowPaymentMethod]);

  return (
    <Modal ignoreBackdrop isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <div className="add-payment-method">
        <div className="header">
          <h4>{title}</h4>
        </div>
        <form action="submit" onSubmit={(event: React.FormEvent) => handleSubmit(event)}>
          <div className="payment-method">
            {!isThePayNowExistingMethodACH() && <label>{PAYMENT_CONSTANTS.PAYMENT_METHOD}</label>}
            {buttonAction === PaymentActions.PAY_NOW_METHOD ? (
              <>
                <div className="radio-row">
                  {paymentDetails?.paymentType?.value === PaymentMethods.CARD && (
                    <>
                      <input
                        type="radio"
                        id="payment-method-card"
                        name="payment-method-card"
                        value={payNowPaymentMethod}
                        onChange={() => setPayNowPaymentMethod(PayNowPaymentMethods.EXISTING)}
                        checked={payNowPaymentMethod === PayNowPaymentMethods.EXISTING}
                      />
                      <label htmlFor="payment-method-card">{PAYMENT_CONSTANTS.EXISTING_METHOD}</label>
                    </>
                  )}
                  {isThePayNowExistingMethodACH() ? (
                    <div className="card-details-note">
                      <p>
                        Please enter card details to resolve your outstanding balance of{' '}
                        <b>
                          $
                          {centsToDollars(
                            activeAccount?.billingAmount?.totalFineAmountCents +
                              (activeAccount?.billingAmount?.quickbooksLateFeeCents ?? 0),
                          )}
                        </b>
                        .
                      </p>
                    </div>
                  ) : (
                    <>
                      <input
                        type="radio"
                        id="payment-method-ach"
                        name="payment-method-ach"
                        value={payNowPaymentMethod}
                        onChange={() => setPayNowPaymentMethod(PayNowPaymentMethods.NEW)}
                        checked={payNowPaymentMethod === PayNowPaymentMethods.NEW}
                      />
                      <label htmlFor="payment-method-ach">{PAYMENT_CONSTANTS.NEW_METHOD}</label>
                    </>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="radio-row">
                  <input
                    type="radio"
                    id="payment-method-card"
                    name="payment-method-card"
                    value={paymentMethod?.paymentType?.value}
                    onChange={() => {
                      setPaymentMethod({ ...paymentMethod, paymentType: { value: PaymentMethods.CARD, valid: true } });
                    }}
                    checked={paymentMethod?.paymentType?.value === PaymentMethods.CARD}
                  />
                  <label htmlFor="payment-method-card">{PAYMENT_CONSTANTS.CARD}</label>
                  <>
                    <input
                      type="radio"
                      id="payment-method-ach"
                      name="payment-method-ach"
                      value={paymentMethod?.paymentType?.value}
                      onChange={() => {
                        setPaymentMethod({ ...paymentMethod, paymentType: { value: PaymentMethods.ACH, valid: true } });
                      }}
                      checked={paymentMethod?.paymentType?.value === PaymentMethods.ACH}
                    />
                    <label htmlFor="payment-method-ach">{PAYMENT_CONSTANTS.ACH_TRANSFER}</label>
                  </>
                </div>
              </>
            )}
          </div>

          <PaymentDetails
            paymentMethod={paymentMethod}
            validate={validate}
            buttonAction={buttonAction}
            paymentMethodType={payNowPaymentMethod}
            setPaymentMethod={setPaymentMethod}
            confirmAccountNumber={confirmAccountNumber}
            setConfirmAccountNumber={setConfirmAccountNumber}
          />

          {(!clearingBalance || !addingPaymentMethod) && <Loader loaderSize={'medium'} />}

          <BillingAddress
            paymentMethod={paymentMethod}
            validate={validate}
            buttonAction={buttonAction}
            paymentMethodType={payNowPaymentMethod}
            setPaymentMethod={setPaymentMethod}
          />

          {buttonAction === PaymentActions.PAY_NOW_METHOD && payNowPaymentMethod === PayNowPaymentMethods.NEW && (
            <div className="set-as-default-condition">
              <input
                type="checkbox"
                id="default-pay-method"
                name="default-pay-method"
                value="default-pay-method"
                checked={makePaymentAsDefault}
                onChange={() => setMakePaymentAsDefault(!makePaymentAsDefault)}
              />
              <p>{PAYMENT_CONSTANTS.SET_DEFAULT_PAYMENT_METHOD}</p>
            </div>
          )}

          <div className="payment-transaction-note">
            {paymentMethod?.paymentType?.value === PaymentMethods.CARD && (
              <div className="transaction-alert">
                <AlertSVG />
                <p>{NOTIFICATION_CONSTANTS.CC_PROCESSING_FEE_MSG}</p>
              </div>
            )}
            {paymentMethod?.paymentType?.value === PaymentMethods.ACH && (
              <div className="outstanding-balance-note">
                <p>
                  <b>{LABEL_CONSTANTS.NOTE}:</b> {NOTIFICATION_CONSTANTS.ACH_PAYMENT_METHOD_NOTE}
                </p>
              </div>
            )}
            {checkOutstandingBalance() && buttonAction === PaymentActions.PAY_NOW_METHOD && (
              <div className="outstanding-balance-note">
                {payNowPaymentMethod === PayNowPaymentMethods.EXISTING ? (
                  <p>{NOTIFICATION_CONSTANTS.PAY_NOW_EXISTING_FOOTER}</p>
                ) : payNowPaymentMethod === PayNowPaymentMethods.NEW && makePaymentAsDefault ? (
                  <p>{NOTIFICATION_CONSTANTS.PAY_NOW_SET_DEFAULT_METHOD_FOOTER}</p>
                ) : (
                  <p>{NOTIFICATION_CONSTANTS.PAY_NOW_NEW_FOOTER}</p>
                )}
              </div>
            )}
          </div>
          <div className="footer">
            <button
              type="submit"
              disabled={!checkValidity() || !clearingBalance || !addingPaymentMethod}
              className="button green medium">
              {buttonAction === PaymentActions.PAY_NOW_METHOD
                ? payNowPaymentMethod === PayNowPaymentMethods.NEW && makePaymentAsDefault
                  ? BUTTON_CONSTANTS.SAVE_PAY
                  : BUTTON_CONSTANTS.PAY
                : BUTTON_CONSTANTS.SAVE}
            </button>
            <button className="button green inverted mr-1" onClick={onclick} disabled={!clearingBalance || !addingPaymentMethod}>
              {BUTTON_CONSTANTS.CANCEL}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddPaymentMethodModal;
