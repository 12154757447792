import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import SignOutModal from '../sign-out-modal/SignOutModal';
import ProfileImage from '../profile-image/ProfileImage';
import { PermissionService } from '../../services/permission.service';
import { ACCOUNT_PATHS, APP_PATHS, ROUTE_PATHS } from '../../constants/routePaths';
import { DashboardSVG, DataSVG, GoatSVG, HistorySVG, InventorySVG, LocationSVG, ReportsSVG } from '../svgs';
import { PersonsSVG, SettingsSVG, TabMenuSvg } from '../svgs';

type NavBarTabletViewProps = {
  permissionService: PermissionService;
  setOpenNavBar: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAdminNav: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAccountNav: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAnimalManagementOptions: React.Dispatch<React.SetStateAction<boolean>>;
  setShowInventoryManagementOptions: React.Dispatch<React.SetStateAction<boolean>>;
  setShowReportOptions: React.Dispatch<React.SetStateAction<boolean>>;
  currentRoute: string[];
};

const NavBarTabletView: FC<NavBarTabletViewProps> = ({
  permissionService,
  setOpenNavBar,
  setShowAdminNav,
  setShowAccountNav,
  setShowAnimalManagementOptions,
  setShowInventoryManagementOptions,
  setShowReportOptions,
  currentRoute,
}) => {
  const location = useLocation();
  const appPage = location.pathname.split('/')[2];
  const user = useAppSelector(state => state.user);
  const profileImageBlob = useAppSelector(state => state.user.profileImageBlob);
  const profileImageId = useAppSelector(state => state.user.profileImageId);

  const activeLinkClass = (path: string) => {
    return currentRoute.includes(path) ? 'nav-link active' : 'nav-link';
  };

  const NavLinkButton = ({ path, onClick, children }: { path: string; onClick?: () => void; children: React.ReactNode }) => (
    <Link className={activeLinkClass(path)} to={path} onClick={onClick}>
      {children}
    </Link>
  );

  const NavButton = ({ path, onClick, children }: { path: string; onClick: () => void; children: React.ReactNode }) => (
    <button type="button" className={activeLinkClass(path)} onClick={onClick}>
      {children}
    </button>
  );

  const renderAdminNav = () => (
    <div className="tab-grid-nav-bar">
      <NavButton
        path={APP_PATHS.ANIMAL_MANAGEMENT}
        onClick={() => {
          setOpenNavBar(true);
          setShowAdminNav(true);
          setShowAccountNav(false);
          setShowAnimalManagementOptions(true);
        }}>
        <GoatSVG />
      </NavButton>
      <NavButton
        path={APP_PATHS.INVENTORY_MANAGEMENT}
        onClick={() => {
          setOpenNavBar(true);
          setShowAdminNav(true);
          setShowAccountNav(false);
          setShowInventoryManagementOptions(true);
        }}>
        <InventorySVG />
      </NavButton>
      <NavLinkButton path={APP_PATHS.TRANSACTION_MANAGEMENT} onClick={() => setShowAdminNav(true)}>
        <HistorySVG />
      </NavLinkButton>
      <NavLinkButton path={APP_PATHS.CUSTOMER_INFO} onClick={() => setShowAdminNav(true)}>
        <DataSVG />
      </NavLinkButton>
      <NavLinkButton path={APP_PATHS.LOCATION_MANAGEMENT} onClick={() => setShowAdminNav(true)}>
        <LocationSVG />
      </NavLinkButton>
      <NavButton
        path={APP_PATHS.REPORTS}
        onClick={() => {
          setOpenNavBar(true);
          setShowAdminNav(true);
          setShowAccountNav(false);
          setShowReportOptions(true);
        }}>
        <ReportsSVG />
      </NavButton>
      <NavLinkButton path={APP_PATHS.APPLICATION_TEAM} onClick={() => setShowAdminNav(true)}>
        <PersonsSVG />
      </NavLinkButton>
      <NavLinkButton path={APP_PATHS.ADMIN_SETTINGS} onClick={() => setShowAdminNav(true)}>
        <SettingsSVG />
      </NavLinkButton>
    </div>
  );

  const renderAccountNav = () => (
    <div className="tab-grid-nav-bar">
      <NavLinkButton path={ACCOUNT_PATHS.DASHBOARD} onClick={() => setShowAccountNav(true)}>
        <DashboardSVG />
      </NavLinkButton>
      <NavLinkButton path={ACCOUNT_PATHS.INVENTORY} onClick={() => setShowAccountNav(true)}>
        <InventorySVG />
      </NavLinkButton>
      <NavLinkButton path={ACCOUNT_PATHS.TRANSACTION_HISTORY} onClick={() => setShowAccountNav(true)}>
        <HistorySVG />
      </NavLinkButton>
      {permissionService.userHasTeamManagementPermission() && (
        <NavLinkButton path={ACCOUNT_PATHS.TEAM_MANAGEMENT} onClick={() => setShowAccountNav(true)}>
          <PersonsSVG />
        </NavLinkButton>
      )}
    </div>
  );

  const renderSettingsNav = () => (
    <div className="tab-setting-nav-bar">
      <NavLinkButton path={ACCOUNT_PATHS.ACCOUNT_SETTINGS}>
        <SettingsSVG />
      </NavLinkButton>
    </div>
  );

  return (
    <div>
      <button type="button" onClick={() => setOpenNavBar(true)}>
        <TabMenuSvg />
      </button>
      <div>
        <Link to={ROUTE_PATHS.APP_USER_PROFILE}>
          <ProfileImage profileImageId={profileImageId} profileImageBlob={profileImageBlob} />
        </Link>
      </div>

      {user.accounts.length > 0
        ? permissionService.userHasRSGAdminPermission() && !Object.values(ACCOUNT_PATHS).includes(appPage)
          ? renderAdminNav()
          : renderAccountNav()
        : permissionService.userHasRSGAdminPermission() && Object.values(APP_PATHS).includes(appPage) && renderAdminNav()}

      <hr />
      {user.accounts.length > 0 && permissionService.userHasSettingsPermission() && renderSettingsNav()}

      <div className="tab-sign-out">
        <SignOutModal />
      </div>
    </div>
  );
};

export default NavBarTabletView;
