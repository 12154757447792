import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import AccountInfo from '../../components/account-info/AccountInfo';
import AddPaymentMethodModal, { initialPaymentState } from '../../components/payment-method-modal/AddPaymentMethodModal';
import CatalogSell from './dashboard-components/CatalogSell';
import RecentlyAcquiredInventory from './dashboard-components/RecentlyAcquiredInventory';
import PendingTransactions from './dashboard-components/PendingTransactions';
import InventoryGraphics from '../../components/account-info/InventoryGraphics';
import NotificationBanner from '../../components/notificationBanner/NotificationBanner';
import { centsToDollars } from '../../services/account.service';
import {
  doesAccountHasPendingPaymentTransaction,
  doesUserHaveSettingAccess,
  getPaymentDetails,
} from '../../services/payment.service';
import { PermissionService } from '../../services/permission.service';
import { showToast } from '../../services/toast.service';
import { getAnimalOwnershipsByAccountId } from '../../api/animalOwnersApi';
import { AccountRoles, AppRoles } from '../../types/enums';
import { PaymentMethod } from '../../types/interfaces/paymentMethod.interface';
import { toastMessages } from '../../constants/errorMessages';
import { ROUTE_PATHS } from '../../constants/routePaths';
import { BUTTON_CONSTANTS, NOTIFICATION_CONSTANTS, PAGE_HEADER_CONSTANTS } from '../../constants/common';
import AnimalOwnership from './dashboard-components/AnimalOwnership';

const Dashboard = (): JSX.Element => {
  const accountId = useAppSelector(state => state.activeAccount.accountId);
  const accountRoles = useAppSelector(state => state.auth.accountContext.roles) as AccountRoles[];
  const activeAccount = useAppSelector(state => state.activeAccount);
  const appRoles = useAppSelector(state => state.auth.appContext.roles) as AppRoles[];
  const enabled = useAppSelector(state => state.user.enabled);
  const paymentStatus = useAppSelector(state => state.payment.addedPaymentMethod);
  const user = useAppSelector(state => state.user);
  const verified = useAppSelector(state => state.user.verified);

  const [addPaymentMethodModalVisible, setAddPaymentMethodModalVisible] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState<PaymentMethod>(initialPaymentState);
  const [pendingACHPayment, setPendingACHPayment] = useState<boolean>();
  const [showPayNowButton, setShowPayNowButton] = useState<boolean>();
  const [userHaveAnimalOwnership, setUserHaveAnimalOwnership] = useState(false);
  const [userHaveSettingAccess, setUserHaveSettingAccess] = useState<boolean>();

  const permissionService = new PermissionService(accountRoles, appRoles, enabled, verified);

  const checkPaymentValidity = () => {
    return activeAccount?.billingAmount?.outstandingBalanceCents > 0 || activeAccount?.billingAmount?.penaltyCents > 0;
  };

  const getTotalAnimalOwnershipsForAccount = useCallback(async () => {
    if (accountId) {
      try {
        const { data: animalOwnership } = await getAnimalOwnershipsByAccountId({ filter: `accountId eq ${accountId}` });
        setUserHaveAnimalOwnership(animalOwnership.length != 0);
      } catch (error: any) {
        throw new Error(error);
      }
    }
  }, [accountId]);

  useEffect(() => {
    getTotalAnimalOwnershipsForAccount();
  }, [getTotalAnimalOwnershipsForAccount]);

  useEffect(() => {
    const fetchPaymentDetails = async () => {
      if (permissionService.userHasSettingsPermission() && accountId && paymentStatus && activeAccount.billingEnabled) {
        const paymentData = await getPaymentDetails(accountId);
        paymentData && setPaymentInfo(paymentData);
      }
    };
    fetchPaymentDetails();
  }, [addPaymentMethodModalVisible, accountId]);

  useEffect(() => {
    const fetchSettingsAccess = async () => {
      if (accountId) {
        try {
          const hasSettingAccess = await doesUserHaveSettingAccess(accountId, user?.userId);
          setUserHaveSettingAccess(hasSettingAccess);
          checkPaymentValidity() && setShowPayNowButton(hasSettingAccess);
        } catch (error) {
          showToast.error(toastMessages.SOMETHING_WENT_WRONG);
        }
      }
    };

    fetchSettingsAccess();
  }, [activeAccount]);

  useEffect(() => {
    const fetchPendingPayment = async () => {
      if (accountId && permissionService.userHasSettingsPermission()) {
        try {
          const hasPendingPayment = await doesAccountHasPendingPaymentTransaction();
          setPendingACHPayment(hasPendingPayment);
        } catch (error) {
          showToast.error(toastMessages.SOMETHING_WENT_WRONG);
        }
      }
    };

    fetchPendingPayment();
  }, [accountId]);

  const accountSettingsLink = (
    <Link to={ROUTE_PATHS.APP_ACCOUNT_SETTINGS} className="payment-details-link" state={{ activeTab: 2 }}>
      {PAGE_HEADER_CONSTANTS.SETTINGS}
    </Link>
  );

  const getNotificationMessage = () => {
    if (userHaveSettingAccess != undefined && userHaveSettingAccess) {
      return <p>To access all the features of this account, please go to {accountSettingsLink} and add your payment details.</p>;
    } else {
      return <p>{NOTIFICATION_CONSTANTS.ADD_PAYMENT_DETAILS}</p>;
    }
  };

  return (
    <div className="dashboard">
      {user?.accounts?.length > 0 && paymentStatus != undefined && !paymentStatus && (
        <>
          <div className="xs-notification-banner max-width">
            <NotificationBanner
              children={<div className="payment-details-not-found-notification">{getNotificationMessage()}</div>}
            />
          </div>
        </>
      )}
      {user?.accounts?.length > 0 &&
        paymentStatus != undefined &&
        paymentStatus &&
        (activeAccount?.billingAmount?.outstandingBalanceCents > 0 ? (
          <>
            <div className="xs-notification-banner max-width">
              {pendingACHPayment != undefined &&
                (!pendingACHPayment ? (
                  <NotificationBanner
                    children={
                      <div className="pay-now-notification">
                        <p className={`${!showPayNowButton ? 'full-width-pay-now-notification' : ''}`}>
                          {activeAccount?.billingAmount?.penaltyCents > 0 ? (
                            /* Here we need to add the quickbooks late fee in total fine amount in case we turn on the penalty */
                            <>
                              <b>{activeAccount?.name}</b> account has a balance due of{' '}
                              <b>${centsToDollars(activeAccount?.billingAmount?.totalFineAmountCents)}</b>. (Includes{' '}
                              <b>${centsToDollars(activeAccount?.billingAmount?.outstandingBalanceCents)}</b> outstanding balance,{' '}
                              <b>${centsToDollars(activeAccount?.billingAmount?.lateFeeCents)}</b> late fee,{' '}
                              <b> ${centsToDollars(activeAccount?.billingAmount?.financeChargeCents)}</b> finance charge of{' '}
                              {activeAccount?.billingAmount?.financeChargeRate}%)
                            </>
                          ) : (
                            <>
                              <b>{activeAccount?.name}</b> account has a balance due of{' '}
                              <b>
                                $
                                {centsToDollars(
                                  activeAccount?.billingAmount?.totalFineAmountCents +
                                    (activeAccount?.billingAmount?.quickbooksLateFeeCents ?? 0),
                                )}
                              </b>
                              . Please pay the balance to avoid additional charges.
                            </>
                          )}
                        </p>
                        {showPayNowButton != undefined && showPayNowButton && (
                          <button
                            className="button green small pay-now-button"
                            type="button"
                            onClick={() => setAddPaymentMethodModalVisible(true)}>
                            {BUTTON_CONSTANTS.PAY_NOW}
                          </button>
                        )}
                      </div>
                    }
                  />
                ) : (
                  <>
                    <NotificationBanner
                      children={
                        <div className="payment-details-not-found-notification">
                          <p>{NOTIFICATION_CONSTANTS.PENDING_ACH_PAYMENT}</p>
                        </div>
                      }
                    />
                  </>
                ))}
            </div>
          </>
        ) : (
          activeAccount?.billingAmount?.outstandingBalanceCents <= 0 &&
          activeAccount?.locked && (
            <div className="xs-notification-banner max-width">
              <NotificationBanner
                children={
                  <div className="payment-details-not-found-notification">
                    <p>{NOTIFICATION_CONSTANTS.ACCOUNT_LOCKED}</p>
                  </div>
                }
              />
            </div>
          )
        ))}

      {accountId && addPaymentMethodModalVisible && (
        <AddPaymentMethodModal
          title={BUTTON_CONSTANTS.PAY_NOW}
          accountId={accountId}
          paymentDetails={paymentInfo}
          isOpen={addPaymentMethodModalVisible}
          setIsOpen={setAddPaymentMethodModalVisible}
          onclick={() => setAddPaymentMethodModalVisible(false)}
          buttonAction={'PayNow'}
        />
      )}

      {accountId !== undefined && (
        <>
          <div className="card max-width">
            <AccountInfo accountId={accountId} />
          </div>
          <div className="card max-width">
            <InventoryGraphics accountId={accountId} />
          </div>
        </>
      )}

      <RecentlyAcquiredInventory />
      <PendingTransactions />
      {userHaveAnimalOwnership && <CatalogSell />}
      <AnimalOwnership />
    </div>
  );
};

export default Dashboard;
