import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { SortedTable } from '../../../components';
import { InventoryTransaction } from '../../../types/interfaces';
import { TransactionTypeEnum } from '../../../types/enums';
import { getInventoryTransactions } from '../../../api/inventoryTransactionsApi';
import { showToast } from '../../../services/toast.service';
import { toastMessages } from '../../../constants/errorMessages';
import { LABEL_CONSTANTS, TABLE_HEADER_CONSTANTS } from '../../../constants/common';

const RecentlyAcquiredInventory = (): JSX.Element => {
  const accountId = useAppSelector(state => state.activeAccount.accountId);

  const [inventory, setInventory] = useState<InventoryTransaction[]>([]);
  const [loadingItems, setLoadingItems] = useState<boolean>(false);

  const includeInRequest = 'Specimen.SpecimenType,Specimen.Animal,TransactionLink.Account';

  useEffect(() => {
    const fetchInventory = async () => {
      if (!accountId) return;

      try {
        setLoadingItems(true);
        const { data } = await getInventoryTransactions({
          include: includeInRequest,
          filter: `accountId eq ${accountId};transactionType eq ${TransactionTypeEnum.Purchase}||transactionType eq ${TransactionTypeEnum.Collect}||transactionType eq ${TransactionTypeEnum.Deposit}`,
          limit: 5,
          sort: '^createdDatetime',
        });

        const inventoryTransactionData = (data as any).result;
        setInventory(inventoryTransactionData);
      } catch (error: any) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      } finally {
        setLoadingItems(false);
      }
    };

    fetchInventory();
  }, [accountId]);

  const getSortedTable = (headers: string[], className: string) => {
    return (
      <div className={className}>
        <SortedTable
          sortIndex={className === 'mobile-view' ? 1 : 2}
          headers={headers.map(header => ({ displayName: header }))}
          data={inventory?.map((i: InventoryTransaction) => {
            return className === 'mobile-view'
              ? [
                  { content: i?.specimen?.animal?.code ? i?.specimen?.animal?.code + ' - ' + i?.specimen?.animal?.name : '-' },
                  { content: new Date(i?.createdDatetime).toLocaleDateString() },
                  { content: i?.changeQuantity },
                ]
              : [
                  { content: i?.specimen?.animal?.code ? i?.specimen?.animal?.code + ' - ' + i?.specimen?.animal?.name : '-' },
                  { content: i?.specimen?.specimenType?.name },
                  { content: new Date(i?.createdDatetime).toLocaleDateString() },
                  { content: i?.changeQuantity },
                  { content: i?.transactionType },
                  { content: i?.transactionLink?.account?.name },
                ];
          })}
          loading={loadingItems}
          noResultsMessage={LABEL_CONSTANTS.NO_INVENTORY}
        />
      </div>
    );
  };

  return (
    <div className="card full recently-acquired-inventory">
      <h2 className="inventory-header">{LABEL_CONSTANTS.ACQUIRED_INVENTORY}</h2>
      <div className="inventory-table">
        {getSortedTable(
          [TABLE_HEADER_CONSTANTS.ANIMAL, TABLE_HEADER_CONSTANTS.PURCHASE_DATE, TABLE_HEADER_CONSTANTS.QUANTITY],
          'mobile-view',
        )}
        {getSortedTable(
          [
            TABLE_HEADER_CONSTANTS.ANIMAL,
            TABLE_HEADER_CONSTANTS.ITEM_TYPE,
            TABLE_HEADER_CONSTANTS.ACQUIRED,
            TABLE_HEADER_CONSTANTS.QUANTITY,
            TABLE_HEADER_CONSTANTS.TRANSACTION_TYPE,
            TABLE_HEADER_CONSTANTS.SELLER,
          ],
          'desktop-view',
        )}
      </div>
    </div>
  );
};
export default RecentlyAcquiredInventory;
