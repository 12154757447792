import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useAppSelector } from '../redux/hooks';
import NavBar from '../components/nav-bar/NavBar';
import TitleBar from '../components/title-bar/TitleBar';
import Loader from '../components/loader/Loader';
import { CancelSVG, HamburgerMenuSVG } from '../components/svgs';
import Dashboard from '../pages/dashboard/Dashboard';
import Inventory from '../pages/inventory/Inventory';
import Settings from '../pages/settings/Settings';
import UserProfileEdit from '../pages/user-profile/user-edit/UserProfileEdit';
import ManageSpeciesBreeds from '../pages/animal-management/species-breeds/ManageSpeciesBreeds';
import ManageAnimals from '../pages/animal-management/ManageAnimals';
import TeamManagement from '../pages/team-management/ManageTeam';
import TransactionManagement from '../pages/transaction-management/TransactionManagement';
import TransactionHistory from '../pages/transaction-history/TransactionHistory';
import ApplicationTeam from '../pages/application-team/ApplicationTeam';
import CustomerInfo from '../pages/customer-info/CustomerInfo';
import AccountSettings from '../pages/account-settings/AccountSettings';
import LocationManagement from '../pages/location-management/LocationManagement';
import NotAuthorized from '../pages/error-pages/NotAuthorized';
import AdminSettings from '../pages/admin-settings/AdminSettings';
import TransactionCart from '../pages/transaction-cart/TransactionCart';
import InventoryRoutes from './Inventory.routes';
import SettingsRoutes from './Settings.routes';
import AnimalManagementRoutes from './AnimalManagement.routes';
import InventoryManagementRoutes from './InventoryManagement.routes';
import UserProfileRoutes from './UserProfile.routes';
import TeamManagementRoutes from './TeamManagement.routes';
import TransactionManagementRoutes from './TransactionManagement.routes';
import TransactionHistoryRoutes from './TransactionHistory.routes';
import TransactionCartRoutes from './TransactionCart.routes';
import CustomerInfoRoutes from './CustomerInfo.routes';
import ReportsRoutes from './Reports.routes';
import ApplicationTeamRoutes from './ApplicationTeam.routes';
import AuthWrapper from './auth-wrapper/AuthWrapper';
import { ACCOUNT_PATHS, APP_PATHS } from '../constants/routePaths';
import { AccountRoles } from '../types/enums/accountRoles.enum';
import { AppRoles } from '../types/enums/appRoles.enum';
import { PermissionService } from '../services/permission.service';

const ApplicationRoutes = () => {
  const appRoles = useAppSelector(state => state.auth.appContext.roles) as AppRoles[];
  const accountRoles = useAppSelector(state => state.auth.accountContext.roles) as AccountRoles[];
  const enabled = useAppSelector(state => state.user.enabled);
  const userIsVerified = useAppSelector(state => state.user.verified);
  const [loading, setLoading] = useState<boolean>(true);
  const accountToken = useAppSelector(state => state.auth.accountContext.token);
  const [open, setOpen] = useState<boolean>(false);

  function handleElement(element: JSX.Element) {
    const isReady = () => accountToken && !loading;

    if (isReady()) {
      return element;
    } else {
      return <Loader loaderSize="medium" pageLoader simple />;
    }
  }

  const permissionService = new PermissionService(accountRoles, appRoles, enabled, userIsVerified);

  return (
    <div className="app">
      <div className="app-header">
        <div className="show-hide-menu-button">
          {!open ? (
            <button type="button" onClick={() => setOpen(!open)}>
              <HamburgerMenuSVG />
            </button>
          ) : (
            <button type="button" onClick={() => setOpen(!open)}>
              <CancelSVG />
            </button>
          )}
        </div>
        <TitleBar showSignOut={loading} />
      </div>
      <AuthWrapper setLoading={setLoading} loading={loading} permissionService={permissionService}>
        <div className="app-body">
          <NavBar permissionService={permissionService} setLoading={setLoading} open={open} setOpen={setOpen} />
          <div className="app-content" style={{ opacity: open ? 0.3 : 1 }}>
            {permissionService.userIsEnabled() && (
              <Routes>
                <Route path={ACCOUNT_PATHS.DASHBOARD} element={handleElement(<Dashboard />)} />
                {permissionService.userIsVerified() && (
                  <>
                    <Route
                      path={APP_PATHS.USER_PROFILE}
                      element={
                        !loading ? (
                          <UserProfileEdit permissionService={permissionService} />
                        ) : (
                          <Loader loaderSize="medium" pageLoader simple />
                        )
                      }
                    />

                    <Route
                      path={APP_PATHS.USER_PROFILE_ + '*'}
                      element={!loading ? <UserProfileRoutes /> : <Loader loaderSize="medium" pageLoader simple />}
                    />

                    <Route
                      path={ACCOUNT_PATHS.INVENTORY}
                      element={handleElement(<Inventory permissionService={permissionService} />)}
                    />

                    <Route
                      path={ACCOUNT_PATHS.INVENTORY_ + '*'}
                      element={handleElement(<InventoryRoutes permissionService={permissionService} />)}
                    />

                    <Route path={ACCOUNT_PATHS.TRANSACTION_HISTORY} element={handleElement(<TransactionHistory />)} />

                    <Route
                      path={ACCOUNT_PATHS.TRANSACTION_HISTORY_ + '*'}
                      element={handleElement(<TransactionHistoryRoutes />)}
                    />

                    <Route
                      path={ACCOUNT_PATHS.TEAM_MANAGEMENT}
                      element={handleElement(
                        permissionService.userHasTeamManagementPermission() ? <TeamManagement /> : <NotAuthorized />,
                      )}
                    />
                    <Route
                      path={ACCOUNT_PATHS.TEAM_MANAGEMENT_ + '*'}
                      element={handleElement(
                        permissionService.userHasTeamManagementPermission() ? <TeamManagementRoutes /> : <NotAuthorized />,
                      )}
                    />

                    {permissionService.userHasSettingsPermission() && (
                      <>
                        <Route path={ACCOUNT_PATHS.ACCOUNT_SETTINGS} element={<AccountSettings />} />
                        <Route path={ACCOUNT_PATHS.SETTINGS} element={<Settings />} />
                        <Route path={ACCOUNT_PATHS.SETTINGS_ + '*'} element={<SettingsRoutes />} />
                      </>
                    )}

                    {(permissionService.userHasRSGAdminPermission() || permissionService.userHasInventoryPermission()) && (
                      <>
                        <Route
                          path={APP_PATHS.TRANSACTION_CART}
                          element={<TransactionCart permissionService={permissionService} />}
                        />
                        <Route
                          path={APP_PATHS.TRANSACTION_CART_ + '*'}
                          element={<TransactionCartRoutes permissionService={permissionService} />}
                        />
                      </>
                    )}

                    {permissionService.userHasRSGAdminPermission() && (
                      <>
                        <Route path={APP_PATHS.ANIMAL_MANAGEMENT} element={<ManageAnimals />} />
                        <Route path={APP_PATHS.ANIMAL_MANAGEMENT_ + '*'} element={<AnimalManagementRoutes />} />
                        <Route path={APP_PATHS.SPECIES_BREED_MANAGEMENT} element={<ManageSpeciesBreeds />} />
                        <Route path={APP_PATHS.INVENTORY_MANAGEMENT_ + '*'} element={<InventoryManagementRoutes />} />
                        <Route path={APP_PATHS.TRANSACTION_MANAGEMENT} element={<TransactionManagement />} />
                        <Route path={APP_PATHS.TRANSACTION_MANAGEMENT_ + '*'} element={<TransactionManagementRoutes />} />
                        <Route path={APP_PATHS.REPORTS_ + '*'} element={<ReportsRoutes />} />
                        <Route path={APP_PATHS.APPLICATION_TEAM} element={<ApplicationTeam />} />
                        <Route path={APP_PATHS.APPLICATION_TEAM_ + '*'} element={<ApplicationTeamRoutes />} />
                        <Route path={APP_PATHS.CUSTOMER_INFO} element={<CustomerInfo />} />
                        <Route path={APP_PATHS.CUSTOMER_INFO_ + '*'} element={<CustomerInfoRoutes />} />
                        <Route path={APP_PATHS.LOCATION_MANAGEMENT} element={<LocationManagement />} />
                        <Route path={APP_PATHS.ADMIN_SETTINGS} element={<AdminSettings />} />
                      </>
                    )}
                  </>
                )}
              </Routes>
            )}
          </div>
        </div>
      </AuthWrapper>
    </div>
  );
};

export default ApplicationRoutes;
