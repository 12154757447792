import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { DashboardSVG, HistorySVG, InventorySVG, PersonsSVG } from '../../svgs';
import { ACCOUNT_PATHS } from '../../../constants/routePaths';
import { NAVIGATION_LINK_CONSTANTS } from '../../../constants/common';
import { PermissionService } from '../../../services/permission.service';

type UserNavProps = {
  currentRoute: string[];
  showMenu?: boolean;
  permissionService: PermissionService;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenNavBar: React.Dispatch<React.SetStateAction<boolean>>;
};

const AccountNav: FC<UserNavProps> = ({ currentRoute, showMenu = true, permissionService, setOpen, setOpenNavBar }) => {
  const activeLinkClass = (path: string) => {
    return currentRoute.includes(path) ? 'nav-link active' : 'nav-link';
  };

  const closeSideBar = () => {
    setOpen(false);
    setOpenNavBar(false);
  };

  return (
    <div className={showMenu ? 'account-nav' : 'account-nav hide'}>
      <Link className={activeLinkClass(ACCOUNT_PATHS.DASHBOARD)} to={ACCOUNT_PATHS.DASHBOARD} onClick={closeSideBar}>
        <DashboardSVG />
        {NAVIGATION_LINK_CONSTANTS.DASHBOARD}
      </Link>
      <Link className={activeLinkClass(ACCOUNT_PATHS.INVENTORY)} to={ACCOUNT_PATHS.INVENTORY} onClick={closeSideBar}>
        <InventorySVG />
        {NAVIGATION_LINK_CONSTANTS.INVENTORY}
      </Link>
      <Link
        className={activeLinkClass(ACCOUNT_PATHS.TRANSACTION_HISTORY)}
        to={ACCOUNT_PATHS.TRANSACTION_HISTORY}
        onClick={closeSideBar}>
        <HistorySVG />
        {NAVIGATION_LINK_CONSTANTS.TRANSACTION_HISTORY}
      </Link>
      {permissionService.userHasTeamManagementPermission() && (
        <Link
          className={activeLinkClass(ACCOUNT_PATHS.TEAM_MANAGEMENT)}
          to={ACCOUNT_PATHS.TEAM_MANAGEMENT}
          onClick={closeSideBar}>
          <PersonsSVG />
          {NAVIGATION_LINK_CONSTANTS.TEAM_MANAGEMENT}
        </Link>
      )}
    </div>
  );
};

export default AccountNav;
