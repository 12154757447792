import { useAppSelector } from '../../../redux/hooks';
import PagedTable from '../../../components/sorted-table/PagedTable';
import { AnimalOwner } from '../../../types/interfaces';
import { getAnimalOwnershipsByAccountId } from '../../../api/animalOwnersApi';
import { ANIMAL_CONSTANTS, LABEL_CONSTANTS, TABLE_HEADER_CONSTANTS } from '../../../constants/common';

const AnimalOwnership = (): JSX.Element => {
  const accountId = useAppSelector(state => state.activeAccount.accountId);

  return (
    <div className="card full animal-ownership">
      <h2 className="animal-ownership-header">{ANIMAL_CONSTANTS.ANIMAL_OWNERSHIP}</h2>
      <div className="animal-ownership-table">
        <PagedTable
          headers={[
            TABLE_HEADER_CONSTANTS.ANIMAL_CODE,
            TABLE_HEADER_CONSTANTS.ANIMAL_NAME,
            TABLE_HEADER_CONSTANTS.OWNERSHIP_PERCENTAGE,
          ].map(header => ({ displayName: header }))}
          height={254}
          filter={`accountId eq ${accountId}`}
          include="Animal"
          getData={params => {
            return getAnimalOwnershipsByAccountId(params);
          }}
          buildRow={(item: AnimalOwner) => {
            return [item?.animal?.code, item?.animal?.name, item?.ownershipPercentage];
          }}
          noResultsMessage={LABEL_CONSTANTS.ACCOUNT_HAS_NO_ANIMAL_OWNERSHIP}
        />
      </div>
    </div>
  );
};

export default AnimalOwnership;
