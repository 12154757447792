export const ACCOUNT_CONSTANTS = Object.freeze({
  ACCOUNT_DEACTIVATE_SUBHEADER:
    'Account deactivation is available when inventory count is 0 and the account does not have ownership of an animal.',
  ACCOUNT_FARM_NAME: 'Account/Farm Name',
  ACCOUNT_INFO: 'Account Information',
  ACCOUNT_TYPE: 'Account Type',
  ACCOUNT: 'Account',
  ADD_ACCOUNT: 'Add Account',
  CREATE_ACCOUNT_SUBHEADER:
    'Your user profile is not associated with a storage account, please create a storage account to continue.',
  CREATE_ACCOUNT: 'Create Account',
  CREATE_STORAGE_ACCOUNT: 'Create Storage Account',
  DEACTIVATE_ACCOUNT: 'Deactivate account',
  DEACTIVATION: 'Deactivation',
  DUE_AMOUNT: 'Due Amount',
  DUE_PAYMENT_SUBHEADER: 'There is due payment for this account.',
  MARK_PAID: 'Mark As Paid',
  PENDING_ACH_SUBHEADER: 'There is a pending ACH payment transaction associated with this account.',
  SEARCH_ACCOUNT: 'Search Account',
  VERIFY_ACCOUNT: 'Verify Account',
  VERIFY_USER_EMAIL:
    'Please check your email and click the link to verify your email address in order to continue. If you did not receive a verification email or if your link expired, click the button below to send a new link.',
});

export const ADDRESS_CONSTANTS = Object.freeze({
  ADD_ADDRESS: 'Add Address',
  ADDRESS_INFO: 'Address Information',
  ADDRESS_LINE_1: 'Address Line 1',
  ADDRESS_LINE_2: 'Address Line 2',
  ADDRESS: 'Address',
  CITY: 'City',
  CONTACT_PHONE: 'Contact Phone',
  COUNTRY: 'Country',
  STATE_REGION: 'State / Region',
  STATE: 'State',
  ZIP_CODE: 'Zip Code',
});

export const ANIMAL_CONSTANTS = Object.freeze({
  ACCOUNT_BUSINESS_FARM: 'Account/Business/Farm',
  ADD_BREED_HEADER: 'Please complete the form to add a new breed.',
  ADD_BREED: 'Add Breed',
  ADD_NEW_ANIMAL: 'Add New Animal',
  ADD_OWNER: 'Add another owner',
  ADD_SPECIES_HEADER: 'Please complete the form to add a new species.',
  ADD_SPECIES: 'Add Species',
  ANIMAL_CODE: 'Animal Code',
  ANIMAL_INFO: 'Animal Information',
  ANIMAL_NAME: 'Animal Name',
  ANIMAL_OWNERSHIP_BREAKDOWN: 'Animal Ownership Breakdown',
  ANIMAL_OWNERSHIP_QUESTION: 'Do you want to add ownership to this animal?',
  ANIMAL_OWNERSHIP: 'Animal Ownership',
  ANIMAL: 'Animal',
  ANIMALS: 'Animals',
  BREED: 'Breed',
  BREEDS: 'Breeds',
  DAM: 'DAM',
  DATE_OF_BIRTH: 'Date of Birth',
  DESCRIPTION: 'Description',
  DNA: 'DNA',
  DOB: 'DOB',
  EDIT_BREED_HEADER: 'Please complete the form to update your breed.',
  EDIT_BREED: 'Edit Breed',
  EDIT_SPECIES_HEADER: 'Please complete the form to update your species.',
  EDIT_SPECIES: 'Edit Species',
  GENDER: 'Gender',
  NO_IMAGE: 'No Image Available',
  NO_OWNERSHIP: 'No Ownership Defined',
  OWNERSHIP_100_PERCENT: 'Ownership must add to 100%',
  OWNERSHIP_OVERVIEW_HEADER: 'Please specify each account and their animal ownership percentage.',
  OWNERSHIP_OVERVIEW: 'Ownership Overview',
  OWNERSHIP_PERCENTAGE: 'Ownership %',
  OWNERSHIP_REQUIRED: 'All owners must have a percentage',
  REGISTRATION: 'Registration',
  SIRE: 'Sire',
  SPECIES_NAME: 'Species Name',
  SPECIES: 'Species',
  STORAGE_LOCATIONS: 'Storage Location(s)',
});

export const BUTTON_CONSTANTS = Object.freeze({
  ACCEPT: 'Accept',
  ADD_ANIMAL: 'Add Animal',
  ADD_DATA_POINT: 'Add Another Data Point',
  ADD_INVENTORY: 'Add Inventory',
  ADD_LOCATION: 'Add another location',
  ADD_PAYMENT_METHOD: 'Add Payment Method',
  ADD_USER: 'Add User',
  ADD: 'Add',
  ADJUST: 'Adjust',
  APPLY: 'Apply',
  BACK: 'Back',
  CANCEL: 'Cancel',
  CHOOSE_FILES: 'Choose Files',
  CLOSE: 'Close',
  COMPLETE: 'Complete',
  CONFIRM: 'Confirm',
  CONTINUE: 'Continue',
  DECLINE: 'Decline',
  DELETE: 'Delete',
  DISCARD: 'Discard',
  EDIT: 'Edit',
  LOAD_MORE: 'Load More',
  LOT_INFO: 'Lot Info',
  MOVE: 'Move',
  NEXT_STEP: 'Next Step',
  OK: 'Ok',
  PAY_NOW: 'Pay now',
  PAY: 'Pay',
  PLACE_ORDER: 'Place Order',
  PRINT_AND_PROCESS: 'Print And Process',
  PRINT: 'Print',
  PROCEED_TO_CHECKOUT: 'Proceed to Checkout',
  PROCEED_TO_PAYMENT: 'Proceed to Payment',
  QUICK_VIEW: 'Quick View',
  RECONCILE: 'Reconcile',
  REMOVE: 'Remove',
  RESEND_LINK: 'Resend Link',
  RESEND: 'Resend',
  SAVE_AND_ADD_MORE: 'Save & Add More',
  SAVE_PAY: 'Save & Pay',
  SAVE: 'Save',
  SEE_TRANSACTIONS: 'See Transactions',
  SELL_TRANSFER: 'Sell / Transfer',
  SEND: 'Send',
  SIGN_IN: 'Sign In',
  SIGN_OUT: 'Sign Out',
  SIGN_UP: 'Sign Up',
  SUBMIT: 'Submit',
  SYNC: 'Sync',
  UPDATE_TOKEN: 'Update token',
  UPDATE: 'Update',
  USE: 'Use',
  VIEW_DETAIL: 'View Detail',
  VIEW_DETAILS: 'View Details',
  VIEW_EDIT: 'View / Edit',
  VIEW_MORE: 'View More',
  VIEW_PROFILE: 'View Profile',
  VIEW: 'View',
  WITHDRAW: 'Withdraw (Ship/Pick Up)',
  YES: 'Yes',
});

export const INVENTORY_CONSTANTS = Object.freeze({
  ACCOUNT_OWNERS: 'Accounts/Owners',
  ADD_INSURANCE_PROMPT: 'Would you like to add insurance to your shipment?',
  ADD_INVENTORY_NOTE:
    'In the Notes field, please include information about how you plan to send the specimen and an RSG representative will be in contact with you.',
  ADD_INVENTORY_SUBHEADER:
    'If you have specimen collected/held outside of RSG complete the deposit details below to initiate the transaction.',
  ANIMAL: 'Animal',
  COLLECTED_BY: 'Collected By',
  COLLECTION_SITE: 'Collection Site',
  FROZEN_BY: 'Frozen By',
  HAVE_SELL_CODE: 'Do you have a sell code?',
  HAVE_TANK: 'Do you need to rent a dry vapor shipper tank from RSG for this shipping order?',
  ITEM_DETAILS: 'Item Details',
  ITEM_OWNERSHIP_BREAKDOWN: 'Item Ownership Breakdown',
  ITEM_TYPE: 'Item Type',
  ITEM_UNIT_MEASURE: 'Item Unit of Measure',
  LOT_DATE: 'Lot Date',
  LOT_NUMBER: 'Lot Number',
  ORIGINAL_QUANTITY: 'Original Quantity',
  PROCESSED_BY: 'Processed By',
  PURCHASE_CONGRATS: 'Congratulations on your purchase, please enter the sell code below.',
  QUALITY: 'Quality',
});

export const INVENTORY_MANAGEMENT_CONSTANTS = Object.freeze({
  BULK_ORDER_ADD_MORE_FOOTER: 'Please click "save and add more" to save your progress and add additional animals to your order.',
  BULK_ORDER_NEXT_STEP_FOOTER: 'When your order is complete, please click "next step" to finalize your request.',
  COMMON_INVENTORY_FOOTER:
    'Click "Submit" to place your request. An email notification will be sent to you once your request has been successfully processed.',
  CONFIRM_OVERRIDE_QUESTION: 'Are you sure you want to check out more than the requested available quantity?',
  INSURANCE_AMOUNT_PROMPT:
    'How much would you like to insure your shipment for? The maximum amount allowed is $9,999.00. For amounts of $10,000 or more, please call 765.778.3000 to get a quote. ',
  INSURANCE_TERMS_ACCEPT: 'I have read and accept the RSG Insurance Terms and Conditions.',
  ON_SITE_SELL_QUESTION: 'Would you like to use now?',
  REVIEW_PURCHASE_INFO_SUBHEADER:
    'Below you will find your semen or embryo withdrawal information, please take a second to review and confirm the purchase details.',
  SELL_INVENTORY_FOOTER:
    'Clicking Submit will transfer these units to the buyers account immediately. This action cannot be cancelled.',
  USE_INVENTORY_HEADER:
    'Complete this form if you would like RSG to handle the deployment of these units at an RSG host site or RSG facility.',
  WITHDRAW_INVENTORY_HEADER:
    "To request a withdraw from your inventory, please use this form. Do not use the 'Withdraw' function if you want RSG to handle the deployment of these units at an RSG host site or facility - instead go to “Use”.",
  WITHDRAW_SHIPPING_NOTE:
    'Please note that delivery time may vary depending on the breeding season, normal delivery times can take anywhere from 1-2 weeks. Additionally, please note there is a cost associated with this option, you will see the grant total once you check out. Kindly provide the mailing address below.',
  WITHDRAW_TERMS_1: 'Frozen Genetics are shipped solely at the risk of the client.',
  WITHDRAW_TERMS_2: 'RSG does not accept any liability for semen or embryos lost, stolen, or damaged.',
  WITHDRAW_TERMS_3: 'Any insurance on the shipment is the responsibility of the client.',
  WITHDRAW_TERMS_ACCEPT: 'I have read and agree to the terms and conditions.',
});

export const LABEL_CONSTANTS = Object.freeze({
  ACCEPTED: 'Accepted',
  ACCOUNT_FARM_NAME: 'Account / Farm Name',
  ACCOUNT_HAS_NO_ANIMAL_OWNERSHIP: 'This Account does not have ownership interest on any animal.',
  ACCOUNT_ID: 'Account ID',
  ACCOUNT_LOCKED: 'Account is locked',
  ACCOUNT_NAME: 'Account Name',
  ACCOUNT_OWNER_ID: 'Account/Owner ID',
  ACCOUNT_OWNER: 'Account/Owner',
  ACCOUNT_OWNERSHIP: 'Account Ownership',
  ACCOUNT_UNLOCKED: 'Account is unlocked',
  ACCOUNTS: 'Accounts',
  ACQUIRED_INVENTORY: 'Recently Acquired Inventory',
  ACTIVE_INACTIVE: 'Active & Inactive',
  ACTIVE: 'Active',
  ADD_ACCOUNT_OWNERSHIP: 'Add Account Ownership',
  ADD_LOCATION: 'Add Location',
  ADD_PAYMENT_METHOD: 'Add Payment Method',
  ANIMAL_BREAKDOWN: 'Animal Breakdown',
  ANIMAL_INFO: 'Animal Info',
  BREED_DATE: 'Breed Date',
  BREED_LOCATION: 'Breed Location',
  BULK_TRANSACTION_ID: 'Bulk Transaction ID',
  BULK_TRANSACTION_LINK_ID: 'Bulk Transaction Link ID',
  BUYER_ACCOUNT: 'Buyer Account',
  BUYER_EMAIL: 'Buyer Email',
  BUYER_FIRST_NAME: 'Buyer First Name',
  BUYER_INFO: 'Buyer Information',
  BUYER_LAST_NAME: 'Buyer Last Name',
  BUYER_PHONE_NUMBER: 'Buyer Phone Number',
  CANISTER_INVENTORY_COUNT: 'Canister Inventory Count',
  CANISTER: 'Canister',
  CANISTERS: 'Canisters',
  CATALOG_QUANTITY: 'Catalog Quantity',
  CATALOG_REPORT: 'Catalog Report',
  CATALOG_SELL: 'Catalog Sell',
  CHECK_IN_WARNING: 'Check In Warning',
  CHECK_INTO: 'Check Into',
  CHOOSE_PAYMENT_MODE: 'Please choose how you’d like to proceed with payment',
  COLLECT: 'Collect',
  CONFIRM_OVERRIDE: 'Confirm Override of Available Qty',
  CONTACT_FIRST_NAME: 'Contact First Name',
  CONTACT_LAST_NAME: 'Contact Last Name',
  CONTACT_PHONE: 'Contact Phone',
  CURRENT_QTY: 'Current Quantity',
  DATA_POINT_SELECT: 'Custom Data Point Selection',
  DATA_TYPE: 'Data Type',
  DATE: 'Date',
  DEACTIVATED: 'Deactivated',
  DECLINED: 'Declined',
  DEPOSIT_DETAILS: 'Deposit Details',
  DEPOSIT: 'Deposit',
  DOWNLOAD: 'Download',
  DRAG_DROP_FILE: 'Drag and Drop file here',
  DRAG_DROP_FILES: 'Drag and Drop files here',
  DUE_PAYMENT: 'Due Payment',
  EDIT_PROFILE: 'Edit Profile',
  EMAIL_ID: 'Email Id',
  EMAIL: 'Email',
  EMBRYOS: 'Embryos',
  ENABLE: 'Enable',
  ENTER_NEW_CARD_DETAILS: 'Enter new card details',
  FILTER_BY: 'Filter By',
  FILTER: 'Filter',
  FIRST_NAME: 'First Name',
  INACTIVE: 'Inactive',
  INFORMATION: 'Information',
  INVENTORY_INTEGRITY: 'Inventory Integrity',
  INVENTORY_ITEMS: 'Inventory Items',
  INVENTORY_LOT_NUMBER: 'Inventory Lot Number',
  INVENTORY_TYPE: 'Inventory Type',
  INVENTORY: 'Inventory',
  ITEM_INFO: 'Item Info',
  ITEM_STORAGE_LOCATIONS: 'Item Storage Location(s)',
  LAST_NAME: 'Last Name',
  LAST_UPDATED_BY: 'Last Updated By',
  LAST_UPDATED: 'Last Updated',
  LOT_DATE_NUMBER: 'Lot Date / Number',
  MANAGE_YOUR_BILLING: 'Manage your Billing',
  MARK_AS_PAID: 'Mark as paid',
  MAX: 'Max',
  MIN: 'Min',
  MONTHLY_STORAGE_PAYMENT_HISTORY: 'Monthly Genetics Storage Payment History',
  MOVING_FROM: 'Moving From',
  MOVING_TO: 'Moving To',
  NEWEST_TO_OLDEST: 'Newest to Oldest',
  NO_ACCOUNTS_FOUND: 'No Accounts Found',
  NO_ANIMAL_OWNERSHIP: 'This Account does not have ownership interest on any animal.',
  NO_ASSOCIATED_TRANSACTIONS: 'No Associated Transactions',
  NO_HAVE_MY_OWN_TANK: 'No, I will send my own tank to RSG for shipping',
  NO_INVENTORY_FOUND: 'No Inventory Found',
  NO_INVENTORY: 'No Inventory Available',
  NO_ITEMS: 'No Items',
  NO_LINKED_STORAGE_MOVEMENTS: 'No Linked Storage Movements',
  NO_LINKED_TRANSACTIONS: 'No Linked Transactions',
  NO_LOCATION_FOUND: 'No Locations Found',
  NO_LOCATIONS_AVAILABLE: 'No Locations Available',
  NO_PAYMENT_DISCREPANCIES_FOUND: 'No Payment Discrepancies Found',
  NO_PENDING_TRANSACTIONS: 'No Pending Transactions',
  NO_RESULTS_FOUND: 'No results found',
  NO_SPECIMENS_AVAILABLE_FOR_CHECK_IN: 'No specimens available for check in.',
  NO_TRANSACTIONS_FOUND: 'No transactions found',
  NO: 'No',
  NOT_AVAILABLE: 'Not Available',
  NOTE: 'Note',
  NOTES: 'Notes',
  OLDEST_TO_NEWEST: 'Oldest to Newest',
  ORDER_SUMMARY: 'Order Summary',
  ORIGINAL_LOT_QTY: 'Original Lot Quantity',
  ORIGINATION: 'Origination',
  PAYMENT_HISTORY: 'Payment History',
  PAYMENT_METHOD: 'Payment Method',
  PENDING_DEPOSIT: 'Pending Deposit',
  PENDING_TRANSACTIONS: 'Pending Transactions',
  PENDING_USE_TRANSACTIONS: 'Pending Use Transactions',
  PHONE_NO: 'Phone Number',
  PICKUP: 'Pickup',
  PNG_JPG_JPEG: '(png, jpg, jpeg)',
  PURCHASE_DETAILS: 'Purchase Details',
  PURCHASE_QUANTITY: 'Purchase Quantity',
  QTY_IN_CANISTER: 'Quantity In Canister',
  QUANTITY_AVAILABLE: 'Quantity Available',
  QUANTITY_OWNED: 'Quantity Owned',
  QUANTITY_TO_DISCARD: 'Quantity to Discard',
  QUANTITY_TO_WITHDRAW: 'Quantity to Withdraw',
  QUANTITY: 'Quantity',
  RECONCILE: 'Reconcile',
  REQUIRED: 'Required',
  RESET_FILTERS: 'Reset Filters',
  RESOLUTION: 'Resolution',
  REVIEW_PURCHASE_INFO: 'Review Purchase Information',
  ROLE: 'Role',
  RSG_INSURANCE_TC: 'RSG Insurance Terms and Conditions',
  SEARCH_ANIMAL: 'Search Animal',
  SEARCH_BY: 'Search By',
  SEARCH_EMAIL: 'Search Email',
  SELECT_ACCOUNT: 'Select Account',
  SELECT_ITEM_QUANTITY: 'Select Item(s) and Quantity',
  SELECT_ITEM: 'Select Item',
  SELECT_ONE: 'Select One',
  SELECT_QTY: 'Select Quantity',
  SELECT_TRAVELING_CANISTER: 'Select Traveling Canister',
  SELECT_TYPE: 'Select Type',
  SELECTED_QTY_TO_USE: 'Selected Qty to Use',
  SELL_INFO: 'Sell Information',
  SELL_TO_NON_EXISTENT_ACCOUNT: 'Sell to Non-Existent Account',
  SELL_TYPE: 'Sell Type',
  SELLER_EMAIL: 'Seller Email',
  SELLER_INFO: 'Seller Information',
  SELLER_NAME: 'Seller Name',
  SELLER_PHONE_NUMBER: 'Seller Phone Number',
  SEMEN: 'Semen',
  SETUP_DEPOSIT: 'Setup Deposit',
  SHIP_TO_ADDRESS: 'Ship To Address',
  SHIPPING_INFO: 'Shipping Information',
  SHIPPING_INSURANCE_PAYMENT_HISTORY: 'Shipping Insurance Payment History',
  SHIPPING_INSURANCE: 'Shipping Insurance',
  SHOW_HIDE_PASSWORD: 'Show or Hide Password',
  SITE: 'Site',
  SITES: 'Sites',
  SORT_BY: 'Sort By',
  SPECIMEN_TYPE: 'Specimen Type',
  SPECIMENS: 'Specimen(s)',
  STATUS: 'Status',
  STORAGE_BREAKDOWN: 'Storage Breakdown',
  STORAGE_LOCATION: 'Storage Location',
  STORAGE_RATES: 'Storage Rates',
  STORAGE_TIER: 'Storage Tier',
  TANK_INVENTORY_COUNT: 'Tank Inventory Count',
  TANK: 'Tank',
  TANKS: 'Tanks',
  TEAM_MEMBERS: 'Team Members',
  TERMS_AND_CONDITIONS: 'Terms and Conditions',
  TOTAL_CHANGED_QUANTITY: 'Total Changed Quantity',
  TOTAL_QTY_TO_USE: 'Total Qty to Use',
  TOTAL_QUANTITY_SELECTED: 'Total Quantity Selected',
  TOTAL_QUANTITY: 'Total Quantity',
  TOTAL_UNITS: 'Total Units',
  TRANSACTION_ID_NOT_FOUND: 'Transaction ID not found',
  TRANSACTION_LINK_ID: 'Transaction Link ID',
  TRAVELING_TANK: 'Traveling Tank',
  TYPE: 'Type',
  UNITS_OWNED: 'Units Owned',
  UNITS: 'Units',
  UPDATE_PAYMENT_METHOD: 'Update Payment Method',
  UPLOAD_IMAGE: 'Upload Image',
  UPLOAD_IMAGES: 'Upload Images',
  USE_CARD_ON_FILE: 'Use card on file',
  USE_INFO: 'Use Information',
  USER_DETAILS: 'User Details',
  USER: 'User',
  USERS: 'Users',
  VARIANCE_DETECTED: 'Inventory Variance Detected',
  VARIANCE: 'Variance',
  VIEWING: 'Viewing',
  WITHDRAW_METHOD: 'Withdraw Method',
  YES_NEED_TO_RENT_TANK: 'Yes, I need to rent a tank from RSG',
  YES: 'Yes',
});

export const NAVIGATION_LINK_CONSTANTS = Object.freeze({
  ACCOUNT: 'Account',
  ADMIN_SETTINGS: 'Admin Settings',
  ANIMAL_MANAGEMENT: 'Animal Management',
  ANIMAL: 'Animal',
  APPLICATION_ADMIN: 'Application Admin',
  APPLICATION_TEAM: 'Application Team',
  CANISTER_CONTENTS: 'Canister Contents',
  CHECK_IN: 'Check In',
  CHECK_OUT: 'Check Out',
  COLLECT_DEPOSIT: 'Collect / Deposit',
  CUSTOM_DATA_POINTS: 'Custom Data Points',
  CUSTOMER_INFO: 'Customer Info',
  DASHBOARD: 'Dashboard',
  INSURED_CUSTOMERS: 'Insured Customers',
  INVENTORY_MANAGEMENT: 'Inventory Management',
  INVENTORY: 'Inventory',
  LOCATION_MANAGEMENT: 'Location Management',
  MOVE: 'Move',
  ON_SITE_SELL: 'On-Site Sell',
  RECONCILE: 'Reconcile',
  REPORTS: 'Reports',
  RSG_CLINIC_USE: 'RSG Clinic Use',
  SPECIES_BREEDS: 'Species & Breeds',
  TEAM_MANAGEMENT: 'Team Management',
  THRESHOLD: 'Threshold',
  TRANSACTION_HISTORY: 'Transaction History',
  TRANSACTION_MANAGEMENT: 'Transaction Management',
});

export const NOTIFICATION_CONSTANTS = Object.freeze({
  ACCOUNT_LOCKED:
    'Your account has been locked by the app admin. Please contact RSG Genetics Team to unlock your account at 765-978-0301.',
  ACH_PAYMENT_METHOD_NOTE:
    'Your banking details will undergo a final verification process when the first charge is initiated. Due to the nature of ACH transactions, there might be a delay before any issues with your details are identified. In the event of a grace period before billing starts, this could further extend the time until a failure notification is received. We appreciate your patience and understanding.',
  ACH_PAYMENT_NOT_ALLOWED:
    'We noticed your saved payment method is ACH, which can not be used for insured shipments. Please enter new card details to proceed. Need help? Call us at (765) 978-0301 and select option 4 for billing and invoice inquiries.',
  ADD_PAYMENT_DETAILS: 'Please add payment details to access all the features of this account.',
  CC_PROCESSING_FEE_MSG: 'On credit cards, 3% processing fee will be applied.',
  NO_INSURANCE_MESSAGE:
    'Your shipment will be sent with no insurance coverage. RSG is not liable for any damages or loss of genetic material.',
  PAY_NOW_EXISTING_FOOTER: 'Click on "Pay" to clear the outstanding balance.',
  PAY_NOW_NEW_FOOTER:
    'Please note that these details will only be used for a one-time payment to clear the outstanding balance and will not be saved. To save the payment details, please select the above checkbox.',
  PAY_NOW_SET_DEFAULT_METHOD_FOOTER: 'Click on "Save & Pay" to Update the details and clear the outstanding balance.',
  PENDING_ACH_PAYMENT: 'Your account has a past due balance. Access is restricted until the pending payment is processed.',
  SHIPPING_PAYMENT_INFO_SUBHEADER:
    'Please note that if you have opted to add insurance to your order, a credit card number is required to fulfill the order. For futher assistance, please call  (765) 978-0301 and select option 4 for billing and invoice inquiries. ',
});

export const PAGE_HEADER_CONSTANTS = Object.freeze({
  ADJUST_OWNERSHIP_QUANTITY: 'Adjust Ownership Quantity',
  ADMIN_SETTINGS: 'Admin Settings',
  ANIMAL_INVENTORY: 'Animal Inventory',
  CANISTER_CONTENTS: 'Canister Contents',
  CHECK_IN_INVENTORY: 'Check In Inventory',
  CHECK_OUT_INVENTORY: 'Check Out Inventory',
  CLINIC_USE: 'RSG Clinic Use',
  COLLECT_DEPOSIT_INVENTORY: 'Collect / Deposit Inventory',
  COMPLETE_DISCARD: 'Complete Discard',
  COMPLETE_WITHDRAW: 'Complete Withdraw',
  CREATE_CLIENT_TRANSACTION: 'Create Client Transaction',
  CREATE_WITHDRAW_TRANSACTION: 'Create Withdraw Transaction',
  CUSTOM_DATA_POINTS: 'Custom Data Points',
  CUSTOMER_INFO: 'Customer Info',
  DISCARD_INVENTORY: 'Discard Inventory',
  LOCATION_INFO: 'Location Info',
  MOVE_INVENTORY: 'Inventory Management Move',
  MY_INVENTORY: 'My Inventory',
  ON_SITE_SELL: 'On-Site Sell',
  RECONCILE_INVENTORY_OWNERSHIP: 'Reconcile Inventory & Ownership',
  RECONCILE: 'Inventory Discrepancies',
  SELL_INVENTORY: 'Sell / Transfer',
  SETTINGS: 'Settings',
  SPECIES_BREEDS: 'Species & Breeds',
  THRESHOLD: 'Threshold',
  TRANSACTION_CART: 'Transaction Cart',
  TRANSACTION_MANAGEMENT: 'Transaction Management',
  USE_INVENTORY: 'Use Inventory',
  VIEW_EDIT_TRANSACTION: 'View/Edit Transaction',
  VIEW_TRANSACTION: 'View Transaction',
  WITHDRAW_INVENTORY: 'Withdraw (Ship/Pick Up)',
});

export const PASSWORDS_CONSTANTS = Object.freeze({
  ADMIN_RESET_PASSWORD_EMAIL_GUIDE:
    "If you haven't received an email, please use the link provided to reset password for RSG's Genetics  Inventory Management Platform.",
  ADMIN_RESET_PASSWORD_GUIDE:
    'The Admins has the capability to generate a reset password link, aiding users in resetting their passwords when necessary. Kindly provide the email address of the user requiring a password reset. Clicking the "Reset Password" button will trigger an email to both the user and the admin initiating the password reset.',
  ADMIN_RESET_PASSWORD: 'Reset Password for Any User',
  CONFIRM_CODE_SUBHEADER: 'Please enter the code we sent to your email.',
  CONFIRM_CODE: 'Confirm Code',
  CREATE_PASSWORD: 'Create Password',
  DIDNT_GET_CODE: "Didn't get a code?",
  FORGOT_PASSWORD_SUBHEADER: "If your email exists, we'll send you an email to help you reset your password.",
  FORGOT_PASSWORD: 'Forgot Password?',
  RESET_PASSWORD_LINK: 'Reset password link',
  RESET_PASSWORD_SUBHEADER: 'Set a new password for your account.',
  RESET_PASSWORD: 'Reset Password',
  SET_PASSWORD_SUBHEADER: "Let's get started on setting a new password for your account",
});

export const PAYMENT_CONSTANTS = Object.freeze({
  ACCOUNT_DETAILS: 'Account Details',
  ACCOUNT_NAME: 'Account Name',
  ACCOUNT_NUMBER: 'Account Number',
  ACH_TRANSFER: 'ACH Transfer',
  ACH: 'ACH',
  AMOUNT: 'Amount',
  BILLING_ADDRESS: 'Billing Address',
  CARD_DETAILS: 'Card Details',
  CARD_NUMBER: 'Card Number',
  CARD: 'Card',
  CONFIRM_ACCOUNT_NUMBER: 'Confirm Account Number',
  CONFIRM_SKIP_PAYMENT_MESSAGE: 'Are you sure you want to skip the payment for this transaction?',
  CVV: 'CVV',
  DIRECT: 'Direct',
  EXISTING_METHOD: 'Existing Method',
  EXPIRY_DATE: 'Expiry Date',
  EXTERNAL: 'External',
  HANDLING_PROCESSING: 'Handling & Processing',
  INSURANCE_AMOUNT: 'Insurance Amount',
  INSURANCE_FEE: 'Insurance Fee',
  INSURANCE: 'Insurance',
  INVENTORY_DUE_AMOUNT: 'Inventory Due Amount',
  MM_YY: 'MM/YY',
  NAME_ON_CARD: 'Name on Card',
  NEW_METHOD: 'New Method',
  NO_INVOICE_PAYMENT_RECORD_IN_QB:
    'The system will not automatically generate an invoice or payment record for this transaction in QuickBooks. These must be manually added in QuickBooks.',
  PAYMENT_HISTORY: 'Payment History',
  PAYMENT_INFO: 'Payment Info',
  PAYMENT_INFORMATION: 'Payment Information',
  PAYMENT_METHOD: 'Payment Method',
  ROUTING_NUMBER: 'Routing Number',
  SET_DEFAULT_PAYMENT_METHOD: 'Set this as a default payment method',
  SHIPPING_COST: 'Shipping Cost',
  SKIP_PAYMENT_ASSUMED_COMPLETED: 'It is assumed that payment has already been completed outside this system.',
  SKIP_PAYMENT_NO_CARD_REQUIRED: 'No card details will be required.',
  SKIP_PAYMENT_NO_CHARGES_PROCESSED: 'The system will not process any charges for this transaction.',
  TANK_RENTAL_FEE: 'Tank Rental Fee',
  TOTAL_AMOUNT: 'Total Amount',
  UPDATE_INVENTORY_DUE: 'Update Inventory Due Amount',
  UPDATED_INVENTORY_DUE_AMOUNT: 'Updated Inventory Due Amount',
});

export const QUICKBOOKS_CONSTANTS = Object.freeze({
  PAYMENT_DISCREPANCIES: 'Payment Discrepancies',
  PAYMENT_DISCREPANCIES_GUIDE:
    'Review and resolve payment discrepancies between the Inventory app and QuickBooks. You can use the ‘Resync’ option to refresh data from QuickBooks or the ‘Reconcile’ option to manually adjust discrepancies and ensure both systems are aligned. Please carefully review discrepancies before making changes to avoid incorrect financial adjustments.',
  QUICKBOOKS: 'QuickBooks',
  UPDATE_TOKEN_HEADER: 'Update QuickBooks Refresh Token',
});

export const RSG_CONSTANTS = Object.freeze({
  PRIVACY_POLICY: 'Privacy Policy',
  RSG_SIGN_IN_FOOTER: "Don't have an account yet? Let's get you started today",
  RSG_SIGN_IN_SUBHEADER: 'The premier online platform for managing your sheep and goat frozen genetics.',
  RSG_SIGN_UP_FOOTER: "Already have an account? Let's proceed to",
  RSG_SIGN_UP_SUBHEADER:
    'The complete reproductive and wellness solutions service that enhances your sheep and goat breeding business.',
  RSG_STORAGE_RATES: "RSG's Storage Rates",
  RSG_TITLE: 'RSG Client System',
  RSG_WELCOME: 'Welcome to RSG',
  TERMS_OF_USE: 'Terms of Use',
});

export const STORAGE_PLAN_CONSTANTS = Object.freeze({
  FLAT_RATE: 'Flat Rate',
  FOR_6_MONTHS: 'For 6 Months',
  PER_MONTH: 'Per Month',
  PER_UNIT: 'Per Unit',
  STORAGE_RATES_SUBHEADER:
    'Storage costs are calculated monthly based on inventory count. As your inventory grows and shrinks, the system will automatically adjust your rate.',
  STORAGE_RATES: 'Storage Rates',
});

export const TABLE_HEADER_CONSTANTS = Object.freeze({
  ACCOUNT_BUSINESS_FARM: 'Account / Business / Farm',
  ACCOUNT_OWNER: 'Account / Owner',
  ACCOUNT: 'Account',
  ACQUIRED: 'Acquired',
  ACTION_ITEM: 'Action Item',
  ACTION: 'Action',
  ACTIONS: 'Actions',
  ACTUAL_QTY: 'Actual Qty',
  ACTUAL: 'Actual',
  ADDED_QTY: 'Added Qty',
  ADJUSTED_QTY: 'Adjusted Qty',
  AMOUNT: 'Amount',
  ANIMAL_CODE_NAME: 'Animal Code / Name',
  ANIMAL_CODE: 'Animal Code',
  ANIMAL_ID: 'Animal Id',
  ANIMAL_NAME: 'Animal Name',
  ANIMAL_OWNERSHIP_PER: 'Animal Ownership %',
  ANIMAL: 'Animal',
  AVAILABLE_QTY: 'Available Qty',
  AVERAGE_DAILY_BALANCE: 'Average Daily Balance',
  BEGINNING_QTY: 'Beginning Qty',
  BILL_DATE: 'Bill Date',
  CANISTER_QTY: 'Canister Qty',
  CATALOG_QTY: 'Catalog Qty',
  CHANGED_QTY: 'Changed Qty',
  CHANGED_QUANTITY: 'Changed Quantity',
  CHECK_IN_QTY: 'Check In Qty',
  CURRENT_QTY: 'Current Quantity',
  DATE: 'Date',
  DECREASE: 'Decrease',
  DIFFERENCE: 'Difference',
  EMAIL_ADDRESS: 'Email Address',
  EXPECTED: 'Expected',
  FREEZE_RECEIVE_DATE: 'Freeze/Receive Date',
  GENDER: 'Gender',
  ID: 'ID',
  INCREASE: 'Increase',
  INVENTORY_DUE_AMOUNT: 'Inventory Due Amount',
  INVENTORY_LOT_NUMBER: 'Inventory Lot Number',
  INVENTORY_TYPE: 'Inventory Type',
  ITEM_TYPE: 'Item Type',
  ITEM: 'Item',
  ITEMS: 'Items',
  LEDGER_QTY: 'Ledger Qty',
  LINK_ID: 'Link ID',
  LOCATION: 'Location',
  LOT_DATE_NO: 'Lot Date / Number',
  LOT_NO: 'Lot Number',
  NAME: 'Name',
  ORIGINAL_QTY: 'Original Qty',
  ORIGINAL_QUANTITY: 'Original Quantity',
  ORIGINAL: 'Original',
  OWNED_QTY: 'Owned Qty',
  OWNED: 'Owned',
  OWNERSHIP_PERCENTAGE: 'Ownership %',
  PAYMENT_METHOD: 'Payment Method',
  PURCHASE_DATE: 'Purchase Date',
  QB_DUE_AMOUNT: 'QB Due Amount',
  QTY_IN_CANISTER: 'Qty In Canister',
  QTY_TO_WITHDRAW: 'Qty to Withdraw',
  QUALITY_PERCENTAGE: 'Quality %',
  QUALITY: 'Quality',
  QUANTITY_OWNED: 'Quantity Owned',
  QUANTITY: 'Quantity',
  REFERENCE_ID: 'Reference Id',
  REMOVED_QTY: 'Removed Qty',
  REMOVED: 'Removed',
  RESULTING_QTY: 'Resulting Qty',
  ROLE: 'Role',
  SELECT: 'Select',
  SELLER: 'Seller',
  SOLD_QTY: 'Sold Qty',
  SPECIES_BREED: 'Species-Breed',
  STATUS: 'Status',
  THRESHOLD: 'Threshold',
  TOTAL_CATALOG_QTY: 'Total Catalog Qty',
  TOTAL_QTY: 'Total Qty',
  TRANSACTION_ID: 'Transaction ID',
  TRANSACTION_TYPE: 'Transaction Type',
  TYPE: 'Type',
  UNIT_OF_MEASURE: 'Unit Of Measure',
  UNUSABLE_QTY: 'Unusable Qty',
  USE_QTY: 'Use Qty',
  USED_QTY: 'Used Qty',
  WITHDRAW_QTY: 'Withdraw Qty',
});

export const TOOLTIP_MESSAGE_CONSTANTS = Object.freeze({
  ADJUST_TOOLTIP_MSG:
    'This transaction, in addition to notes entered below, provides context to why the adjustment is being made.',
  INSURANCE_FEE_TOOLTIP: '5% of the insurance amount.',
});

export const TRANSACTION_CONSTANTS = Object.freeze({
  ASSOCIATED_TRANSACTIONS: 'Associated Transactions',
  BULK_TRANSACTIONS: 'Bulk Transactions',
  LINKED_STORAGE_MOVEMENTS: 'Linked Storage Movements',
  LINKED_TRANSACTIONS: 'Linked Transactions',
  TRANSACTION_DATE: 'Transaction Date',
  TRANSACTION_ID: 'Transaction ID',
  TRANSACTION_INFORMATION: 'Transaction Information',
  TRANSACTION_LINK_ID: 'Transaction Link ID',
  TRANSACTION_NOTES: 'Transaction Notes',
  TRANSACTION_TYPE: 'Transaction Type',
  TRANSACTIONS: 'Transactions',
});

export const VALIDATION_ALERT_CONSTANTS = Object.freeze({
  ACCOUNT_FOUND: 'The account has been found. Please choose the account to complete the sale.',
  INVALID_CODE: 'Invalid Code',
  MAX_GREATER_THAN_MIN: 'Max value must be greater than min value',
  MIN_LESS_THAN_MAX: 'Min value must be less than max value',
  NO_ACCOUNT_FOUND:
    'No account has been found, or the user is not an authorized buyer on an account. Please provide additional information below to invite the user to create a storage account and complete the sale.',
  RECONCILE_INVENTORY_EXPECTED:
    'Original quantity minus used/removed quantity must be equal to the currently stored quantity in the system.',
  RECONCILE_INVENTORY_OWNED: 'Owned quantity must be equal to the currently stored quantity in the system.',
  REQUIRED_FIELD: 'This field is required.',
  SITE_TANK_CANISTER_REQUIRED: 'Site, tank, and canister are required',
  STORAGE_QTY_GREATER_THAN_0: 'Storage location quantity must be greater than 0',
  STORAGE_QTY_MUST_EQUAL: 'Storage location quantity must equal',
  UNAUTHORIZED_CATALOG_SELL:
    'Unauthorized: You do not have permission to sell to this user. Kindly provide the different email address.',
  UNIQUE_ANIMAL_CODE: 'Animal Code must be unique',
  WITHDRAW_QTY_EXCEEDED: 'Total quantity selected for the specimen is more than the quantity to withdraw.',
});

export const URL_CONSTANTS = Object.freeze({
  TERMS_OF_USE_URL:
    'https://reprospecialty.com/wp-content/uploads/2023/10/2023-Semen-and-Embryo-Storage-Agreement-Terms-and-Conditions-for-RSGinventory.pdf',
  PRIVACY_POLICY_URL: 'https://reprospecialty.com/wp-content/uploads/2023/10/PRIVACY-POLICY-for-RSGinventory.pdf',
});

export const REDIRECTING = 'Redirecting...';
export const RETURN_TO_SIGN_IN = 'Return To Sign In';
export const STAY_SIGNED_IN = 'Stay Signed In';
