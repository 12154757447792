import { useState } from 'react';
import ResetPassword from './ResetPassword';
import PaymentDiscrepancies from './PaymentDiscrepancies';
import { PAGE_HEADER_CONSTANTS, PASSWORDS_CONSTANTS, QUICKBOOKS_CONSTANTS } from '../../constants/common';
import './adminSettings.scss';

const AdminSettings = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="admin-settings-page">
      <div className="admin-settings card">
        <div className="admin-settings__header">
          <h2>{PAGE_HEADER_CONSTANTS.ADMIN_SETTINGS}</h2>
          <ul className="admin-settings__tabs">
            <li className={activeTab === 0 ? 'active' : ''} onClick={() => setActiveTab(0)}>
              <div className="reset-password">{PASSWORDS_CONSTANTS.RESET_PASSWORD}</div>
            </li>
            <li className={activeTab === 1 ? 'active' : ''} onClick={() => setActiveTab(1)}>
              <div className="quick-books">{QUICKBOOKS_CONSTANTS.QUICKBOOKS}</div>
            </li>
          </ul>
        </div>
        <div className="admin-settings__body">
          {activeTab === 0 && <ResetPassword />}
          {activeTab === 1 && <PaymentDiscrepancies />}
        </div>
      </div>
    </div>
  );
};

export default AdminSettings;
