import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { DropDownSVG, ReportsSVG } from '../../../svgs';
import { redirectToTopOfThePage } from '../../../../utils/commonUtils';
import { APP_PATHS, REPORT_PATHS } from '../../../../constants/routePaths';
import { NAVIGATION_LINK_CONSTANTS } from '../../../../constants/common';

interface ReportLinksProps {
  currentRoute: string[];
  showReportsPages: boolean;
  setShowReportsPages: React.Dispatch<React.SetStateAction<boolean>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenNavBar: React.Dispatch<React.SetStateAction<boolean>>;
}
const ReportLinks: FC<ReportLinksProps> = ({ currentRoute, showReportsPages, setShowReportsPages, setOpen, setOpenNavBar }) => {
  const activeLinkClass = (path: string) => {
    return currentRoute.includes(path) ? 'nav-link active' : 'nav-link';
  };

  const activeSubLinkClass = (path: string) => {
    return currentRoute.includes(path) ? 'nav-sub-link active' : 'nav-sub-link';
  };

  const closeSideBar = () => {
    setOpen(false);
    setOpenNavBar(false);
    redirectToTopOfThePage();
  };

  return (
    <div>
      <button type="button" className={activeLinkClass(APP_PATHS.REPORTS)} onClick={() => setShowReportsPages(!showReportsPages)}>
        <ReportsSVG />
        {NAVIGATION_LINK_CONSTANTS.REPORTS}
        <DropDownSVG reversed={showReportsPages} />
      </button>
      {showReportsPages && (
        <div>
          <Link
            className={activeSubLinkClass(REPORT_PATHS.INSURED_CUSTOMERS)}
            to={APP_PATHS.REPORTS_ + REPORT_PATHS.INSURED_CUSTOMERS}
            onClick={closeSideBar}>
            {NAVIGATION_LINK_CONSTANTS.INSURED_CUSTOMERS}
          </Link>
        </div>
      )}
    </div>
  );
};

export default ReportLinks;
