import { useState } from 'react';
import { Loader, Modal } from '../../components';
import ValidatedInput, { ValidatedState } from '../../components/custom-input/ValidatedInput';
import { Validators } from '../../types/enums';
import { QBOpenBalanceDiscrepancy } from '../../types/interfaces/quickbooks.interface';
import { updateAccountOutstandingBalance } from '../../api/accountApi';
import { syncQuickBooksByAccountId, updateQuickBooksSyncHistory } from '../../api/quickbooksApi';
import { centsToDollars, dollarsToCents } from '../../services/account.service';
import { showToast } from '../../services/toast.service';
import { BUTTON_CONSTANTS, LABEL_CONSTANTS, PAYMENT_CONSTANTS } from '../../constants/common';
import { toastMessages } from '../../constants/errorMessages';

export type MarkAsPaidModalProps = {
  isOpen: boolean;
  width?: number;
  setIsOpen: (e: boolean) => void;
  onConfirm: () => void;
  selectedDiscrepancyItem: QBOpenBalanceDiscrepancy | undefined;
};

const UpdateOutstandingModal = ({ isOpen, width = 600, setIsOpen, onConfirm, selectedDiscrepancyItem }: MarkAsPaidModalProps) => {
  const [notes, setNotes] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [updatedDueAmount, setUpdatedDueAmount] = useState<ValidatedState>({ value: '', valid: false });
  const [validate, setValidate] = useState<boolean>(false);

  const handleClose = () => {
    setNotes('');
    setUpdatedDueAmount({ value: '', valid: false });
    setIsOpen(false);
    setValidate(false);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    setValidate(true);

    if (notes?.trim() && updatedDueAmount?.valid && selectedDiscrepancyItem) {
      const { account, accountId, inventoryAppOutstandingBalanceCents } = selectedDiscrepancyItem;
      const updatedBalanceCents = dollarsToCents(Number(updatedDueAmount.value));
      try {
        const outstandingBalancePayload = { ...account, outstandingBalanceCents: updatedBalanceCents };
        const syncHistoryPayload = {
          accountId: accountId,
          previousOutstandingBalanceCents: inventoryAppOutstandingBalanceCents,
          newOutstandingBalanceCents: updatedBalanceCents,
          notes: notes,
        };

        await updateAccountOutstandingBalance(accountId, outstandingBalancePayload);
        await syncQuickBooksByAccountId(accountId);
        await updateQuickBooksSyncHistory(syncHistoryPayload);

        showToast.success(toastMessages.OUTSTANDING_BALANCE_UPDATE_SUCCESS);
      } catch (error: any) {
        showToast.error(toastMessages.OUTSTANDING_BALANCE_UPDATE_FAIL);
      } finally {
        handleClose();
        onConfirm();
      }
    }
    setLoading(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} width={width} ignoreBackdrop>
      <div className="mark-as-paid-modal update-outstanding">
        <div className="header">
          <h4>{PAYMENT_CONSTANTS.UPDATE_INVENTORY_DUE}</h4>
        </div>
        <div className="body">
          <div className="body-content">
            <div className="row">
              <div className="l-column">{PAYMENT_CONSTANTS.ACCOUNT_NAME}: </div>
              <div className="r-column">
                <b>{selectedDiscrepancyItem?.account?.name}</b>
              </div>
            </div>
            <div className="row">
              <div className="l-column">{PAYMENT_CONSTANTS.INVENTORY_DUE_AMOUNT} : </div>
              <div className="r-column">
                <b>{`$${centsToDollars(selectedDiscrepancyItem?.inventoryAppOutstandingBalanceCents)}`}</b>
              </div>
            </div>
            <div className="row">
              <div className="l-column">{`*${PAYMENT_CONSTANTS.UPDATED_INVENTORY_DUE_AMOUNT} ($)`} : </div>
              <div className="r-column">
                <ValidatedInput
                  useId="Amount"
                  label={``}
                  type="number"
                  validate={validate}
                  setValidatedState={setUpdatedDueAmount}
                  validators={[Validators.REQUIRED]}
                  validatedStateForAutoFill={updatedDueAmount}
                  allowFraction
                  preventNegative
                  decimalPlaces={2}
                />
              </div>
            </div>
            {loading && <Loader loaderSize={'small'} />}
            <div className="row">
              <div className="l-column">{`*${LABEL_CONSTANTS.NOTES} :`}</div>
              <div className="r-column">
                <textarea
                  value={notes}
                  placeholder={LABEL_CONSTANTS.NOTES}
                  id="inventory-notes-textarea"
                  onChange={e => setNotes(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <button className="button blue inverted small" onClick={handleClose} disabled={loading}>
            {BUTTON_CONSTANTS.CANCEL}
          </button>
          <button
            className={
              !notes?.trim() || loading || !updatedDueAmount?.valid ? 'button green small disabled' : 'button green small'
            }
            onClick={handleSubmit}>
            {BUTTON_CONSTANTS.SUBMIT}
          </button>
        </div>
      </div>
    </Modal>
  );
};
export default UpdateOutstandingModal;
