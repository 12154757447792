import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { store } from '../../redux/store';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { clearActiveAccount } from '../../redux/reducers/accountSlice';
import { clearAuthAccountContext } from '../../redux/reducers/authSlice';
import { storePaymentStatus } from '../../redux/reducers/paymentGatewaySlice';
import { updateUserAccounts } from '../../redux/reducers/userSlice';
import AddAccountModal from '../../pages/account-management/add-account-modal/AddAccountModal';
import SignOutModal from '../sign-out-modal/SignOutModal';
import VerifyUserModal from '../verify-user-modal/VerifyUserModal';
import AddPaymentMethodModal, { initialPaymentState } from '../payment-method-modal/AddPaymentMethodModal';
import AccountCard from '../account-card/AccountCard';
import AccountNav from './AccountNav/AccountNav';
import AccountSettingsNav from './account-settings-nav/AccountSettingsNav';
import AdminNav from './adminNav/AdminNav';
import { TabMenuCloseSvg } from '../svgs';
import NavBarTabletView from './NavBarTablet';
import { fetchTransactionCartItems } from '../../services/transactionCart.service';
import { PermissionService } from '../../services/permission.service';
import { requestUserPersonalInfo } from '../../services/user.service';
import { setActiveAccount } from '../../services/account.service';
import { showToast } from '../../services/toast.service';
import { getAccountById } from '../../api/accountApi';
import { getSubscriptionStatus } from '../../api/paymentGatewayApi';
import { AccountRoles, AddressType } from '../../types/enums';
import { PaymentMethod } from '../../types/interfaces/paymentMethod.interface';
import { ACCOUNT_PATHS, ROUTE_PATHS } from '../../constants/routePaths';
import { localStorageConstants } from '../../constants/localStorage.constants';
import { toastMessages } from '../../constants/errorMessages';
import { LABEL_CONSTANTS } from '../../constants/common';

type NavBarProps = {
  open: boolean;
  permissionService: PermissionService;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const NavBar: FC<NavBarProps> = ({ permissionService, setLoading, open, setOpen }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const currentRouteLocation = location.pathname.split('/');
  const appPage = currentRouteLocation[2];

  const user = useAppSelector(state => state.user);
  const activeAccountId = useAppSelector(state => state.activeAccount.accountId);
  const activeAccount = useAppSelector(state => state.activeAccount);

  const [accountId, setAccountId] = useState<number>();
  const [addAccountModalVisible, setAddAccountModalVisible] = useState(user.enabled === false);
  const [addPaymentMethodModalVisible, setAddPaymentMethodModalVisible] = useState(false);
  const [openNavBar, setOpenNavBar] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState<PaymentMethod>(initialPaymentState);
  const [showAccountNav, setShowAccountNav] = useState<boolean>(Object.values(ACCOUNT_PATHS).includes(appPage));
  const [showAdminNav, setShowAdminNav] = useState<boolean>(!Object.values(ACCOUNT_PATHS).includes(appPage));
  const [showAnimalManagementOptions, setShowAnimalManagementOptions] = useState<boolean>(false);
  const [showInventoryManagementOptions, setShowInventoryManagementOptions] = useState<boolean>(false);
  const [showReportOptions, setShowReportOptions] = useState<boolean>(false);
  const [storagePlansModalOpen, setStoragePlansModalOpen] = useState(false);

  const handlePaymentDetails = async () => {
    if (accountId) {
      try {
        const { data: isUserSubscribed } = await getSubscriptionStatus(accountId);

        if (isUserSubscribed || !activeAccount.billingEnabled) {
          dispatch(storePaymentStatus(true));
          setAddPaymentMethodModalVisible(false);
        } else {
          dispatch(storePaymentStatus(false));
          try {
            const { data: accountResponse } = await getAccountById(accountId, { include: 'Users.Roles' });

            accountResponse.users.find(accountUser => {
              if (accountUser.userId === user.userId) {
                accountUser.roles.find(role => {
                  if (role.name === AccountRoles.AccountOwner || role.name === AccountRoles.AccountAdmin) {
                    if (user.verified && user.enabled && user.accounts.length > 0) {
                      addBillingAddress();
                      setAddPaymentMethodModalVisible(true);
                    }
                  }
                });
              }
            });
          } catch {
            showToast.error(toastMessages.SOMETHING_WENT_WRONG);
          }
        }
      } catch {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      }
    }
  };

  const addBillingAddress = () => {
    const accountHasAddresses = () => activeAccount && activeAccount.addresses && activeAccount.addresses.length > 0;

    if (accountHasAddresses()) {
      const billing = activeAccount.addresses!.find(address => address.type === AddressType.BILLING);

      if (billing) {
        setPaymentInfo(paymentInfo => {
          return {
            ...paymentInfo,
            address: { value: billing.streetAddress, valid: billing.streetAddress.length > 0 ? true : false },
            city: { value: billing.city, valid: billing.city.length > 0 ? true : false },
            region: { value: billing.state, valid: billing.state.length > 0 ? true : false },
            postalCode: { value: billing.postalCode, valid: billing.postalCode.length > 0 ? true : false },
            country: { value: billing.country, valid: billing.country.length > 0 ? true : false },
          };
        });
      }
    }
  };

  useEffect(() => {
    activeAccountId && setAccountId(activeAccountId);
  }, [activeAccountId]);

  useEffect(() => {
    handlePaymentDetails();
  }, [accountId, activeAccount.addresses]);

  useEffect(() => {
    if (permissionService.userHasRSGAdminPermission() && user.userId) {
      fetchTransactionCartItems(user.userId, undefined, permissionService.userHasRSGAdminPermission());
    } else if (permissionService.userHasInventoryPermission() && user.userId && accountId != undefined) {
      fetchTransactionCartItems(user.userId, accountId);
    }
  }, [accountId, permissionService]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const handleWindowSizeChange = () => {
    if (window.innerWidth < 767 || window.innerWidth > 1024) {
      setOpenNavBar(false);
    } else if (window.innerWidth > 767) {
      setOpen(false);
    }
  };

  const cancelPaymentDetails = () => {
    setAccountId(activeAccountId);
    navigate(ROUTE_PATHS.APP_DASHBOARD);
    setShowAccountNav(true);
    setShowAdminNav(false);
    setAddPaymentMethodModalVisible(false);
  };

  useEffect(() => {
    activeAccountId && switchAccountIfDeactivated(activeAccountId);
  }, [currentRouteLocation]);

  const switchAccountIfDeactivated = async (activeAccountId: any) => {
    try {
      const { data: account } = await getAccountById(activeAccountId);
      if (account.enabled === false) {
        const accounts = user.accounts.filter(item => item.accountId !== activeAccountId);

        if (accounts.length > 0) {
          await setActiveAccount(accounts[0].accountId);
          await requestUserPersonalInfo();
          navigate(ROUTE_PATHS.APP_DASHBOARD);
        } else {
          sessionStorage.removeItem(localStorageConstants.ACTIVE_ACCOUNT_ID);
          store.dispatch(updateUserAccounts({ accounts }));
          store.dispatch(clearActiveAccount());
          store.dispatch(clearAuthAccountContext());
          navigate(ROUTE_PATHS.APP_USER_PROFILE);
        }
        showToast.info(account.name + ' ' + toastMessages.DEACTIVATED_ACCOUNT);
      }
    } catch (err: any) {
      throw err;
    }
  };

  return (
    <>
      {!openNavBar && (
        <div className="tablet-nav-bar">
          <NavBarTabletView
            permissionService={permissionService}
            setOpenNavBar={setOpenNavBar}
            setShowAccountNav={setShowAccountNav}
            setShowAdminNav={setShowAdminNav}
            setShowAnimalManagementOptions={setShowAnimalManagementOptions}
            setShowInventoryManagementOptions={setShowInventoryManagementOptions}
            setShowReportOptions={setShowReportOptions}
            currentRoute={currentRouteLocation}
          />
        </div>
      )}
      <VerifyUserModal isOpen={user.verified === false} userId={user.userId} />
      {(addAccountModalVisible || storagePlansModalOpen) && (
        <AddAccountModal
          isOpen={addAccountModalVisible}
          setIsOpen={setAddAccountModalVisible}
          storagePlansModalOpen={storagePlansModalOpen}
          setStoragePlansModalOpen={setStoragePlansModalOpen}
          isActiveUser={user.enabled === true}
          setAddPaymentMethodModalVisible={setAddPaymentMethodModalVisible}
          setAccountId={setAccountId}
        />
      )}
      {accountId && addPaymentMethodModalVisible && (
        <AddPaymentMethodModal
          title={LABEL_CONSTANTS.ADD_PAYMENT_METHOD}
          accountId={accountId}
          paymentDetails={paymentInfo}
          isOpen={addPaymentMethodModalVisible}
          setIsOpen={setAddPaymentMethodModalVisible}
          onclick={cancelPaymentDetails}
          buttonAction={'AddPaymentInfo'}
        />
      )}
      <div className={open || openNavBar ? 'nav-bar' : 'nav-bar hide'}>
        {openNavBar && (
          <button className="tab-menu-button" type="button" onClick={() => setOpenNavBar(false)}>
            <TabMenuCloseSvg />
          </button>
        )}
        <AccountCard
          user={user}
          onClick={() => {}}
          setLoading={setLoading}
          setAddAccountModalVisible={setAddAccountModalVisible}
          setShowAccountNav={setShowAccountNav}
          setShowAdminNav={setShowAdminNav}
          setOpen={setOpen}
          setOpenNavBar={setOpenNavBar}
        />

        <>
          {permissionService.userHasRSGAdminPermission() && (
            <AdminNav
              currentRoute={currentRouteLocation}
              accounts={user.accounts}
              permissionService={permissionService}
              showAdminNav={showAdminNav}
              setShowAdminNav={setShowAdminNav}
              showAccountNav={showAccountNav}
              setShowAccountNav={setShowAccountNav}
              showAnimalManagementOptions={showAnimalManagementOptions}
              setShowAnimalManagementOptions={setShowAnimalManagementOptions}
              showInventoryManagementOptions={showInventoryManagementOptions}
              setShowInventoryManagementOptions={setShowInventoryManagementOptions}
              showReportOptions={showReportOptions}
              setShowReportOptions={setShowReportOptions}
              setOpen={setOpen}
              setOpenNavBar={setOpenNavBar}
            />
          )}
        </>

        {!permissionService.userHasRSGAdminPermission() && (
          <>
            {user.accounts.length > 0 && (
              <AccountNav
                currentRoute={currentRouteLocation}
                permissionService={permissionService}
                setOpen={setOpen}
                setOpenNavBar={setOpenNavBar}
              />
            )}
          </>
        )}

        <hr />
        {user.accounts.length > 0 && (
          <AccountSettingsNav
            currentRoute={currentRouteLocation}
            permissionService={permissionService}
            setOpen={setOpen}
            setOpenNavBar={setOpenNavBar}
          />
        )}
        <SignOutModal />
      </div>
    </>
  );
};

export default NavBar;
