import { Modal } from '../../../components';
import TransactionLabelField from '../../../components/transaction/TransactionLabelField';
import { BUTTON_CONSTANTS } from '../../../constants/common';

export type InsuredCustomerDetailsModalProps = {
  isOpen: boolean;
  width?: number;
  onClose: () => void;
};

const InsuredCustomerDetailsModal = ({ isOpen, width = 600, onClose }: InsuredCustomerDetailsModalProps) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} width={width} ignoreBackdrop>
        <div className="insured-customer-details-modal">
          <div className="inventory-action card">
            <h2>{'Customer Insurance details'}</h2>
            <TransactionLabelField label={`Client Name:`} value={<label>{'Jack Sparrow'}</label>} />
            <TransactionLabelField label={`Date Submitted:`} value={<label>{`${new Date().toLocaleDateString()}`}</label>} />
            <TransactionLabelField label={`Address:`} value={<label>{'Old town road, Bangalore, Karnataka, IND 56086'}</label>} />
            <TransactionLabelField label={`Email:`} value={<label>{'nikhil.sudarshanam71@yopmail.com'}</label>} />
            <TransactionLabelField label={`Phone Number:`} value={<label>{'7411116889'}</label>} />
            <TransactionLabelField label={`Purchase Amount of Policy:`} value={<label>{'No Policy'}</label>} />
            <TransactionLabelField label={`Insurance Premium:`} value={<label>{'$4000'}</label>} />
            <TransactionLabelField label={`Insurance Processing Fee:`} value={<label>{'$80'}</label>} />
            <TransactionLabelField label={`Tracking Number:`} value={<label>{'1RSG253INS'}</label>} />
            <TransactionLabelField label={`Case Number on Package:`} value={<label>{'823482'}</label>} />
          </div>
          <div className="flex-right">
            <button className="button medium green close-button" onClick={() => onClose()}>
              {BUTTON_CONSTANTS.CLOSE}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default InsuredCustomerDetailsModal;
