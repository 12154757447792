import { FC, useEffect, useState } from 'react';
import ValidatedInput, { ValidatedState } from './ValidatedInput';
import { ValidateAddress } from '../../pages/transaction-cart/TransactionCartWithdraw';
import { Validators } from '../../types/enums';
import { ADDRESS_CONSTANTS } from '../../constants/common';

interface ValidatedAddressProps {
  validate: boolean;
  setValid: (valid: boolean) => void;
  address: ValidateAddress | undefined;
  setAddress: (address: ValidateAddress) => void;
}

const ValidatedAddress: FC<ValidatedAddressProps> = ({ validate, setValid, address, setAddress }): JSX.Element => {
  const initialFormState = { value: '', valid: false };

  const [address1Input, setAddress1Input] = useState<ValidatedState>(address?.streetAddress ?? initialFormState);
  const [address2Input, setAddress2Input] = useState<ValidatedState>(address?.streetAddress2 ?? initialFormState);
  const [cityInput, setCityInput] = useState<ValidatedState>(address?.city ?? initialFormState);
  const [stateInput, setStateInput] = useState<ValidatedState>(address?.state ?? initialFormState);
  const [zipcodeInput, setZipcodeInput] = useState<ValidatedState>(address?.postalCode ?? initialFormState);
  const [countryInput, setCountryInput] = useState<ValidatedState>(address?.country ?? initialFormState);
  const [phoneInput, setPhoneInput] = useState<ValidatedState>(address?.phone ?? initialFormState);

  useEffect(() => {
    setValid(
      address1Input.valid && cityInput.valid && stateInput.valid && zipcodeInput.valid && countryInput.valid && phoneInput.valid,
    );
    setAddress({
      streetAddress: address1Input,
      streetAddress2: address2Input,
      city: cityInput,
      state: stateInput,
      postalCode: zipcodeInput,
      country: countryInput,
      phone: phoneInput,
    });
  }, [address1Input, address2Input, cityInput, stateInput, zipcodeInput, countryInput, phoneInput]);

  return (
    <div>
      <div className="form-row">
        <ValidatedInput
          useId="address-1"
          label={`*${ADDRESS_CONSTANTS.ADDRESS_LINE_1}:`}
          type="text"
          validate={validate}
          setValidatedState={setAddress1Input}
          validators={[Validators.REQUIRED]}
          validatedStateForAutoFill={address1Input}
        />
      </div>
      <div className="form-row">
        <ValidatedInput
          useId="address-2"
          label={`${ADDRESS_CONSTANTS.ADDRESS_LINE_2}:`}
          type="text"
          validate={validate}
          setValidatedState={setAddress2Input}
          validators={[]}
          validatedStateForAutoFill={address2Input}
        />
      </div>
      <div className="form-row">
        <ValidatedInput
          useId="city"
          label={`*${ADDRESS_CONSTANTS.CITY}:`}
          type="text"
          validate={validate}
          setValidatedState={setCityInput}
          validators={[Validators.REQUIRED]}
          validatedStateForAutoFill={cityInput}
        />
      </div>
      <div className="form-row">
        <ValidatedInput
          useId="State"
          label={`*${ADDRESS_CONSTANTS.STATE}:`}
          type="text"
          validate={validate}
          setValidatedState={setStateInput}
          validators={[Validators.REQUIRED]}
          validatedStateForAutoFill={stateInput}
        />
      </div>
      <div className="form-row">
        <ValidatedInput
          useId="zipcode"
          label={`*${ADDRESS_CONSTANTS.ZIP_CODE}:`}
          type="text"
          validate={validate}
          setValidatedState={setZipcodeInput}
          validators={[Validators.REQUIRED, Validators.ZIP_CODE]}
          validatedStateForAutoFill={zipcodeInput}
        />
      </div>
      <div className="form-row">
        <ValidatedInput
          useId="country"
          label={`*${ADDRESS_CONSTANTS.COUNTRY}:`}
          type="text"
          validate={validate}
          setValidatedState={setCountryInput}
          validators={[Validators.REQUIRED]}
          validatedStateForAutoFill={countryInput}
        />
      </div>
      <div className="form-row">
        <ValidatedInput
          useId="contact-phone"
          label={`*${ADDRESS_CONSTANTS.CONTACT_PHONE}:`}
          type="phone"
          validate={validate}
          setValidatedState={setPhoneInput}
          validators={[Validators.REQUIRED, Validators.PHONE_LENGTH]}
          validatedStateForAutoFill={phoneInput}
        />
      </div>
    </div>
  );
};

export default ValidatedAddress;
