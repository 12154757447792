import { FC, useEffect, useState } from 'react';
import PagedTable from '../../sorted-table/PagedTable';
import Loader from '../../loader/Loader';
import Tooltip from '../../tooltip/Tooltip';
import { showToast } from '../../../services/toast.service';
import { centsToDollars } from '../../../services/account.service';
import { getPaymentTransactions, getPaymentTransactionsOdata } from '../../../api/paymentGatewayApi';
import { PaymentTransaction, StandardParamsToODataParams } from '../../../types/interfaces';
import { PaymentMethodStatus } from '../../../types/enums';
import { toastMessages } from '../../../constants/errorMessages';
import { APP_PATHS } from '../../../constants/routePaths';
import { LABEL_CONSTANTS, PAYMENT_CONSTANTS, TABLE_HEADER_CONSTANTS } from '../../../constants/common';
import './paymentHistory.scss';

const PaymentHistory: FC<{ accountId?: number; refresh?: number }> = ({ accountId, refresh }) => {
  const [paymentHistory, setPaymentHistory] = useState<PaymentTransaction[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const currentRouteLocation = location?.pathname?.split('/');
  const appPage = currentRouteLocation && currentRouteLocation[2];

  useEffect(() => {
    handleGetPaymentHistory();
  }, [accountId, refresh]);

  const handleGetPaymentHistory = async () => {
    try {
      setIsLoading(true);
      if (accountId) {
        const params = { filter: `accountId eq ${accountId}`, sort: '^billedDate' };
        const { data: paymentHistoryResponse } = await getPaymentTransactionsOdata(StandardParamsToODataParams(params));
        setPaymentHistory(paymentHistoryResponse);
      } else {
        const params = { sort: '^billedDate' };
        const { data: paymentHistoryResponse } = await getPaymentTransactions(params);
        setPaymentHistory(paymentHistoryResponse);
      }
    } catch (error) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    } finally {
      setIsLoading(false);
    }
  };

  const getPaymentMethod = (history: PaymentTransaction) => {
    const { method, maskedAccountNumber, notes } = history;
    let paymentLabel = '';

    switch (method) {
      case PaymentMethodStatus.DIRECT:
        paymentLabel = PAYMENT_CONSTANTS.DIRECT;
        break;
      case PaymentMethodStatus.NOT_AVAILABLE:
        paymentLabel = LABEL_CONSTANTS.NOT_AVAILABLE;
        break;
      case PaymentMethodStatus.CARD:
        paymentLabel = `${PAYMENT_CONSTANTS.CARD}....${maskedAccountNumber.slice(-4)}`;
        break;
      case PaymentMethodStatus.ACH:
        paymentLabel = `${PAYMENT_CONSTANTS.ACH}....${maskedAccountNumber.slice(-4)}`;
        break;
      case PaymentMethodStatus.EXTERNAL:
        paymentLabel = PAYMENT_CONSTANTS.EXTERNAL;
        break;
      default:
        paymentLabel = '-';
    }

    return (
      <>
        {paymentLabel}
        {(method === PaymentMethodStatus.DIRECT || method === PaymentMethodStatus.NOT_AVAILABLE) &&
          appPage &&
          Object.values(APP_PATHS).includes(appPage) && <Tooltip>{notes ?? '-NA-'}</Tooltip>}
      </>
    );
  };

  const getPaymentStatus = (status: string) => (status === 'NotAttempted' ? 'Failed' : status ?? '-');

  const InfoLabel = ({ label, value }: { label: string; value: React.ReactNode }) => (
    <div className="payment-history-content">
      <label>{label}:</label>
      <label>{value}</label>
    </div>
  );

  return (
    <div className="payment-history-container">
      <div className="monthly-genetics-payment-history">
        <h3>{LABEL_CONSTANTS.MONTHLY_STORAGE_PAYMENT_HISTORY}</h3>
        <div className="desk-payment-history">
          <PagedTable
            headers={[
              { displayName: TABLE_HEADER_CONSTANTS.BILL_DATE, sortValue: 'billedDate' },
              { displayName: TABLE_HEADER_CONSTANTS.AMOUNT },
              { displayName: TABLE_HEADER_CONSTANTS.AVERAGE_DAILY_BALANCE },
              { displayName: TABLE_HEADER_CONSTANTS.PAYMENT_METHOD },
              { displayName: TABLE_HEADER_CONSTANTS.REFERENCE_ID },
              { displayName: TABLE_HEADER_CONSTANTS.STATUS },
            ]}
            sortBy="^billedDate"
            filter={accountId ? `accountId eq ${accountId}` : ''}
            getData={params => {
              return accountId
                ? getPaymentTransactionsOdata(StandardParamsToODataParams(params))
                : getPaymentTransactions(params);
            }}
            buildRow={history => {
              return [
                `${new Date(history?.billedDate).toLocaleDateString('en-US')}`,
                `$${centsToDollars(history?.costInCents)}`,
                history?.averageDailyBalance,
                getPaymentMethod(history),
                history?.transactionReferenceId,
                getPaymentStatus(history?.status),
              ];
            }}
            height={240}
            pageSize={6}
            noResultsMessage={LABEL_CONSTANTS.NO_RESULTS_FOUND}
            refresh={refresh}
          />
        </div>
        <div className="xs-payment-history">
          {!isLoading && paymentHistory?.length === 0 ? (
            <div className="result-item">{LABEL_CONSTANTS.NO_RESULTS_FOUND}</div>
          ) : (
            <>
              {paymentHistory &&
                paymentHistory.map((history, index) => {
                  return (
                    <div key={history?.transactionReferenceId + index} className="card payment-history-card">
                      <div className="payment-history-info">
                        <InfoLabel
                          label={TABLE_HEADER_CONSTANTS.BILL_DATE}
                          value={new Date(history?.billedDate).toLocaleDateString('en-US') ?? '-'}
                        />
                        <InfoLabel
                          label={TABLE_HEADER_CONSTANTS.AMOUNT}
                          value={`$${centsToDollars(history?.costInCents) ?? '0'}`}
                        />
                        <InfoLabel
                          label={TABLE_HEADER_CONSTANTS.AVERAGE_DAILY_BALANCE}
                          value={`${history?.averageDailyBalance ?? '-'}`}
                        />
                      </div>
                      <div className="payment-history-info">
                        <InfoLabel label={TABLE_HEADER_CONSTANTS.PAYMENT_METHOD} value={getPaymentMethod(history)} />
                        <InfoLabel
                          label={TABLE_HEADER_CONSTANTS.REFERENCE_ID}
                          value={`${history?.transactionReferenceId ?? '-'}`}
                        />
                        <InfoLabel label={TABLE_HEADER_CONSTANTS.STATUS} value={getPaymentStatus(history?.status)} />
                      </div>
                    </div>
                  );
                })}
            </>
          )}
          {isLoading && <Loader addedSpace loaderSize="small" />}
        </div>
      </div>

      <div className="shipping-insurance-payment-history">
        <h3>{LABEL_CONSTANTS.SHIPPING_INSURANCE_PAYMENT_HISTORY}</h3>
        <div className="desk-payment-history">
          <PagedTable
            headers={[
              { displayName: TABLE_HEADER_CONSTANTS.BILL_DATE, sortValue: 'billedDate' },
              { displayName: TABLE_HEADER_CONSTANTS.AMOUNT },
              { displayName: TABLE_HEADER_CONSTANTS.PAYMENT_METHOD },
              { displayName: TABLE_HEADER_CONSTANTS.REFERENCE_ID },
              { displayName: TABLE_HEADER_CONSTANTS.STATUS },
            ]}
            sortBy="^billedDate"
            filter={accountId ? `accountId eq ${accountId}` : ''}
            getData={params => {
              return accountId
                ? getPaymentTransactionsOdata(StandardParamsToODataParams(params))
                : getPaymentTransactions(params);
            }}
            buildRow={history => {
              return [
                `${new Date(history?.billedDate).toLocaleDateString('en-US')}`,
                `$${centsToDollars(history?.costInCents)}`,
                getPaymentMethod(history),
                history?.transactionReferenceId,
                getPaymentStatus(history?.status),
              ];
            }}
            height={240}
            pageSize={6}
            noResultsMessage={LABEL_CONSTANTS.NO_RESULTS_FOUND}
            refresh={refresh}
          />
        </div>
        <div className="xs-payment-history">
          {!isLoading && paymentHistory?.length === 0 ? (
            <div className="result-item">{LABEL_CONSTANTS.NO_RESULTS_FOUND}</div>
          ) : (
            <>
              {paymentHistory &&
                paymentHistory.map((history, index) => {
                  return (
                    <div key={history?.transactionReferenceId + index} className="card payment-history-card">
                      <div className="payment-history-info">
                        <InfoLabel
                          label={TABLE_HEADER_CONSTANTS.BILL_DATE}
                          value={new Date(history?.billedDate).toLocaleDateString('en-US') ?? '-'}
                        />
                        <InfoLabel
                          label={TABLE_HEADER_CONSTANTS.AMOUNT}
                          value={`$${centsToDollars(history?.costInCents) ?? '0'}`}
                        />
                      </div>
                      <div className="payment-history-info">
                        <InfoLabel label={TABLE_HEADER_CONSTANTS.PAYMENT_METHOD} value={getPaymentMethod(history)} />
                        <InfoLabel
                          label={TABLE_HEADER_CONSTANTS.REFERENCE_ID}
                          value={`${history?.transactionReferenceId ?? '-'}`}
                        />
                        <InfoLabel label={TABLE_HEADER_CONSTANTS.STATUS} value={getPaymentStatus(history?.status)} />
                      </div>
                    </div>
                  );
                })}
            </>
          )}
          {isLoading && <Loader addedSpace loaderSize="small" />}
        </div>
      </div>
    </div>
  );
};

export default PaymentHistory;
