import { FC, MutableRefObject, Ref, useEffect, useRef, useState } from 'react';
import DropDownSVG from '../svgs/DropDown.svg';
import Loader from '../loader/Loader';
import { showToast } from '../../services/toast.service';
import { StandardParams } from '../../types/interfaces/apiParams.interfaces';
import { toastMessages } from '../../constants/errorMessages';

type PagedTableProps = {
  headers: PagedTableHeader[];
  pageSize?: number;
  getData: (params: StandardParams) => any;
  buildRow: (item: any) => any[];
  include?: string;
  filter?: string;
  height?: number; // Make sure height is shorter than a single pageSize of rows
  minHeight?: number;
  scrollBufferThickness?: number;
  sortBy?: string;
  sortDescByDefault?: boolean;
  footerElement?: JSX.Element;
  refresh?: number;
  noResultsMessage?: string;
  searchString?: string;
  setRefresh?: (refresh: number) => void;
};

export interface PagedTableHeader {
  displayName: string;
  sortValue?: string;
}
const PagedTable: FC<PagedTableProps> = ({
  headers,
  pageSize = 5,
  getData,
  buildRow,
  include,
  filter,
  height = 256,
  minHeight = 0,
  scrollBufferThickness: scrollGap = 32,
  sortBy = '',
  sortDescByDefault = false,
  footerElement,
  refresh,
  noResultsMessage = 'No results',
  searchString,
  setRefresh,
}: PagedTableProps) => {
  const [offset, setOffset] = useState<number>(0);
  const limit = pageSize;

  const [sortDesc, setSortDesc] = useState<boolean>(sortDescByDefault);
  const [sortValue, setSortValue] = useState<string>(sortBy);
  const [tableData, setTableData] = useState<any[][]>([]);
  const [ignoreScrollEvent, setIgnoreScrollEvent] = useState<boolean>(false);

  const ref = useRef<any>(null);

  const hasAllResults: MutableRefObject<boolean> = useRef<boolean>(false);
  const isLoadingAdditionalPage: MutableRefObject<boolean> = useRef<boolean>(false);
  const callNumber: MutableRefObject<number> = useRef<number>(0);

  const loadData = async () => {
    callNumber.current++;
    if (hasAllResults.current) {
      return;
    }
    const callNum = callNumber.current;
    try {
      const { data: response } = await getData({
        include: include,
        filter: filter,
        sort: (sortDesc ? '^' : '') + sortValue,
        limit: limit,
        offset: offset,
      });
      if (offset === 0 && 'current' in ref) {
        ref.current?.scroll({ top: 0 });
      }
      if (callNum === callNumber.current) {
        const rows = Array.isArray(response) ? response : response.result;
        const rowData = rows.map((row: any) => {
          return buildRow(row);
        });
        const newTableData = offset > 0 ? [...tableData, ...rowData] : rowData;
        setTableData(newTableData);
        hasAllResults.current = rows.length === 0;
      }
    } catch {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    }
  };

  useEffect(() => {
    hasAllResults.current = false;
    setTableData([]);

    if (refresh) {
      setIgnoreScrollEvent(true);
      setRefresh && setRefresh(0);
    }

    if (offset === 0) {
      loadData();
    } else {
      setOffset(0);
      if ('current' in ref) {
        ref.current?.scroll({ top: 0 });
      }
    }
  }, [sortDesc, sortValue, filter, include, refresh, searchString]);

  useEffect(() => {
    loadData();
  }, [offset]);

  const loadMore = () => {
    setOffset(offset + limit);
  };

  useEffect(() => {
    isLoadingAdditionalPage.current = false;
  }, [tableData]);

  const handleScroll = (e: any) => {
    if (ignoreScrollEvent) {
      setIgnoreScrollEvent(false);
      return;
    }

    const position = e.target.scrollHeight - e.target.scrollTop;
    const atBottom = position - e.target.clientHeight <= scrollGap;
    if (atBottom && !isLoadingAdditionalPage.current) {
      isLoadingAdditionalPage.current = true;
      loadMore();
    }
  };

  return (
    <div className="sorted-table">
      <table>
        <thead className="paged-table">
          <tr>
            {headers.map((header: PagedTableHeader, headerIndex: number) => {
              return (
                <th
                  key={'paged-table-header-' + header.displayName + '-' + headerIndex}
                  onClick={() => {
                    if (header.sortValue) {
                      if (header.sortValue === sortValue) {
                        setSortDesc(!sortDesc);
                      } else {
                        setSortValue(header.sortValue);
                      }
                    }
                  }}>
                  {header.displayName}
                  {header.sortValue !== undefined && (
                    <span
                      className={
                        (sortDesc ? 'sort-arrow reverse' : 'sort-arrow') + (header.sortValue === sortValue ? ' sorting' : '')
                      }>
                      <DropDownSVG />
                    </span>
                  )}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody ref={ref} onScroll={handleScroll} style={{ minHeight: minHeight + 'px', maxHeight: height + 'px' }}>
          {tableData &&
            tableData.map((row: any[], rowIndex: number) => {
              return (
                <tr key={'sorted-table-row-' + rowIndex}>
                  {row.map((item: any, itemIndex: number) => {
                    return <td key={'sorted-table-item-' + rowIndex + '-' + itemIndex}>{item}</td>;
                  })}
                </tr>
              );
            })}
          {!hasAllResults.current && (tableData.length === 0 || isLoadingAdditionalPage.current) && (
            <tr>
              <td colSpan={headers.length}>
                <br />
                <br />
                <br />
                <Loader loaderSize="small" />
                <br />
                <br />
                <br />
              </td>
            </tr>
          )}
          {tableData.length === 0 && hasAllResults.current && (
            <tr>
              <td colSpan={headers.length}>
                <br />
                {noResultsMessage}
                <br />
                <br />
              </td>
            </tr>
          )}
        </tbody>
        {footerElement !== undefined && (
          <tfoot>
            <tr>
              <td colSpan={headers.length - 1}></td>
              <td>{footerElement}</td>
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  );
};

export default PagedTable;
