import { useState } from 'react';
import { Loader, ValidatedInput } from '../../components';
import { ValidatedState } from '../../components/custom-input/ValidatedInput';
import { Validators } from '../../types/enums';
import { UserInfo } from '../../types/interfaces';
import { adminPasswordReset, getUserByUsername } from '../../api/userApi';
import { showToast } from '../../services/toast.service';
import { LABEL_CONSTANTS, PASSWORDS_CONSTANTS } from '../../constants/common';
import { toastMessages } from '../../constants/errorMessages';
import { httpStatusCodes } from '../../constants/httpStatusCodes';

const ResetPassword = () => {
  const [email, setEmail] = useState<ValidatedState>({ value: '', valid: false });
  const [foundUser, setFoundUser] = useState<UserInfo>();
  const [resettingPassword, setResettingPassword] = useState<boolean>(false);
  const [resetPasswordLink, setResetPasswordLink] = useState<string>('');
  const [validate, setValidate] = useState<boolean>(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setValidate(true);

    if (resettingPassword) return;
    setResettingPassword(true);

    if (email.valid) {
      const emailValue: string = email.value;
      try {
        const { data: resetPasswordResponse } = await adminPasswordReset(emailValue);
        setResetPasswordLink(resetPasswordResponse);
        const { data: userResponse } = await getUserByUsername(email.value);
        setFoundUser(userResponse);
        setEmail({ value: '', valid: false });
        showToast.success(toastMessages.RESET_PASSWORD_EMAIL_SENT);
      } catch (error: any) {
        if (error?.response?.status === httpStatusCodes[404]) {
          showToast.error(toastMessages.EMAIL_NOT_FOUND);
        } else {
          showToast.error(toastMessages.SOMETHING_WENT_WRONG);
        }
        setResetPasswordLink('');
      } finally {
        setValidate(false);
      }
    }
    setResettingPassword(false);
  };

  return (
    <>
      {resettingPassword ? (
        <Loader loaderSize="medium" pageLoader />
      ) : (
        <>
          <h3 className="tab-header">{PASSWORDS_CONSTANTS.ADMIN_RESET_PASSWORD}</h3>
          <p className="tab-guide">{PASSWORDS_CONSTANTS.ADMIN_RESET_PASSWORD_GUIDE}</p>
          <hr className="divider" />
          <form onSubmit={e => handleSubmit(e)}>
            <div className="input">
              <ValidatedInput
                useId="email"
                label="Email"
                type="email"
                setValidatedState={e => setEmail(e)}
                validators={[Validators.EMAIL, Validators.REQUIRED]}
                validate={validate}
                validatedStateForAutoFill={email}
              />
            </div>
            <div className="submit-button">
              <button disabled={!email.valid} type="submit" className="button green">
                {PASSWORDS_CONSTANTS.RESET_PASSWORD}
              </button>
            </div>
            {resetPasswordLink.length > 0 && (
              <>
                <hr className="divider" />
                <p className="tab-guide">{PASSWORDS_CONSTANTS.ADMIN_RESET_PASSWORD_EMAIL_GUIDE}</p>
                <div className="user-details-section">
                  <h4 className="user-details-header">{LABEL_CONSTANTS.USER_DETAILS} : </h4>
                  <div className="user-details-body">
                    <label>
                      {LABEL_CONSTANTS.USER}: {foundUser?.firstName} {foundUser?.lastName}
                    </label>
                    <label>
                      {LABEL_CONSTANTS.EMAIL_ID} : {foundUser?.username}
                    </label>
                    <label>
                      {LABEL_CONSTANTS.CONTACT_PHONE} : {foundUser?.contactPhone}
                    </label>
                    <label>{PASSWORDS_CONSTANTS.RESET_PASSWORD_LINK} :</label>
                    <a className="reset-password-link" href={resetPasswordLink}>
                      {resetPasswordLink}
                    </a>
                  </div>
                </div>
              </>
            )}
          </form>
        </>
      )}
    </>
  );
};
export default ResetPassword;
