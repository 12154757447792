import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../redux/hooks';
import { Loader } from '../../../components';
import ActionMenu from '../../../components/action-buttons/ActionButtons';
import PagedTable from '../../../components/sorted-table/PagedTable';
import { InventoryTransaction } from '../../../types/interfaces';
import { TransactionStatusEnum } from '../../../types/enums';
import { getInventoryTransactions } from '../../../api/inventoryTransactionsApi';
import { showToast } from '../../../services/toast.service';
import { toastMessages } from '../../../constants/errorMessages';
import { ROUTE_PATHS } from '../../../constants/routePaths';
import { BUTTON_CONSTANTS, LABEL_CONSTANTS, TABLE_HEADER_CONSTANTS } from '../../../constants/common';

const PendingTransactions = (): JSX.Element => {
  const navigate = useNavigate();

  const accountId = useAppSelector(state => state.activeAccount.accountId);

  const [loadingTransactions, setLoadingTransactions] = useState<boolean>(false);
  const [pendingTransactions, setPendingTransactions] = useState<InventoryTransaction[]>([]);

  const includeInRequest = 'Specimen.SpecimenType,Specimen.Animal,TransactionLink.Account';

  useEffect(() => {
    const fetchPendingTransactions = async () => {
      if (!accountId) return;
      try {
        setLoadingTransactions(true);
        const { data } = await getInventoryTransactions({
          include: includeInRequest,
          filter: `accountId eq ${accountId};status eq ${TransactionStatusEnum.Pending};`,
          limit: 100,
        });

        const newPendingTransactions = (data as any).result;
        setPendingTransactions(newPendingTransactions);
      } catch (error: any) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      } finally {
        setLoadingTransactions(false);
      }
    };

    fetchPendingTransactions();
  }, [accountId]);

  const getPendingTransactionTable = (headers: string[], className: string, maxHeight: number) => {
    return (
      <div className={className}>
        <PagedTable
          headers={headers.map(header => ({ displayName: header }))}
          sortBy="^createdDatetime"
          include={includeInRequest}
          filter={`accountId eq ${accountId};status eq ${TransactionStatusEnum.Pending};`}
          getData={getInventoryTransactions}
          height={maxHeight}
          buildRow={i => {
            return className === 'mobile-view'
              ? [
                  i?.specimen?.animal?.code ? i?.specimen?.animal?.code + ' - ' + i?.specimen?.animal?.name : '-',
                  i?.transactionType,
                  i?.changeQuantity,
                  <ActionMenu
                    actionButtons={[
                      { name: BUTTON_CONSTANTS.VIEW_DETAILS, action: () => handleAction(i?.inventoryTransactionId) },
                    ]}
                  />,
                ]
              : [
                  i?.inventoryTransactionId,
                  i?.specimen?.animal?.code ? i?.specimen?.animal?.code + ' - ' + i?.specimen?.animal?.name : '-',
                  i?.transactionType,
                  i?.changeQuantity,
                  new Date(i?.createdDatetime).toLocaleDateString(),
                  i?.status,
                  <ActionMenu
                    actionButtons={[
                      { name: BUTTON_CONSTANTS.VIEW_DETAILS, action: () => handleAction(i?.inventoryTransactionId) },
                    ]}
                  />,
                ];
          }}
        />
      </div>
    );
  };

  const handleSeeTransactions = () => {
    navigate(ROUTE_PATHS.APP_TRANSACTION_HISTORY);
  };

  const handleAction = (id: number) => {
    navigate(ROUTE_PATHS.APP_TRANSACTION_HISTORY_VIEW + id, { state: { returnLocation: ROUTE_PATHS.APP_DASHBOARD } });
  };

  return (
    <>
      <div className="card full pending-transactions">
        <h2 className="pending-transactions-header">{LABEL_CONSTANTS.PENDING_TRANSACTIONS}</h2>

        <div className="pending-transactions-table">
          {pendingTransactions && pendingTransactions.length > 0 ? (
            <>
              {getPendingTransactionTable(
                [TABLE_HEADER_CONSTANTS.ANIMAL, TABLE_HEADER_CONSTANTS.TYPE, TABLE_HEADER_CONSTANTS.CHANGED_QTY, ' '],
                'mobile-view',
                194,
              )}
              {getPendingTransactionTable(
                [
                  TABLE_HEADER_CONSTANTS.TRANSACTION_ID,
                  TABLE_HEADER_CONSTANTS.ANIMAL,
                  TABLE_HEADER_CONSTANTS.TYPE,
                  TABLE_HEADER_CONSTANTS.CHANGED_QTY,
                  TABLE_HEADER_CONSTANTS.DATE,
                  TABLE_HEADER_CONSTANTS.STATUS,
                  TABLE_HEADER_CONSTANTS.ACTION,
                ],
                'desktop-view',
                256,
              )}
            </>
          ) : loadingTransactions ? (
            <Loader loaderSize="medium" addedSpace simple />
          ) : (
            <h2 className="no-inventory-available">{LABEL_CONSTANTS.NO_PENDING_TRANSACTIONS}</h2>
          )}
        </div>
      </div>
      <button className="see-transactions button blue" onClick={handleSeeTransactions}>
        <p>{BUTTON_CONSTANTS.SEE_TRANSACTIONS}</p>
      </button>
    </>
  );
};
export default PendingTransactions;
