import { FC } from 'react';
import { Link } from 'react-router-dom';
import { DropDownSVG, GoatSVG } from '../../../svgs';
import { APP_PATHS } from '../../../../constants/routePaths';
import { NAVIGATION_LINK_CONSTANTS } from '../../../../constants/common';
import { redirectToTopOfThePage } from '../../../../utils/commonUtils';

type AnimalManagementLinksProps = {
  currentRoute: string[];
  showAnimalManagementPages: boolean;
  setShowAnimalManagementPages: React.Dispatch<React.SetStateAction<boolean>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenNavBar: React.Dispatch<React.SetStateAction<boolean>>;
};

const AnimalManagementLinks: FC<AnimalManagementLinksProps> = ({
  currentRoute,
  showAnimalManagementPages,
  setShowAnimalManagementPages,
  setOpen,
  setOpenNavBar,
}) => {
  const activeLinkClass = (path: string) => {
    return currentRoute.includes(path) ? 'nav-link active' : 'nav-link';
  };

  const activeSubLinkClass = (path: string) => {
    return currentRoute.includes(path) ? 'nav-sub-link active' : 'nav-sub-link';
  };

  const closeSideBar = () => {
    setOpen(false);
    setOpenNavBar(false);
    redirectToTopOfThePage();
  };

  return (
    <div>
      <button
        type="button"
        className={activeLinkClass(APP_PATHS.ANIMAL_MANAGEMENT)}
        onClick={() => setShowAnimalManagementPages(!showAnimalManagementPages)}>
        <GoatSVG />
        {NAVIGATION_LINK_CONSTANTS.ANIMAL_MANAGEMENT}
        <DropDownSVG reversed={showAnimalManagementPages} />
      </button>
      {showAnimalManagementPages && (
        <div>
          <Link
            className={activeSubLinkClass(APP_PATHS.ANIMAL_MANAGEMENT)}
            to={APP_PATHS.ANIMAL_MANAGEMENT}
            onClick={closeSideBar}>
            {NAVIGATION_LINK_CONSTANTS.ANIMAL}
          </Link>
          <Link
            className={activeSubLinkClass(APP_PATHS.SPECIES_BREED_MANAGEMENT)}
            to={APP_PATHS.SPECIES_BREED_MANAGEMENT}
            onClick={closeSideBar}>
            {NAVIGATION_LINK_CONSTANTS.SPECIES_BREEDS}
          </Link>
        </div>
      )}
    </div>
  );
};

export default AnimalManagementLinks;
