import { FC, useState, useEffect } from 'react';
import FilterDropdown, { FilterOption } from '../custom-input/FilterDropdown';
import ValidatedInput from '../custom-input/ValidatedInput';
import { TransactionFilterOption } from '../transaction/TransactionsList';
import { SearchSVG } from '../svgs';
import { LABEL_CONSTANTS } from '../../constants/common';
import './searchBy.scss';

interface SearchByProps {
  searches: { criteria: FilterOption<any>; query: string }[];
  setSearches: (e: { criteria: FilterOption<any>; query: string }[]) => void;
  searchCriteriaOptions: FilterOption<any>[];
  resetSearchCriteria?: number;
  removedFilter?: { criteria: FilterOption<TransactionFilterOption>; query: string };
}

const SearchBy: FC<SearchByProps> = ({ searchCriteriaOptions, searches, setSearches, resetSearchCriteria, removedFilter }) => {
  const [selectedSearchCriteria, setSelectedSearchCriteria] = useState<FilterOption<any> | null>(null);
  const [query, setQuery] = useState<string>('');

  const handleSearchCriteriaChange = (newCriteria: FilterOption<any>) => {
    setSelectedSearchCriteria(newCriteria);
    const existingEntry = searches.find(item => item.criteria.name === newCriteria.name);
    setQuery(existingEntry ? existingEntry.query : '');
  };

  useEffect(() => {
    if (!selectedSearchCriteria) return;

    const existingIndex = searches.findIndex(item => item.criteria.name === selectedSearchCriteria.name);
    let updatedSearches = [...searches];

    if (query.trim()) {
      if (existingIndex !== -1) {
        updatedSearches[existingIndex] = { criteria: selectedSearchCriteria, query };
      } else {
        updatedSearches.push({ criteria: selectedSearchCriteria, query });
      }
    } else {
      updatedSearches = updatedSearches.filter(item => item.criteria.name !== selectedSearchCriteria.name);
    }

    setSearches(updatedSearches);
  }, [selectedSearchCriteria, query]);

  useEffect(() => {
    if (searches.length === 0 || (removedFilter && removedFilter.criteria.name === selectedSearchCriteria?.name)) {
      setSelectedSearchCriteria(null);
      setQuery('');
    }
  }, [resetSearchCriteria, removedFilter]);

  return (
    <>
      <div className="search-by">
        <p className="search-by-label">{LABEL_CONSTANTS.SEARCH_BY}:</p>

        <div className="input-stack">
          <FilterDropdown
            placeholder="Select"
            options={searchCriteriaOptions}
            value={{ name: selectedSearchCriteria?.name ?? '', value: selectedSearchCriteria?.value ?? '' }}
            onChange={handleSearchCriteriaChange}
          />

          <ValidatedInput
            disabled={!selectedSearchCriteria}
            validatedStateForAutoFill={{ value: query ?? '', valid: true }}
            placeholder={''}
            label={''}
            setValidatedState={e => setQuery(e.value)}
            validate={false}
            validators={[]}
          />
          <input disabled type="text" className="icon-holder" />
          <SearchSVG />
        </div>
      </div>
    </>
  );
};

export default SearchBy;
