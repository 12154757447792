import { MutableRefObject, useEffect, useRef, useState } from 'react';
import DateRangeInput from '../../../components/custom-input/DateInputRange';
import FilterDropdown, { FilterOption } from '../../../components/custom-input/FilterDropdown';
import SearchBy from '../../../components/search-by/SearchBy';
import { CancelSVG, DownloadSVG } from '../../../components/svgs';
import PagedTable from '../../../components/sorted-table/PagedTable';
import ActionMenu from '../../../components/action-buttons/ActionButtons';
import { Loader } from '../../../components';
import InsuredCustomerDetailsModal from './insuredCustomerDetailsModal';
import { TransactionStatusEnum } from '../../../types/enums';
import { showToast } from '../../../services/toast.service';
import { toastMessages } from '../../../constants/errorMessages';
import { BUTTON_CONSTANTS, LABEL_CONSTANTS } from '../../../constants/common';
import './insuredCustomersReport.scss';

import { getAnimals } from '../../../api/animalsApi';
import { Animal } from '../../../types/interfaces';

const InsuredCustomersReport = (): JSX.Element => {
  const [dateRange, setDateRange] = useState<{ startDate: Date | null; endDate: Date | null }>({
    startDate: null,
    endDate: null,
  });
  const [status, setStatus] = useState<string>('');
  const [searches, setSearches] = useState<{ criteria: FilterOption<any>; query: string }[]>([]);
  const [removedFilter, setRemovedFilter] = useState<{ criteria: FilterOption<any>; query: string }>();
  const [isInsuredCustomerDetailsModalOpen, setIsInsuredCustomerDetailsModalOpen] = useState<boolean>(false);
  const [animalsData, setAnimalsData] = useState<Animal[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);

  const animalsFetchLimit = 20;
  const callNumber: MutableRefObject<number> = useRef<number>(0);
  const hasAllResults: MutableRefObject<boolean> = useRef<boolean>(false);
  const isLoadingAdditionalPage: MutableRefObject<boolean> = useRef<boolean>(false);
  const ref = useRef<any>(null);

  let statusOptions: FilterOption<string>[] = [];
  for (const [key, value] of Object.entries(TransactionStatusEnum)) {
    statusOptions.push({ name: key.replace('Default', 'All'), value: value });
  }

  const searchCriteriaOptions: FilterOption<any>[] = [
    { name: `Client Name`, value: { parameter: 'accountId' } },
    { name: `Tracking Number`, value: { parameter: '' } },
    { name: `Case Number`, value: { parameter: '' } },
  ];

  const loadData = async () => {
    callNumber.current++;
    if (hasAllResults.current) {
      return;
    }
    const callNum = callNumber.current;
    try {
      setLoading(true);

      const query = { include: 'Breed.Species', sort: 'AnimalId', limit: animalsFetchLimit, offset: offset };
      const response = await getAnimals(query);
      const animals = response.data as Animal[];

      if (offset === 0 && 'current' in ref) {
        ref.current?.scroll({ top: 0 });
      }

      if (callNum === callNumber.current) {
        const newTableData = offset > 0 ? [...animalsData, ...animals] : animals;
        setAnimalsData(newTableData);
        setLoading(false);
        hasAllResults.current = animals.length === 0;
      }
    } catch (error: any) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    }
  };

  useEffect(() => {
    window.innerWidth <= 767 && loadData();
  }, [offset]);

  useEffect(() => {
    window.addEventListener('touchmove', handleScroll);
    return () => window.removeEventListener('touchmove', handleScroll);
  }, []);

  useEffect(() => {
    isLoadingAdditionalPage.current = false;
  }, [animalsData]);

  const loadMore = () => {
    setOffset(prevState => prevState + animalsFetchLimit);
  };

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
    const position = scrollHeight - scrollTop;
    const atBottom = position - clientHeight <= clientHeight;

    if (atBottom && !isLoadingAdditionalPage.current) {
      isLoadingAdditionalPage.current = true;
      loadMore();
    }
  };

  const handleDateChange = (dateRange: { startDate: Date | null; endDate: Date | null }) => {
    setDateRange(dateRange);
  };

  const handleRemoveFilter = (index: number) => {
    const updatedSearch = [...searches];
    const removedFilter = updatedSearch.splice(index, 1)[0];

    setRemovedFilter(removedFilter);
    setSearches(updatedSearch);
  };

  return (
    <div className="insured-customers-report">
      <div className="header">
        <h2 className="report-header">{'Insured Customers Report'}</h2>
        <div className="search-stack">
          <div className="search">
            <SearchBy
              searches={searches}
              setSearches={searches => setSearches(searches)}
              searchCriteriaOptions={searchCriteriaOptions}
              removedFilter={removedFilter}
            />
          </div>
        </div>
      </div>

      <hr className="header-hr" />
      <div className="selected-filters-summary">
        {searches.map((item, index) => (
          <div key={index} className="filter-summary">
            <span>
              {item.criteria.name}: <strong>{item.query}</strong>
            </span>
            <button onClick={() => handleRemoveFilter(index)} className="remove-filter-btn">
              <CancelSVG />
            </button>
          </div>
        ))}
      </div>
      <div className="filter-row">
        <div className="button-row">
          <div className="filters">
            <div className="filter-category">
              <FilterDropdown
                placeholder={LABEL_CONSTANTS.STATUS}
                options={statusOptions}
                value={{ name: status, value: status }}
                onChange={e => setStatus(e.value)}
              />
            </div>
            <div className="filter-category">
              <div className="date-range-input">
                <DateRangeInput value={dateRange} onChange={handleDateChange} isClearable rangeLimit={1} />
              </div>
            </div>
          </div>
          <button className="button outlined download-report" onClick={() => {}}>
            <DownloadSVG />
            &nbsp; Download CSV
          </button>
        </div>
      </div>

      <InsuredCustomerDetailsModal
        isOpen={isInsuredCustomerDetailsModalOpen}
        onClose={() => setIsInsuredCustomerDetailsModalOpen(false)}
      />

      <div className="desk-insured-customers-table">
        <PagedTable
          headers={[
            { displayName: 'Client Name' },
            { displayName: 'Date Submitted' },
            { displayName: 'Status' },
            { displayName: 'Insurance Amount' },
            { displayName: 'Action' },
          ]}
          getData={getAnimals}
          buildRow={animal => {
            return [
              animal.name,
              new Date().toLocaleDateString(),
              animal.code,
              animal.animalId,
              <ActionMenu
                actionButtons={[
                  { name: `${BUTTON_CONSTANTS.VIEW_DETAIL}`, action: () => setIsInsuredCustomerDetailsModalOpen(true) },
                ]}
              />,
            ];
          }}
        />
      </div>

      <div className="xs-insured-customers-table">
        {!loading && animalsData.length === 0 ? (
          <div className="result-item">{`${LABEL_CONSTANTS.NO_RESULTS_FOUND}.`}</div>
        ) : (
          animalsData?.length > 0 &&
          animalsData &&
          animalsData.map((animal, index) => {
            return (
              <div className="card max-width" key={'animal-' + animal?.code + index}>
                <div className="insured-customers">
                  <div className="insured-customers-content">
                    <div className="card-content-section">
                      <div className="left">
                        <label>{`Client Name : `}</label>
                        <label>{`${animal.name ?? '-'}`}</label>
                      </div>
                      <div className="right">
                        <label>{`Date Submitted : `}</label>
                        <label>{`${new Date().toLocaleDateString() ?? '-'}`}</label>
                      </div>
                    </div>
                    <div className="card-content-section">
                      <div className="left">
                        <label>{`Status : `}</label>
                        <label>{`${animal.code ?? '-'}`}</label>
                      </div>

                      <div className="right">
                        <label>{`Insurance Amount : `}</label>
                        <label>{`${animal.animalId ?? '-'}`}</label>
                      </div>
                    </div>
                  </div>
                  <div className="action-button">
                    {
                      <ActionMenu
                        actionButtons={[
                          { name: `${BUTTON_CONSTANTS.VIEW_DETAIL}`, action: () => setIsInsuredCustomerDetailsModalOpen(true) },
                        ]}
                      />
                    }
                  </div>
                </div>
              </div>
            );
          })
        )}
        {loading && <Loader addedSpace loaderSize="small" />}
      </div>
    </div>
  );
};

export default InsuredCustomersReport;
