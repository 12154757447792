import { AxiosResponse } from 'axios';
import { appContextGet, appContextPost } from './httpCommon/appAxios.service';
import { PagedResponse, StandardParams } from '../types/interfaces';
import { QBOpenBalanceDiscrepancy, QBSyncHistory } from '../types/interfaces/quickbooks.interface';
import { QUICKBOOKS_OPEN_BALANCE_DISCREPANCIES, QUICKBOOKS_SYNC } from '../constants/apiEndpoints';

export const getQuickBooksOpenBalanceDiscrepancies = (
  params?: StandardParams,
): Promise<AxiosResponse<QBOpenBalanceDiscrepancy[] | PagedResponse<QBOpenBalanceDiscrepancy>, any>> => {
  return appContextGet(QUICKBOOKS_OPEN_BALANCE_DISCREPANCIES.GET, params);
};

export const syncQuickBooksByAccountId = (accountId: number): Promise<AxiosResponse<any, any>> => {
  return appContextPost(QUICKBOOKS_SYNC.RESYNC, undefined, { accountId: accountId });
};

export const updateQuickBooksSyncHistory = (payload: QBSyncHistory): Promise<AxiosResponse<any, any>> => {
  return appContextPost(QUICKBOOKS_SYNC.SYNC_HISTORY, payload);
};
