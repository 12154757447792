const ReportsSVG = (): JSX.Element => {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 14.5H4V7.5H6V14.5ZM10 14.5H8V4.5H10V14.5ZM14 14.5H12V10.5H14V14.5ZM16 16.5H2V2.5H16V16.6M16 0.5H2C0.9 0.5 0 1.4 0 2.5V16.5C0 17.6 0.9 18.5 2 18.5H16C17.1 18.5 18 17.6 18 16.5V2.5C18 1.4 17.1 0.5 16 0.5Z"
        fill="#545556"
      />
    </svg>
  );
};
export default ReportsSVG;
