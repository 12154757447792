import { useEffect, useState } from 'react';
import { Loader } from '../../components';
import ActionMenu from '../../components/action-buttons/ActionButtons';
import PagedTable from '../../components/sorted-table/PagedTable';
import UpdateOutstandingModal from './UpdateOutstandingModal';
import { QBOpenBalanceDiscrepancy } from '../../types/interfaces/quickbooks.interface';
import { getQuickBooksOpenBalanceDiscrepancies, syncQuickBooksByAccountId } from '../../api/quickbooksApi';
import { centsToDollars } from '../../services/account.service';
import { showToast } from '../../services/toast.service';
import { BUTTON_CONSTANTS, LABEL_CONSTANTS, QUICKBOOKS_CONSTANTS, TABLE_HEADER_CONSTANTS } from '../../constants/common';
import { toastMessages } from '../../constants/errorMessages';

const PaymentDiscrepancies = () => {
  const [isOpenUpdateOutstandingModal, setIsOpenUpdateOutstandingModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [openBalanceDiscrepancies, setOpenBalanceDiscrepancies] = useState<QBOpenBalanceDiscrepancy[]>([]);
  const [selectedAccountDiscrepancyItem, setSelectedAccountAccountDiscrepancyItem] = useState<QBOpenBalanceDiscrepancy>();
  const [refresh, setRefresh] = useState<number>(0);

  useEffect(() => {
    const handleGetVariances = async () => {
      setLoading(true);
      try {
        const { data: openBalanceDiscrepancy } = await getQuickBooksOpenBalanceDiscrepancies({
          include: 'Account',
          sort: 'AccountId',
        });
        setOpenBalanceDiscrepancies(openBalanceDiscrepancy as QBOpenBalanceDiscrepancy[]);
      } catch (error) {
        showToast.error(toastMessages.SOMETHING_WENT_WRONG);
      }
      setLoading(false);
    };

    handleGetVariances();
  }, [refresh]);

  const handleSync = async (accountId: number) => {
    try {
      setRefresh(r => r + 1);
      await syncQuickBooksByAccountId(accountId);
    } catch (error) {
      showToast.error(toastMessages.SOMETHING_WENT_WRONG);
    }
  };

  const actionMenuButton = (discrepancyItem: QBOpenBalanceDiscrepancy) => {
    return (
      <ActionMenu
        actionButtons={[
          { name: BUTTON_CONSTANTS.SYNC, action: () => handleSync(discrepancyItem.accountId) },
          {
            name: BUTTON_CONSTANTS.RECONCILE,
            action: () => {
              setSelectedAccountAccountDiscrepancyItem(discrepancyItem);
              setIsOpenUpdateOutstandingModal(true);
            },
          },
        ]}
      />
    );
  };

  return (
    <div className="payment-discrepancies">
      <h3 className="tab-header">{QUICKBOOKS_CONSTANTS.PAYMENT_DISCREPANCIES}</h3>
      <p className="tab-guide">{QUICKBOOKS_CONSTANTS.PAYMENT_DISCREPANCIES_GUIDE}</p>
      <div className="discrepancy-table">
        <div className="content">
          <PagedTable
            headers={[
              { displayName: TABLE_HEADER_CONSTANTS.ACCOUNT },
              { displayName: TABLE_HEADER_CONSTANTS.INVENTORY_DUE_AMOUNT },
              { displayName: TABLE_HEADER_CONSTANTS.QB_DUE_AMOUNT },
              { displayName: TABLE_HEADER_CONSTANTS.DIFFERENCE },
              { displayName: TABLE_HEADER_CONSTANTS.ACTIONS },
            ]}
            buildRow={item => {
              return [
                `${item.account.name} (${item?.accountId})`,
                `$${centsToDollars(item.inventoryAppOutstandingBalanceCents)}`,
                `$${centsToDollars(item.quickBooksOutstandingBalanceCents)}`,
                `$${centsToDollars(Math.abs(item.inventoryAppOutstandingBalanceCents - item.quickBooksOutstandingBalanceCents))}`,
                actionMenuButton(item),
              ];
            }}
            getData={getQuickBooksOpenBalanceDiscrepancies}
            include="Account"
            height={320}
            pageSize={6}
            refresh={refresh}
            setRefresh={setRefresh}
            noResultsMessage={LABEL_CONSTANTS.NO_PAYMENT_DISCREPANCIES_FOUND}
            sortBy="AccountId"
          />
        </div>

        <div className="xs-content">
          {loading ? (
            <Loader addedSpace loaderSize={'small'} />
          ) : openBalanceDiscrepancies?.length === 0 && !loading ? (
            <label className="no-discrepancies-found">{LABEL_CONSTANTS.NO_PAYMENT_DISCREPANCIES_FOUND}</label>
          ) : (
            <>
              {openBalanceDiscrepancies?.map((discrepancyItem, index) => {
                return (
                  <div className="card max-width" key={discrepancyItem.quickBooksOpenBalanceDiscrepancyId + index}>
                    <div className="payment-variances">
                      <div className="payment-variances-content">
                        <div className="card-content-section">
                          <div className="left">
                            <label>{`${TABLE_HEADER_CONSTANTS.ACCOUNT}:  ${discrepancyItem?.account?.name} (${discrepancyItem?.accountId})`}</label>
                          </div>
                          <div className="right">
                            <label>{`${TABLE_HEADER_CONSTANTS.INVENTORY_DUE_AMOUNT}: $${centsToDollars(
                              discrepancyItem.inventoryAppOutstandingBalanceCents,
                            )}`}</label>
                          </div>
                        </div>
                        <div className="card-content-section">
                          <div className="left">
                            <label>{`${TABLE_HEADER_CONSTANTS.QB_DUE_AMOUNT}: $${centsToDollars(
                              discrepancyItem.quickBooksOutstandingBalanceCents,
                            )}`}</label>
                          </div>

                          <div className="right">
                            <label>{`${TABLE_HEADER_CONSTANTS.DIFFERENCE}: $${centsToDollars(
                              Math.abs(
                                discrepancyItem.inventoryAppOutstandingBalanceCents -
                                  discrepancyItem.quickBooksOutstandingBalanceCents,
                              ),
                            )}`}</label>
                          </div>
                        </div>
                      </div>
                      <div className="action-button">{actionMenuButton(discrepancyItem)}</div>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
        <UpdateOutstandingModal
          isOpen={isOpenUpdateOutstandingModal}
          setIsOpen={setIsOpenUpdateOutstandingModal}
          onConfirm={() => setRefresh(r => r + 1)}
          selectedDiscrepancyItem={selectedAccountDiscrepancyItem}
        />
      </div>
    </div>
  );
};

export default PaymentDiscrepancies;
